import { useContext, useEffect, useState } from "react";
import { siteLanguageNamesAbbr } from "../../constans/languagesList";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import localizationJson from "../../localization.json";
import s from "./SiteLangsTabs.module.css";

const SiteLangsTabs = () => {
  const { selectLang, setSelectLang } = useContext(ContextProvider);
  const [langsData, setLangsData] = useState<
    { value: string; label: string }[] | undefined
  >();

  useEffect(() => {
    const browserLanguage = navigator.language.split("-")[0];
    const languageArray = Object.keys(localizationJson);

    setLangsData(
      languageArray.map((item) => ({
        label: siteLanguageNamesAbbr[item],
        value: item,
      }))
    );
    if (selectLang) return;
    if (languageArray.includes(browserLanguage)) {
      setSelectLang(browserLanguage);
    } else {
      setSelectLang("en");
    }
  }, []);

  const changeLangHandler = async (value: string) => {
    strings.setLanguage(value);
    localStorage.setItem("lang", value);
    setSelectLang(value);
  };
  return (
    <div className={s.langSelectBlock}>
      {langsData &&
        langsData.map((item, index) => (
          <button
            onClick={() => changeLangHandler(item.value)}
            className={
              selectLang === item.value ? s.selectLang : s.unselectLang
            }
            key={index}
          >
            {item.label}
          </button>
        ))}
    </div>
  );
};

export default SiteLangsTabs;

import { network } from "../config";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import customKy from "./kyExtend";

const { recordings } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "recordings/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class RecordingsApi {
  static async approveRecording(
    token: string,
    sessionId: string,
    approval: boolean
  ): Promise<
    TypeDefaultResponse & { approvals?: { userId: string; status: boolean }[] }
  > {
    return await customKy
      .post(`${baseURL}${recordings.approveRecording}/${sessionId}`, {
        headers: getAuthHeader(token),
        json: { approval },
      })
      .then((res) => res.json());
  }

  static async getSessionRecordingInfo(
    token: string,
    sessionId: string
  ): Promise<
    TypeDefaultResponse & {
      approvals?: { userId: string; status: boolean }[];
      approvedForRecord?: boolean;
      firstTimestamp?: number;
    }
  > {
    return await customKy
      .get(`${baseURL}${recordings.getSessionRecordingInfo}/${sessionId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async testSaveAudioToSession(
    formData: FormData
  ): Promise<TypeDefaultResponse> {
    return await customKy
      .post(`${baseURL}testSaveAudioToSession/`, {
        body: formData,
      })
      .then((res) => res.json());
  }
}

import { Checkbox, CircularProgress, ThemeProvider } from "@mui/material";
import { matchIsValidTel } from "mui-tel-input";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UsersApi from "../../api/usersApi";
import AuthBgImage from "../../assets/Auth/authbg.png";
import EyeSlashIcon from "../../assets/Auth/eye-slash.svg";
import EyeIcon from "../../assets/Auth/eye.svg";
import RegBgImage from "../../assets/Auth/regimage.png";
import Logo from "../../assets/logo.png";
import { ReactComponent as ArrowLeft } from "../../assets/SpecialistSetupInfo/arrow-right.svg";
import Input from "../../components/Input/Input";
import PhoneNumberInput from "../../components/PhoneNumberInput/PhoneNumberInput";
import SiteLangsTabs from "../../components/SiteLangsTabs/SiteLangsTabs";
import StandartButton from "../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import { storeData } from "../../utils/general";
import { theme } from "../../utils/theme";
import s from "./Authorization.module.css";
import MobileAuthorization from "./MobileAuthorization/MobileAuthorization";

const Authorization = () => {
  const location = useLocation();
  const profilePathName = localStorage.getItem("profilePathName");
  const authMode = location.state ? location.state.authMode : null;

  const navigate = useNavigate();
  const { setUserData, setIsAuth, setIsSetupProfileInfo, selectLang } =
    useContext(ContextProvider);
  const [isSignUp, setIsSignUp] = useState(false);
  const [authValues, setAuthValues] = useState({
    phoneNumberOrEmail: "",
    password: "",
    rememberMe: false,
  });
  const [registerValues, setRegisterVaues] = useState({
    phoneNumber: "",
    email: "",
    password: "",
  });
  const [regError, setRegError] = useState("");
  const [passwordVisible, setPasswordVisible] = useState({
    auth: false,
    register: false,
  });
  const [authError, setAuthError] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    if (!authMode) return;
    setIsSignUp(authMode === "signup");
  }, [authMode]);

  const validateRegister = (
    email: string,
    password: string,
    phoneNumber: string
  ) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+={}\[\]:;<>,.?~\\/-]{8,}$/;

    if (!emailRegex.test(email)) {
      setRegError(strings.invalidEmail);
      return false;
    }

    if (!passwordRegex.test(password)) {
      setRegError(strings.invalidPassword);
      return false;
    }
    if (!matchIsValidTel(phoneNumber)) {
      setRegError(strings.invalidPhoneNumber);
      return false;
    }
    setRegError("");
    return true;
  };

  const handleRegister = async (e?: React.FormEvent<HTMLFormElement>) => {
    e && e.preventDefault();
    const isValid = validateRegister(
      registerValues.email,
      registerValues.password,
      registerValues.phoneNumber
    );
    if (!isValid) return;
    setIsLoader(true);
    const response = await UsersApi.register(
      {
        phoneNumber: registerValues.phoneNumber,
        email: registerValues.email,
        password: registerValues.password,
        isSpecialist: false,
      },
      selectLang || "en"
    );

    if (!response.status && response.message) {
      setIsLoader(false);
      setRegError(response.message);
      return;
    }

    if (response.status && response.userData) {
      if (response.skipTwilioConfirm) {
        storeData(response.skipTwilioConfirm.toString(), "skipTwilioConfirm");
      }
      localStorage.setItem("isSpecialist", false.toString());
      storeData(response.userData._id, "userId");
      setUserData(response.userData);
      navigate("/confirm");
    }
    setRegisterVaues({
      phoneNumber: "",
      email: "",
      password: "",
    });
    setRegError("");
    setIsLoader(false);
  };

  const handlerAuth = async (e?: React.FormEvent<HTMLFormElement>) => {
    e && e.preventDefault();

    if (
      /*   authValues.phoneNumberOrEmail === "" ||
      authValues.password === "" || */
      !selectLang
    )
      return;
    const response = await UsersApi.auth(
      authValues.phoneNumberOrEmail,
      authValues.password,
      selectLang
    );

    if (!response.status && response.message) setAuthError(response.message);
    if (
      response.status &&
      response.userData &&
      response.token &&
      response.refreshToken
    ) {
      setAuthValues({
        phoneNumberOrEmail: "",
        password: "",
        rememberMe: false,
      });
      setUserData(response.userData);
      storeData(response.token, "token");
      storeData(response.refreshToken, "refreshToken");
      storeData(response.userData._id, "userId");
    }
    if (
      response.status &&
      response.userData &&
      !response.userData.phoneNumber &&
      response.token &&
      response.refreshToken
    ) {
      navigate("/connect-phone-number");
      return;
    }
    if (
      response.status &&
      !response.userData?.registerConfirmed &&
      response.token &&
      response.refreshToken
    ) {
      navigate("/confirm");
      return;
    }
    if (
      response.status &&
      response.token &&
      response.refreshToken &&
      response.userData &&
      response.userData.registerFinished
    ) {
      localStorage.setItem("isSpecialist", false.toString());
      setIsAuth(true);
      setIsSetupProfileInfo(true);
      navigate(profilePathName ?? "/home");
      localStorage.removeItem("profilePathName");
    }
  };

  if (isLoader) {
    return (
      <div className="loader-container">
        <ThemeProvider theme={theme}>
          <CircularProgress size={150} color="primary" />
        </ThemeProvider>
      </div>
    );
  }

  return (
    <div className={s.mainContainer}>
      <div className={s.header}>
        <div
          role="button"
          className={s.backBtn}
          onClick={() => navigate("/choice-mode")}
        >
          <ArrowLeft />
        </div>
        <SiteLangsTabs />
      </div>
      <>
        <div className={s.container}>
          {isSignUp ? (
            <div className={s.signUpBlock}>
              <form
                action="#"
                onSubmit={(e) => handleRegister(e)}
                className={s.form}
              >
                <img src={Logo} alt="" className={s.logo} />
                <h1 className={s.heading}>{strings.welcome}</h1>
                <div className={s.inputsBlock}>
                  <div className={s.inputBlock}>
                    <span className={s.inputLable}>
                      {strings.phoneNumberLable}
                    </span>
                    <PhoneNumberInput
                      value={registerValues.phoneNumber}
                      onChangeFunction={(value) =>
                        setRegisterVaues((prev) => ({
                          ...prev,
                          phoneNumber: value,
                        }))
                      }
                    />
                  </div>
                  <div className={s.inputBlock}>
                    <span className={s.inputLable}>
                      {strings.emailAuthLable}
                    </span>
                    <Input
                      inputValue={registerValues.email}
                      isVisible
                      onChangeInput={(value) =>
                        setRegisterVaues((prev) => ({
                          ...prev,
                          email: value,
                        }))
                      }
                      required
                      placeholder={"name@example.com"}
                      type="email"
                    />
                  </div>
                  <div className={s.inputBlock}>
                    <span className={s.inputLable}>
                      {strings.passwordAuthLable}
                    </span>
                    <Input
                      inputValue={registerValues.password}
                      isVisible={passwordVisible.register}
                      onChangeInput={(value) =>
                        setRegisterVaues((prev) => ({
                          ...prev,
                          password: value,
                        }))
                      }
                      required
                      placeholder={"********"}
                    />
                    <img
                      data-testid="register-visible-switcher"
                      src={passwordVisible.register ? EyeSlashIcon : EyeIcon}
                      alt=""
                      className={s.eyeIcon}
                      onClick={() =>
                        setPasswordVisible((prev) => ({
                          ...prev,
                          register: !prev.register,
                        }))
                      }
                    />
                  </div>
                  <p className={s.error}>{regError}</p>
                </div>
                <StandartButton
                  action={handleRegister}
                  buttonTitle={strings.authContinueBtn}
                  width="300px"
                />
                <p
                  className={s.switchAuthReg}
                  onClick={() => setIsSignUp(false)}
                >
                  {strings.alreadyHaveAccMob}
                </p>
              </form>
            </div>
          ) : (
            <div className={s.signInBlock}>
              <form
                action="#"
                onSubmit={(e) => handlerAuth(e)}
                className={s.form}
              >
                <img src={Logo} alt="" className={s.logo} />
                <h1 className={s.heading}>{strings.welcome}</h1>
                <div className={s.inputsBlock}>
                  <div className={s.inputBlock}>
                    <span className={s.inputLable}>
                      {strings.phoneOrEmailLable}
                    </span>
                    <Input
                      inputValue={authValues.phoneNumberOrEmail}
                      isVisible
                      onChangeInput={(value) =>
                        setAuthValues((prev) => ({
                          ...prev,
                          phoneNumberOrEmail: value,
                        }))
                      }
                      required
                      placeholder={"+380 11 111 1111"}
                    />
                  </div>

                  <div className={s.inputBlock}>
                    <span className={s.inputLable}>
                      {strings.passwordAuthLable}
                    </span>
                    <Input
                      inputValue={authValues.password}
                      isVisible={passwordVisible.auth}
                      onChangeInput={(value) =>
                        setAuthValues((prev) => ({
                          ...prev,
                          password: value,
                        }))
                      }
                      required
                      placeholder={"********"}
                    />
                    <img
                      data-testid="register-visible-switcher"
                      src={passwordVisible.auth ? EyeSlashIcon : EyeIcon}
                      alt=""
                      className={s.eyeIcon}
                      onClick={() =>
                        setPasswordVisible((prev) => ({
                          ...prev,
                          auth: !prev.auth,
                        }))
                      }
                    />
                  </div>
                  <div className={s.rememberBlock}>
                    <Checkbox
                      color="success"
                      checked={authValues.rememberMe}
                      onClick={() =>
                        setAuthValues((prev) => ({
                          ...prev,
                          rememberMe: !prev.rememberMe,
                        }))
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <span>{strings.rememberMe}</span>
                  </div>
                  <Link to="/reset-password" className={s.resetPassword}>
                    {strings.forgotPassword}
                  </Link>
                  <p className={s.error}>{authError}</p>
                </div>

                <StandartButton
                  action={handlerAuth}
                  buttonTitle={strings.authContinueBtn}
                  width="300px"
                />
                <div className={s.switchAuthRegBlock}>
                  <span>{strings.newUserMob}</span>
                  <span onClick={() => setIsSignUp(true)} role="button">
                    {strings.signUpBtn}
                  </span>
                </div>
              </form>
            </div>
          )}
        </div>
        <div className={s.mobileAuth}>
          <MobileAuthorization
            authValues={authValues}
            registarValues={registerValues}
            setAuthValues={setAuthValues}
            setRegisterValues={setRegisterVaues}
            authHandler={handlerAuth}
            passwordVisible={passwordVisible}
            setPasswordVisible={setPasswordVisible}
            handleRegister={handleRegister}
            authError={authError}
            regError={regError}
          />
        </div>
      </>
      <div className={s.footerBlock}></div>
      <img
        src={isSignUp ? RegBgImage : AuthBgImage}
        alt=""
        className={s.regBgImage}
      />
    </div>
  );
};

export default Authorization;

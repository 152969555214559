import { Dispatch, SetStateAction, useEffect, useState } from "react";
import SpecialistApi from "../../../../api/specialistApi";
import CustomTooltip from "../../../../components/CustomTooltip/CustomTooltip";
import MuiSwitch from "../../../../components/MuiSwitch/MuiSwitch";
import MultipleSelect from "../../../../components/MultipleSelect/MultipleSelect";
import { ISO639Lang } from "../../../../constans/languagesList";
import strings from "../../../../localization";
import { SpecialistData } from "../../../../types/TypeSpecialist";
import s from "./Services.module.css";

interface ServiceConfigBase {
  title: string;
  description: string;
  value: boolean;
  allowed: boolean;
  price?: {
    language: string;
    clientCost: number;
    specialistIncome: number;
    selected: boolean;
  }[];
}

type ServiceConfig = ServiceConfigBase;

type ServicesData = Record<string, ServiceConfig>;

type ServicesProps = {
  specialistData: SpecialistData;
  allSpecializationData: {
    specializationValue: string;
    specializationLable: string;
    specialistData: SpecialistData;
  }[];
  setSelectedSpecialistData: Dispatch<
    SetStateAction<
      | {
          specializationValue: string;
          specializationLable: string;
          specialistData: SpecialistData;
        }
      | undefined
    >
  >;
};

const Services = ({
  specialistData,
  allSpecializationData,
  setSelectedSpecialistData,
}: ServicesProps) => {
  const token = localStorage.getItem("token");
  const currentServicesData: ServicesData = {
    showInCatalog: {
      title: strings.showCatologTitle,
      description: strings.showCatologDescription,
      value: specialistData.servicesConfig.showInCatalog.switch,
      allowed: specialistData.servicesConfig.showInCatalog.allowed,
    },
    showInSubscription: {
      title: strings.showInSubscriptionTitle,
      description: strings.showInSubscriptionDescription,
      value: specialistData.servicesConfig.showInSubscription.switch,
      allowed: specialistData.servicesConfig.showInSubscription.allowed,
    },
    showInTreeQuiz: {
      title: strings.showinQuizeTitle,
      description: strings.showinQuizeDescription,
      value: specialistData.servicesConfig.showInTreeQuiz.switch,
      allowed: specialistData.servicesConfig.showInTreeQuiz.allowed,
    },

    showInProjects: {
      title: strings.showInProjectsTitle,
      description: strings.showInProjectsDescription,
      value: specialistData.servicesConfig.showInProjects.switch,
      allowed: specialistData.servicesConfig.showInProjects.allowed,
    },
  };

  const [servicesData, setServicesData] =
    useState<ServicesData>(currentServicesData);
  const updateServiceConfig = async (
    key: keyof ServicesData,
    value: boolean
  ) => {
    if (!token) return;
    const updateData = {
      _id: specialistData._id,
      servicesConfig: { [key]: { switch: value } },
    };

    const response = await SpecialistApi.update(updateData, token);

    if (response.status) {
      setSelectedSpecialistData((prev) => ({
        ...prev!,
        specialistData: {
          ...prev!.specialistData,
          servicesConfig: {
            ...prev!.specialistData.servicesConfig,
            [key]: {
              //@ts-expect-error
              ...prev!.specialistData.servicesConfig[key],
              switch: value,
            },
          },
        },
      }));
    }
    if (!response.status) {
      setServicesData((prev) => ({
        ...prev,
        [key]: { ...prev[key], value: !value },
      }));
    }
  };
  useEffect(() => {
    setServicesData((prev) => ({
      showInCatalog: {
        ...prev.showInCatalog,
        value: specialistData.servicesConfig.showInCatalog.switch,
        allowed: specialistData.servicesConfig.showInCatalog.allowed,
      },
      showInProjects: {
        ...prev.showInProjects,
        value: specialistData.servicesConfig.showInProjects.switch,
        allowed: specialistData.servicesConfig.showInProjects.allowed,
      },
      showInSubscription: {
        ...prev.showInSubscription,
        value: specialistData.servicesConfig.showInSubscription.switch,
        allowed: specialistData.servicesConfig.showInSubscription.allowed,
      },
      showInTreeQuiz: {
        ...prev.showInTreeQuiz,
        value: specialistData.servicesConfig.showInTreeQuiz.switch,
        allowed: specialistData.servicesConfig.showInTreeQuiz.allowed,
      },
    }));
  }, [specialistData]);

  const sendToReviewe = async () => {
    if (!token) return;
    const updateData = {
      _id: specialistData._id,
      servicesConfig: { reviewRequested: true },
    };

    const response = await SpecialistApi.update(updateData, token);
    if (response.status) {
      setSelectedSpecialistData((prev) => ({
        ...prev!,
        specialistData: {
          ...prev!.specialistData,
          servicesConfig: {
            ...prev!.specialistData.servicesConfig,
            reviewRequested: true,
          },
        },
      }));
    }
  };
  useEffect(() => {
    (async () => {
      if (!token) return;
      const response = await SpecialistApi.getSpecialistPricing(
        token,
        specialistData._id
      );

      if (response.status && response.pricing) {
        const pricingData = {
          showInTreeQuiz: response.pricing.selection.map((item, index) => ({
            ...item,
            selected: index === 0,
          })),
          showInSubscription: response.pricing.selection.map((item, index) => ({
            ...item,
            selected: index === 0,
          })),
          showInProjects: response.pricing.projects,
        };
        setServicesData((prev) => ({
          ...prev,
          showInSubscription: {
            ...prev.showInSubscription,
            price: pricingData.showInSubscription,
          },
          showInTreeQuiz: {
            ...prev.showInTreeQuiz,
            price: pricingData.showInTreeQuiz,
          },
        }));
      }
    })();
  }, [specialistData]);

  return (
    <div className={s.container}>
      <div className={s.headBlock}>
        <div className={s.headTextBlock}>
          <span className={s.headTitle}>{strings.serviceSettingsTitle}</span>
          <span className={s.headDescription}>
            {strings.servicesSettingsDescription}
          </span>
          {!specialistData.servicesConfig.reviewRequested && (
            <div className={s.revieBlock}>
              <span>{strings.sendOnReviewDescr} </span>
              <span onClick={sendToReviewe}>{strings.clickHere}</span>
            </div>
          )}
        </div>
        {allSpecializationData.length > 1 && (
          <div className={s.selectSpecBlock}>
            <MultipleSelect
              data={allSpecializationData.map((item) => ({
                lable: item.specializationLable,
                value: item.specializationValue,
              }))}
              multiplie={false}
              value={specialistData.mainSpecializations[0].specialization}
              setValue={(value) =>
                setSelectedSpecialistData(
                  allSpecializationData.find(
                    (el) => el.specializationValue === value
                  )
                )
              }
              padding="10px"
              width="100%"
            />
          </div>
        )}
      </div>
      <div className={s.configBlock}>
        {Object.entries(servicesData).map(([key, config]) => (
          <div className={s.configElement} key={key}>
            <div className={s.serviceConfigMainBlock}>
              <div className={s.configTextBlock}>
                <span className={s.configTitle}>{config.title}</span>
                <span className={s.configDescription}>
                  {config.description}
                </span>
              </div>

              <CustomTooltip
                title={strings.noAllowed}
                hideTooltip={config.allowed}
              >
                <div style={{ opacity: config.allowed ? 1 : 0.5 }}>
                  <MuiSwitch
                    checked={config.value}
                    setChecked={(value) => {
                      setServicesData((prev) => ({
                        ...prev,
                        [key]: { ...prev[key], value: value },
                      }));
                      updateServiceConfig(key, value);
                    }}
                    lable=""
                    disabled={config.allowed ? false : true}
                  />
                </div>
              </CustomTooltip>
            </div>
            {config.price && (
              <div className={s.servicePriceBlock}>
                <div className={s.priceLangsBlock}>
                  {config.price.map((item, index) => (
                    <div
                      key={index}
                      className={`${s.langElement} ${
                        item.selected ? s.selectedLang : ""
                      }`}
                      onClick={() =>
                        setServicesData((prev) => ({
                          ...prev,
                          [key]: {
                            ...prev[key],
                            price: prev[key].price?.map(
                              (priceItem, priceIndex) => {
                                if (index === priceIndex) {
                                  return { ...priceItem, selected: true };
                                }
                                return { ...priceItem, selected: false };
                              }
                            ),
                          },
                        }))
                      }
                    >
                      {
                        //@ts-expect-error
                        ISO639Lang[item.language]
                      }
                    </div>
                  ))}
                </div>
                <div className={s.servicePriceValueBlock}>
                  <div className={s.servicePriceElement}>
                    <span className={s.servicePrivceValue}>
                      ${config.price.find((el) => el.selected)?.clientCost}
                    </span>
                    <span className={s.priceDescription}>
                      {strings.clientCost}
                    </span>
                  </div>
                  <div className={s.servicePriceElement}>
                    <span className={s.servicePrivceValue}>
                      $
                      {config.price.find((el) => el.selected)?.specialistIncome}
                    </span>
                    <span className={s.priceDescription}>
                      {strings.specIncome}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Services;

import dayjs from "dayjs";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useDispatch } from "react-redux";
import SessionsApi from "../../../api/sessionApi";
import CircleAvatar from "../../../components/CircleAvatar/CircleAvatar";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import NavigateToProfile from "../../../components/NavigateToProfile/NavigateToProfile";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import { setSelectedInitialCalendarDate } from "../../../redux-toolkit/slices/InitialCalendarSlice";
import { formatDateTimeWithOutTimezone } from "../../../utils/general";
import { getTranslation } from "../../../utils/getTranslation";
import { SuccesNotify } from "../../../utils/toaster";
import { PackageBookingType, SessionsData } from "../Initial";
import s from "./PackageBookingCard.module.css";

type PackageBookingCardProps = {
  packageBookingData: PackageBookingType;
  setPackageBookingData: Dispatch<SetStateAction<PackageBookingType[] | null>>;
  setSessionsData?: Dispatch<SetStateAction<null | SessionsData[]>>;
};

const PackageBookingCard = ({
  packageBookingData,
  setPackageBookingData,
  setSessionsData,
}: PackageBookingCardProps) => {
  const { userData } = useContext(ContextProvider);
  const token = localStorage.getItem("token");
  const [scheduleModal, setScheduleModal] = useState(false);
  const dispatch = useDispatch();

  const acceptBooking = async () => {
    if (!token) return;
    const response = await SessionsApi.acceptPackageBooking(
      token,
      packageBookingData.packageId
    );

    if (response.status && response.sessions) {
      const newSession = response.sessions.map((item) => {
        /*    const clientData = item.invitedUsers.find(
          (user) =>
            user.specialistIds === undefined ||
            !user.specialistIds.length ||
            !user.specialistIds
              .map((specId) => specId._id)
              .includes(item.specialists[0])
        ); */
        const specialistData = item.invitedUsers.find(
          (user) => user._id === response.from._id
        );

        return {
          userId: response.from._id,
          specialistId: item.specialists && item.specialists[0],
          isMySession: true,
          bookingId: item._id,
          bookedSessionId: item._id,
          acceptSession: true,
          clientData: {
            _id: response.from!._id,
            name: getTranslation(
              response.from!.name!,
              userData?.selectedLanguage
            ),
            surname: getTranslation(
              response.from!.surname!,
              userData?.selectedLanguage
            ),
          },
          specialistData: {
            _id: response.for!._id,
            name: getTranslation(
              response.for!.name!,
              userData?.selectedLanguage
            ),
            surname: getTranslation(
              response.for!.surname!,
              userData?.selectedLanguage
            ),
          },
          specialistPay: item.sessionCost,
          specializations:
            (specialistData &&
              specialistData.specialistIds![0].specializations) ||
            [],
          duration: item.datesAndTime[0].duration,
          avatar: response.from!.avatar,
          sessionDate: formatDateTimeWithOutTimezone(item.datesAndTime[0].date)
            .formattedDate,
          sessionTime: formatDateTimeWithOutTimezone(item.datesAndTime[0].date)
            .formattedTime,
          project: item.project,
          package: item.packageRef,
          isAccepted: true,
        };
      });
      setSessionsData &&
        setSessionsData((prev) => {
          if (prev) {
            return [...prev, ...newSession];
          } else {
            return newSession;
          }
        });

      dispatch(
        setSelectedInitialCalendarDate(
          dayjs(response.sessions[0]!.datesAndTime[0].date)
        )
      );
      SuccesNotify(strings.sessionAccepted);
      setScheduleModal(false);

      setPackageBookingData((prev) =>
        prev
          ? prev.filter((el) => el.packageId !== packageBookingData.packageId)
          : prev
      );
    }
  };

  const cancelBooking = async () => {
    if (!token) return;
    const response = await SessionsApi.cancelPackageBooking(
      token,
      packageBookingData.packageId
    );
    if (response.status) {
      setScheduleModal(false);
      SuccesNotify(strings.bookingHasCanceled);
      setPackageBookingData((prev) =>
        prev
          ? prev.filter((el) => el.packageId !== packageBookingData.packageId)
          : prev
      );
    }
  };

  const addDurationToTime = (time: string, duration: number): string => {
    const format = "HH:mm";
    const startTime = dayjs(time, format);
    const endTime = startTime.add(duration, "minute");
    return endTime.format(format);
  };

  return (
    <div className={s.container}>
      <div className={s.headBlock}>
        <span className={s.didtConfirmStatus}>{strings.didNotConfirm}</span>

        {packageBookingData.creationDate && (
          <span className={s.yearBlock}>
            {formatDateTimeWithOutTimezone(packageBookingData.creationDate)
              .formattedTime +
              " " +
              formatDateTimeWithOutTimezone(packageBookingData.creationDate)
                .formattedDate}
          </span>
        )}
      </div>
      <div className={s.themeBlock}>
        <span>
          {strings.packageRequestCardTitle}{" "}
          <span className={s.packageName}>
            {packageBookingData.packageTitle.find(
              (el) => el.language === userData?.selectedLanguage
            )?.text ?? packageBookingData.packageTitle[0].text}
          </span>
        </span>
      </div>
      <div className={s.actionBlock}>
        <NavigateToProfile userId={packageBookingData.userId}>
          <div className={s.avatarBlock}>
            <CircleAvatar
              userId={packageBookingData.userId}
              width="35px"
              height="35px"
              fontSize="18px"
            />
            <span>
              {packageBookingData.specialistName +
                " " +
                packageBookingData.specialistSurname}
            </span>
          </div>
        </NavigateToProfile>
        <div
          role="button"
          className={s.showSheduleBtn}
          onClick={() => setScheduleModal(true)}
        >
          {strings.showShedule}
        </div>
      </div>
      <ModalWindow
        isOpen={scheduleModal}
        setIsOpen={setScheduleModal}
        width="fit-content"
      >
        <div className={s.schedulModalContainer}>
          <h2>{strings.sessionsShedule}</h2>
          <div className={s.sessionsSchedulList}>
            {packageBookingData.bookings.map((item, index) => (
              <div key={index} className={s.scheduleElement}>
                <div className={s.leftSchedulBlock}>
                  <span>{"#" + (index + 1)}</span>
                  <span>
                    {getTranslation(item.title, userData?.selectedLanguage)}
                  </span>
                </div>
                <div className={s.rightSchedulBlock}>
                  <span>
                    {formatDateTimeWithOutTimezone(item.datesAndTime[0].date)
                      .formattedTime +
                      " - " +
                      addDurationToTime(
                        formatDateTimeWithOutTimezone(item.datesAndTime[0].date)
                          .formattedTime,
                        item.datesAndTime[0].duration
                      ) +
                      ", " +
                      formatDateTimeWithOutTimezone(item.datesAndTime[0].date)
                        .formattedDate +
                      "." +
                      new Date().getFullYear()}{" "}
                  </span>
                </div>
              </div>
            ))}
          </div>
          {!packageBookingData.isMySession && (
            <div className={s.actionModalBlock}>
              <StandartButton
                action={acceptBooking}
                buttonTitle={strings.acceptBtn}
                width="150px"
              />
              <button className={s.rejectBtn} onClick={cancelBooking}>
                {strings.rejectBtn}
              </button>
            </div>
          )}
        </div>
      </ModalWindow>
    </div>
  );
};

export default PackageBookingCard;

import { CircularProgress, Pagination, ThemeProvider } from "@mui/material";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import ProjectApi from "../../api/projectApi";
import SpecialistApi from "../../api/specialistApi";
import CalendarIcon from "../../assets/Header/calendar.svg";
import { ReactComponent as ChevronIcon } from "../../assets/SpecialistSetupInfo/arrow-chevron.svg";
import Loader from "../../components/Loader/Loader";
import MuiSwitch from "../../components/MuiSwitch/MuiSwitch";
import MultipleSelect from "../../components/MultipleSelect/MultipleSelect";
import StandartButton from "../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import { MyProjectType } from "../../types/Company";
import { ProjectsAvailableSessionTypes } from "../../types/Projects";
import { TypeSpecialistFilter } from "../../types/TypeSpecialist";
import { TypeUserData } from "../../types/TypeUsers";
import { formateDate } from "../../utils/general";
import { theme } from "../../utils/theme";
import Notify, { SuccesNotify } from "../../utils/toaster";
import MotionDiv from "./MotionDiv/MotionDiv";
import MyProgramCard from "./MyProgramCard/MyProgramCard";
import s from "./MyPrograms.module.css";
import ProgramSpecialistCard from "./ProgramSpecialistCard/ProgramSpecialistCard";

const connectionString = process.env.REACT_APP_HTTP_CONNECTION_STRING;

type FiltersData = {
  mainSpecializations: { lable: string; value: string }[];
  languagesList: { lable: string; value: string }[];
};

const MyPrograms: React.FC = () => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const projectId = queryParams.get("projectId");

  const mainRef = useRef<HTMLDivElement | null>(null);
  const [selectProgram, setSelectProgram] = useState<MyProjectType | null>(
    null
  );
  const [projectsData, setProjectsData] = useState<MyProjectType[] | null>(
    null
  );
  const [actionLoader, setActionLoader] = useState({
    joinProjectLoader: false,
    loadProjects: false,
    loadSpecialists: false,
  });
  const [cardWidth, setCardWidth] = useState(0);
  const [dataOfFilters, setDataOfFilters] = useState<FiltersData>({
    mainSpecializations: [],
    languagesList: [],
  });
  const [specialistsFilters, setSpecialistsFilters] = useState<
    TypeSpecialistFilter & { page: number }
  >({
    page: 1,
    specialistId: { isVerified: true },
  });
  const [projectSpecialists, setProjectSpecialists] = useState<
    TypeUserData[] | undefined
  >();
  const [specialistsUpdateCounter, setSpecialistsUpdateCounter] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const timeFiltersData = [
    {
      value: "06:00-12:00",
      lable: strings.morning,
    },
    {
      value: "12:00-18:00",
      lable: strings.afternoon,
    },
    {
      value: "18:00-23:59",
      lable: strings.evening,
    },
  ];

  const onFilterChanges = () => {
    setSpecialistsUpdateCounter((prev) => prev + 1);
    setSpecialistsFilters((prev) => ({ ...prev, page: 1 }));
  };

  useEffect(() => {
    setSpecialistsFilters({
      page: 1,
      specialistId: { isVerified: true },
    });
  }, [selectProgram]);

  useEffect(() => {
    (async () => {
      if (!token || !selectProgram || !userData) return;
      const payload = {
        userId: userData._id,
        filters: {
          preferredLanguages: specialistsFilters.preferredLanguages,
          specialistId: specialistsFilters.specialistId,
          page: specialistsFilters.page,
          limit: 10,
          receiveRealTimeOffers: specialistsFilters.receiveRealTimeOffers,
          time: specialistsFilters.time,
          considerOverlaps: true,
          sort: {
            field: "_id",
            type: -1,
          },
        },
        projectId: selectProgram._id,
      };
      setActionLoader((prev) => ({ ...prev, loadSpecialists: true }));

      const response = await ProjectApi.getFilteredProjectSpecialists(
        token,
        payload
      );

      setActionLoader((prev) => ({ ...prev, loadSpecialists: false }));
      if (response.paramsAvailable) {
        const languages = response.paramsAvailable.preferredLanguages
          .sort()
          .map((item) => ({
            //@ts-expect-error
            lable: strings[item],
            value: item,
          }));

        setTotalPages(Math.ceil(response.paramsAvailable.totalAmount / 10));
        setDataOfFilters((prev) => ({ ...prev, languagesList: languages }));
        const mainSpecResposne = await SpecialistApi.getMainSpecializations(
          token
        );

        if (mainSpecResposne.status && mainSpecResposne.mainSpecializations) {
          const availManSpecs = mainSpecResposne.mainSpecializations
            .filter((item) =>
              response.paramsAvailable.mainSpecializations.includes(
                item.specialization
              )
            )
            .map((item) => ({
              lable: item.labels.find(
                (item) => item.language === userData.selectedLanguage
              )?.text!,
              value: item.specialization,
            }));

          setDataOfFilters((prev) => ({
            ...prev,
            mainSpecializations: availManSpecs,
          }));
        }
      }
      if (response.status) {
        setProjectSpecialists(response.users);
      }
    })();
  }, [selectProgram, specialistsFilters.page, specialistsUpdateCounter]);

  useEffect(() => {
    if (!projectId || !projectsData) return;
    const currentSelectedProject = projectsData.filter(
      (el) => el._id === projectId
    );
    if (currentSelectedProject[0]) {
      setSelectProgram(currentSelectedProject[0]);
    }
  }, [projectId, projectsData]);

  useEffect(() => {
    if (!mainRef.current) return;

    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (window.innerWidth <= 768) {
          setCardWidth(entry.contentRect.width);
        } else {
          const currentCardWidth = entry.contentRect.width / 4;
          setCardWidth(currentCardWidth);
        }
      }
    });

    observer.observe(mainRef.current);

    return () => {
      if (!mainRef.current) return;
      observer.unobserve(mainRef.current);
    };
  }, [mainRef, mainRef.current]);

  useEffect(() => {
    if (!token || !userData) return;
    const makeAsync = async () => {
      setActionLoader((prev) => ({ ...prev, loadProjects: true }));
      const response = await ProjectApi.getUserProjects(
        token,
        userData._id,
        500,
        1
      );

      setActionLoader((prev) => ({ ...prev, loadProjects: false }));
      if (response.status && response.projects) {
        setProjectsData(response.projects);
      }
    };
    makeAsync();
  }, [userData, token]);

  const joinProjectHandler = async (
    userId: string | undefined,
    projectId: string | undefined
  ) => {
    if (!token || !userId || !projectId) return;
    setActionLoader((prev) => ({ ...prev, joinProjectLoader: true }));
    const response = await ProjectApi.joinNonPrivateProject(
      token,
      userId,
      projectId
    );

    if (!response.status && response.message) {
      Notify(response.message);
    }
    setActionLoader((prev) => ({ ...prev, joinProjectLoader: false }));
    if (response.status) {
      setSelectProgram((prev) => {
        if (!prev) return null;
        return { ...prev, clients: [...prev.clients, userId] };
      });
      const projectIndex = projectsData!.findIndex(
        (project) => project._id === projectId
      );
      if (projectIndex !== -1 && projectsData) {
        const updatedProject = {
          ...projectsData[projectIndex],
          clients: [...projectsData[projectIndex].clients, userId],
        };

        const updatedProjects = [
          ...projectsData.slice(0, projectIndex),
          updatedProject,
          ...projectsData.slice(projectIndex + 1),
        ];

        // Обновляем стейт
        setProjectsData(updatedProjects);
      }

      SuccesNotify(strings.succesJoinproject);
    }
  };

  const discountData = {
    [ProjectsAvailableSessionTypes.SESSION as string]: selectProgram
      ? selectProgram.sessionDiscount
      : 0,
    [ProjectsAvailableSessionTypes.PACKAGE as string]: selectProgram
      ? selectProgram.packageDiscount
      : 0,
  };

  if (actionLoader.loadProjects) {
    return (
      <div className={s.loaderContainer} ref={mainRef}>
        <Loader size={100} />
      </div>
    );
  }

  return (
    <div className={s.container} ref={mainRef}>
      <div
        className={`${s.programListBlock} ${!selectProgram ? s.rowList : ""} ${
          selectProgram
            ? s.mobileProgramListBlockHidden
            : s.mobileProgramListBlockVisible
        }`}
      >
        <MotionDiv
          flexDirection={
            window.innerWidth <= 768
              ? "column"
              : selectProgram
              ? "column"
              : "row"
          }
        >
          {projectsData && projectsData.length ? (
            projectsData.map((item, index) => (
              <motion.div
                key={index}
                layout
                transition={{
                  type: "tween",
                  duration: window.innerWidth > 768 ? 1 : 0,
                }}
                onClick={() => setSelectProgram(item)}
                style={{
                  width: `calc(${cardWidth}px - 0px)`,
                }}
                className={`${s.motionBlock} ${
                  !selectProgram ? s.nonSelectMotionBlock : ""
                }`}
              >
                <MyProgramCard
                  clientIds={item.clients}
                  programStartDate={formateDate(new Date(item.creationDate!))}
                  programTitle={item.title}
                  joinProjectHandler={joinProjectHandler}
                  projectId={item._id}
                  joinLoader={actionLoader.joinProjectLoader}
                  isSelected={item._id === selectProgram?._id}
                  discount={{
                    packageDiscount: item.packageDiscount,
                    sessionDiscount: item.sessionDiscount,
                  }}
                  projectType={item.availableSessionTypes![0].type}
                  availableSessions={item.availableSessions}
                />
              </motion.div>
            ))
          ) : (
            <div className={s.noProgramsBlock}>
              <h1>{strings.noProgramAvailable}</h1>
            </div>
          )}
        </MotionDiv>
      </div>

      {selectProgram && (
        <div
          className={
            !selectProgram
              ? `${s.programContentBlock} ${s.mobileProgramInfoBlockHidden}`
              : `${s.programContentBlock} ${s.mobileProgramInfoBlockVisible}`
          }
        >
          <div className={s.programHeaderContent}>
            <div
              className={s.backButton}
              onClick={() => setSelectProgram(null)}
            >
              <ChevronIcon className={s.chevronIcon} />
              <span>{strings.myProgramBackBtn}</span>
            </div>
            <div className={s.dateBlock}>
              <img src={CalendarIcon} alt="" />
              {selectProgram && (
                <span>
                  {formateDate(new Date(selectProgram.creationDate!))}
                </span>
              )}
            </div>
          </div>
          {selectProgram && (
            <div className={s.programInfoTitleBlock}>
              <div className={s.titleBlock}>
                <h3>{selectProgram.title}</h3>
                {discountData[selectProgram.availableSessionTypes![0].type] ? (
                  <div className={s.priceBlock}>
                    <span>{strings.myProgramDiscountTitle}</span>
                    <div className={s.priceValueBlock}>
                      <span>
                        -{" "}
                        {
                          discountData[
                            selectProgram.availableSessionTypes![0].type
                          ]
                        }
                        %{" "}
                      </span>
                    </div>
                  </div>
                ) : null}
              </div>
              <p>{selectProgram.description}</p>
              {selectProgram.video && (
                <video
                  src={`${connectionString}projects/getProjectVideoStream/${selectProgram.video}`}
                  className={s.video}
                  controls
                ></video>
              )}
            </div>
          )}
          {selectProgram && !selectProgram.clients.includes(userData?._id!) ? (
            <div className={s.programJoinBlock}>
              <div className={s.priceBlock}></div>

              {actionLoader.joinProjectLoader ? (
                <div className={s.loaderBlock}>
                  <ThemeProvider theme={theme}>
                    <CircularProgress size={40} color="primary" />
                  </ThemeProvider>
                </div>
              ) : (
                <StandartButton
                  action={() =>
                    joinProjectHandler(userData?._id, selectProgram._id)
                  }
                  buttonTitle={strings.joinBtn}
                  width="130px"
                />
              )}
            </div>
          ) : null}
          {selectProgram && !selectProgram.availableSessions![0].unlimited && (
            <div className={s.amountSessionBlock}>
              {selectProgram.availableSessions![0].type ===
              ProjectsAvailableSessionTypes.PACKAGE
                ? strings.formatString(strings.availablePackagesAmount, {
                    available: (
                      <span
                        style={{
                          color:
                            selectProgram.availableSessions![0].amount === 0
                              ? "red"
                              : "var(--primary-color)",
                          fontSize: "18px",
                        }}
                      >
                        {selectProgram.availableSessions![0].amount}
                      </span>
                    ),

                    amount: (
                      <span
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "18px",
                        }}
                      >
                        {selectProgram.availableSessions![0].outOf}
                      </span>
                    ),
                  })
                : strings.formatString(strings.availableSessionsAmount, {
                    available: (
                      <span
                        style={{
                          color:
                            selectProgram.availableSessions![0].amount === 0
                              ? "red"
                              : "var(--primary-color)",
                          fontSize: "18px",
                        }}
                      >
                        {selectProgram.availableSessions![0].amount}
                      </span>
                    ),

                    amount: (
                      <span
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "18px",
                        }}
                      >
                        {selectProgram.availableSessions![0].outOf}
                      </span>
                    ),
                  })}
            </div>
          )}
          <div className={s.programSpecialistListBlock}>
            <div className={s.filtersBlock}>
              <div className={s.filterInputBlock}>
                <MultipleSelect
                  data={[
                    ...dataOfFilters.mainSpecializations,
                    {
                      value: "initial",
                      lable: strings.filterAllSpecializations,
                    },
                  ]}
                  setValue={(value) => {
                    onFilterChanges();
                    setSpecialistsFilters((prev) => ({
                      ...prev,
                      specialistId: {
                        ...prev.specialistId!,
                        mainSpecializations:
                          value === "initial" ? undefined : [value as string],
                      },
                    }));
                  }}
                  value={
                    specialistsFilters.specialistId!.mainSpecializations
                      ? specialistsFilters.specialistId!.mainSpecializations
                      : "initial"
                  }
                  multiplie={false}
                  lable={strings.filterMainSpec}
                  padding="10px"
                  width="100%"
                />
              </div>
              <div className={s.filterInputBlock}>
                <MultipleSelect
                  data={dataOfFilters.languagesList}
                  setValue={(value) => {
                    onFilterChanges();
                    setSpecialistsFilters((prev) => ({
                      ...prev,
                      preferredLanguages:
                        typeof value === "string" ? [value] : value,
                    }));
                  }}
                  value={specialistsFilters.preferredLanguages ?? []}
                  multiplie={true}
                  lable={strings.filterLanguages}
                  checkBox
                  padding="10px"
                  width="100%"
                />
              </div>
              <div className={s.filterInputBlock}>
                <MultipleSelect
                  data={timeFiltersData}
                  setValue={(value) => {
                    onFilterChanges();
                    setSpecialistsFilters((prev) => ({
                      ...prev,
                      time: value as string,
                    }));
                  }}
                  value={specialistsFilters.time ?? ""}
                  multiplie={false}
                  lable={strings.timeFilter}
                  padding="10px"
                  width="100%"
                />
              </div>
              <MuiSwitch
                lable={strings.filterRealTimeSession}
                lablePlacement="start"
                checked={
                  specialistsFilters.receiveRealTimeOffers === undefined
                    ? false
                    : specialistsFilters.receiveRealTimeOffers
                }
                setChecked={(value) => {
                  onFilterChanges();
                  setSpecialistsFilters((prev) => ({
                    ...prev,
                    receiveRealTimeOffers: value,
                  }));
                }}
              />
            </div>
            <div className={s.specalistCardsHeader}>
              <span>{strings.specTableHeadLableName}</span>
              <span className={s.specializationLabel}>
                {strings.specTableHeadLableSpecialization}
              </span>
              <span className={s.langLable}>
                {strings.specTableHeadLableLanguages}
              </span>
              <span>{strings.myProgramPriceTitle}</span>
              <span>{strings.specTableHeadLableBookSession}</span>
            </div>
            {projectSpecialists && projectSpecialists.length ? (
              actionLoader.loadSpecialists ? (
                <div className={s.specialistsLoaderBlock}>
                  <Loader size={70} />
                </div>
              ) : (
                <div>
                  {projectSpecialists.map((item, index) => (
                    <ProgramSpecialistCard
                      specialistUserData={item}
                      bgColor={index % 2 !== 0 ? "#F5F5F5" : "#FFFFFF"}
                      projectId={selectProgram._id}
                      discount={
                        selectProgram.availableSessionTypes![0].type ===
                        ProjectsAvailableSessionTypes.PACKAGE
                          ? selectProgram.packageDiscount
                          : selectProgram.sessionDiscount
                      }
                      projectType={selectProgram.availableSessionTypes![0].type}
                      allSpecialistIds={selectProgram.specialists}
                      bookingsAmount={
                        selectProgram.availableSessions[0].unlimited
                          ? 1
                          : selectProgram.availableSessions![0].amount
                      }
                      key={index}
                    />
                  ))}
                  <div className={s.pagginationBlock}>
                    <Pagination
                      count={totalPages}
                      page={specialistsFilters.page}
                      onChange={(_, page) =>
                        setSpecialistsFilters((prev) => ({
                          ...prev,
                          page: page,
                        }))
                      }
                    />
                  </div>
                </div>
              )
            ) : (
              <div className={s.noSpecialistBlock}>{strings.noSpecFound}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyPrograms;

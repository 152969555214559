import { network } from "../config";
import { SessionPayment } from "../types/Balance";
import { TypeGoogleEvent, TypeSystemMessageData } from "../types/General";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import {
  BookedPackage,
  BookSessionPayload,
  BookSessionResponse,
  CommentSessionPayload,
  DefaultComments,
  SessionComment,
  SessionPackage,
  Sessions,
  UsersBookingResponse,
} from "../types/TypeSession";
import { TimeRange } from "../types/TypeSpecialist";
import { TypeUpdatedUserInfo, TypeUserData } from "../types/TypeUsers";
import customKy from "./kyExtend";

const { sessions } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "session/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class SessionsApi {
  static async bookSession(
    payload: BookSessionPayload,
    token: string
  ): Promise<
    TypeDefaultResponse & {
      booking?: BookSessionResponse;
      notEnoughBalance: boolean;
    }
  > {
    return await customKy
      .post(`${baseURL}${sessions.bookSession}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }
  static async getUsersBooking(
    userId: string,
    token: string
  ): Promise<
    TypeDefaultResponse & {
      bookings?: UsersBookingResponse[];
    }
  > {
    return await customKy
      .get(`${baseURL}${sessions.getUsersBooking}/${userId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }
  static async acceptBooking(
    userId: string,
    bookingId: string,
    token: string
  ): Promise<
    TypeDefaultResponse & {
      sessions?: Sessions[];
      userIdToNotify?: string;
      systemMessageData?: TypeSystemMessageData;
      googleEventsData?: {
        userId: string;
        eventInfo: TypeGoogleEvent;
      }[];
    }
  > {
    return await customKy
      .post(`${baseURL}${sessions.acceptBooking}/${bookingId}`, {
        headers: getAuthHeader(token),
        json: { userId, bookingId },
      })
      .then((res) => res.json());
  }

  static async getAllUserSessions(
    userId: string,
    token: string,
    startDate?: Date,
    endDate?: Date
  ): Promise<
    TypeDefaultResponse & {
      sessions?: Sessions[];
    }
  > {
    return await customKy
      .get(
        `${baseURL}${sessions.getAllUserSessions}/${userId}/${startDate}/${endDate}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async commentSession(
    token: string,
    payload: CommentSessionPayload
  ): Promise<
    TypeDefaultResponse & {
      comment?: SessionComment;
    }
  > {
    return await customKy
      .post(`${baseURL}${sessions.commentSession}/${payload.sessionId}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async cancelBooking(
    token: string,
    bookingId: string
  ): Promise<
    TypeDefaultResponse & {
      userIdToNotify?: string;
      systemMessageData?: {
        userIds: string[];
        message: string;
      };
    }
  > {
    return await customKy
      .post(`${baseURL}${sessions.cancelBooking}/${bookingId}`, {
        headers: getAuthHeader(token),
        json: { bookingId },
      })
      .then((res) => res.json());
  }

  static async getSpecialistsComments(
    userId: string,
    limit: number,
    page: number,
    specialistId?: string
  ): Promise<
    TypeDefaultResponse & {
      comments: SessionComment[];
      totalAmount: number;
    }
  > {
    const params = new URLSearchParams();
    if (specialistId && specialistId.length) {
      params.append("specialistId", specialistId);
    }

    return await customKy
      .get(
        `${baseURL}${
          sessions.getSpecialistsComments
        }/${userId}/${limit}/${page}?${params.toString()}`
      )
      .then((res) => res.json());
  }

  static async getSpecialistsSessionsAmount(
    userId: string,
    specialistId?: string
  ): Promise<
    TypeDefaultResponse & {
      amount: number;
    }
  > {
    const params = new URLSearchParams();
    if (specialistId && specialistId.length) {
      params.append("specialistId", specialistId);
    }
    return await customKy
      .get(
        `${baseURL}${
          sessions.getSpecialistsSessionsAmount
        }/${userId}?${params.toString()}`
      )
      .then((res) => res.json());
  }

  static async getSessionVisitDuration(
    token: string,
    sessionId: string
  ): Promise<
    TypeDefaultResponse & {
      sessionLogs?: { _id: string; totalSessionTime: number }[];
    }
  > {
    return await customKy
      .get(`${baseURL}${sessions.getSessionVisitDuration}/${sessionId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getSessionById(
    token: string,
    sessionId: string
  ): Promise<{
    session?: Sessions;
  }> {
    return await customKy
      .post(`${baseURL}${sessions.getSessionById}`, {
        headers: getAuthHeader(token),
        json: { sessionId },
      })
      .then((res) => res.json());
  }

  static async checkIfCanLeaveComment(
    token: string,
    sessionId: string
  ): Promise<TypeDefaultResponse> {
    return await customKy
      .get(`${baseURL}${sessions.checkIfCanLeaveComment}/${sessionId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getAllDefaultComments(token: string): Promise<
    TypeDefaultResponse & {
      defaultComments?: DefaultComments[];
    }
  > {
    return await customKy
      .get(`${baseURL}${sessions.getAllDefaultComments}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getUsersSessionPayments(
    token: string,
    userId: string,
    page: number,
    limit: number
  ): Promise<
    TypeDefaultResponse & {
      payments?: SessionPayment[];
    }
  > {
    return await customKy
      .get(
        `${baseURL}${sessions.getUsersSessionPayments}/${userId}/${page}/${limit}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async createSessionPackage(
    token: string,
    payload: {
      userId: string;
      specialistId: string;
      newPackage: Pick<
        SessionPackage,
        "title" | "description" | "packageCost" | "isActive" | "tags"
      > & {
        specializations: string[];
        project?: string | boolean;
        sessions: {
          title: { text: string; language: string }[];
          description?: { text: string; language: string }[];
          datesAndTime: {
            duration: number;
          }[];
          order: number;
        }[];
      };
    }
  ): Promise<
    TypeDefaultResponse & {
      package?: SessionPackage;
    }
  > {
    return await customKy
      .post(`${baseURL}${sessions.createSessionPackage}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async setSessionPackagePreviewFile(
    token: string,
    packageId: string,
    formData: FormData
  ): Promise<
    TypeDefaultResponse & {
      package?: SessionPackage | null;
    }
  > {
    return await customKy
      .post(`${baseURL}${sessions.setSessionPackagePreviewFile}/${packageId}`, {
        headers: getAuthHeader(token),
        body: formData,
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async getSpecialistSessionPackages(
    token: string,
    specialistUserId: string,
    specialistId?: string
  ): Promise<
    TypeDefaultResponse & {
      packages?: SessionPackage[] | null;
      overlapDays?: {
        specialist: string;
        overlaps: { day: string; overlapTime: TimeRange[] }[];
      }[];
    }
  > {
    const params = new URLSearchParams();
    if (specialistId && specialistId.length) {
      params.append("specialistId", specialistId);
    }
    return await customKy
      .get(
        `${baseURL}${
          sessions.getSpecialistSessionPackages
        }/${specialistUserId}?${params.toString()}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }
  static async updatePackage(
    token: string,
    updateData: TypeUpdatedUserInfo
  ): Promise<
    TypeDefaultResponse & {
      package?: SessionPackage | null;
    }
  > {
    return await customKy
      .post(`${baseURL}${sessions.updatePackage}/${updateData._id}`, {
        headers: getAuthHeader(token),
        json: { updateData },
      })
      .then((res) => res.json());
  }

  static async deletePackage(
    token: string,
    packageId: string
  ): Promise<
    TypeDefaultResponse & {
      package?: SessionPackage | null;
    }
  > {
    return await customKy
      .post(`${baseURL}${sessions.deletePackage}/${packageId}`, {
        headers: getAuthHeader(token),
        json: { packageId },
      })
      .then((res) => res.json());
  }

  static async bookSessionPackage(
    token: string,
    payload: {
      packageId: string;
      bookingsInfo: {
        title: string;
        description?: string;
        datesAndTime: { date: Date; duration: number }[];
        order: number;
      }[];
      sessionLanguage: string;
      projectId?: string;
      promocodeText?: string;
    }
  ): Promise<
    TypeDefaultResponse & {
      package?: BookedPackage | null;
      systemMessageData?: TypeSystemMessageData;
      notEnoughBalance: boolean;
    }
  > {
    return await customKy
      .post(`${baseURL}${sessions.bookSessionPackage}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async getUsersBookedPackages(
    token: string,
    userId: string
  ): Promise<
    TypeDefaultResponse & {
      packages?: BookedPackage[];
    }
  > {
    return await customKy
      .get(`${baseURL}${sessions.getUsersBookedPackages}/${userId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async acceptPackageBooking(
    token: string,
    packageId: string
  ): Promise<
    TypeDefaultResponse & {
      sessions?: Sessions[];
      userIdToNotify?: string;
      systemMessageData?: TypeSystemMessageData;
      from: TypeUserData;
      for: TypeUserData;
      googleEventsData?: {
        userId: string;
        eventInfo: TypeGoogleEvent;
      }[];
    }
  > {
    return await customKy
      .post(`${baseURL}${sessions.acceptPackageBooking}/${packageId}`, {
        headers: getAuthHeader(token),
        json: { packageId },
      })
      .then((res) => res.json());
  }

  static async cancelPackageBooking(
    token: string,
    packageId: string
  ): Promise<
    TypeDefaultResponse & {
      userIdToNotify?: string;
      systemMessageData?: TypeSystemMessageData;
    }
  > {
    return await customKy
      .post(`${baseURL}${sessions.cancelPackageBooking}/${packageId}`, {
        headers: getAuthHeader(token),
        json: { packageId },
      })
      .then((res) => res.json());
  }

  static async getSpecialistSessionPackageById(
    token: string,
    packageId: string
  ): Promise<
    TypeDefaultResponse & {
      package?: SessionPackage | null;
      overlapDays?: {
        specialist: string;
        overlaps: { day: string; overlapTime: TimeRange[] }[];
      }[];
    }
  > {
    return await customKy
      .get(
        `${baseURL}${sessions.getSpecialistSessionPackageById}/${packageId}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getPackageFiles(
    token: string,
    fileId: string
  ): Promise<
    TypeDefaultResponse & {
      packageImages?: {
        _id: string;
        link: string;
        type: string;
        filename: string;
        size: number;
      }[];
      packageVideos?: {
        _id: string;
        link: string;
        type: string;
        filename: string;
        size: number;
      }[];
      packageSessionImages?: {
        previewImage: {
          _id: string;
          link: string;
          type: string;
          filename: string;
          size: number;
        };
        title: string;
        description?: string | undefined;
        datesAndTime: {
          duration: number;
        }[];
        order: number;
      }[];
    }
  > {
    return await customKy
      .get(`${baseURL}${sessions.getPackageFiles}/${fileId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async deletePackageSessionFile(
    token: string,
    payload: {
      packageId: string;
      fileId: string;
      fileType: "packageImage" | "packageVideo" | "packageSessionImage";
    }
  ): Promise<
    TypeDefaultResponse & {
      package?: SessionPackage;
    }
  > {
    return await customKy
      .post(
        `${baseURL}${sessions.deletePackageSessionFile}/${payload.packageId}`,
        {
          headers: getAuthHeader(token),
          json: { payload },
        }
      )
      .then((res) => res.json());
  }

  static async checkIfCanBookPackage(
    token: string,
    userId: string,
    packageId: string
  ): Promise<
    TypeDefaultResponse & {
      canBook: boolean;
    }
  > {
    return await customKy
      .get(
        `${baseURL}${sessions.checkIfCanBookPackage}/${userId}/${packageId}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getGeneralPackagesInfo(
    token: string,
    specialistUserId: string
  ): Promise<
    TypeDefaultResponse & {
      totalBookedPackages: number;
      totalEarned: number;
      totalPackages: number;
    }
  > {
    return await customKy
      .get(`${baseURL}${sessions.getGeneralPackagesInfo}/${specialistUserId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getUsersSessionHistory(
    token: string,
    userId: string,
    filters: {
      price?: { from?: number; to?: number };
      isRefunded?: boolean;
      isPayedOut?: boolean;
      isFromPackage?: boolean;
      date?: { from?: Date; to?: Date };
    },
    page: number,
    limit: number
  ): Promise<
    TypeDefaultResponse & {
      sessions?: (Pick<
        Sessions,
        | "_id"
        | "title"
        | "description"
        | "sessionCost"
        | "project"
        | "isPayedOut"
        | "packageRef"
        | "isRefunded"
        | "isWaitingForRefund"
        | "tags"
        | "subject"
        | "isCanceled"
        | "recordForSpecAllowed"
        | "recordingNow"
        | "recordingAvailableAt"
      > & {
        specialists: TypeUserData[];
        clients: TypeUserData[];
        dateAndTime: {
          date: Date;
          duration: number;
        };
        visitDurations:
          | {
              _id: string;
              totalSessionTime: number;
            }[]
          | undefined;
      })[];
      totalAmount: number;
    }
  > {
    return await customKy
      .post(
        `${baseURL}${sessions.getUsersSessionHistory}/${userId}/${page}/${limit}`,
        {
          json: { filters },
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getSessionInfoById(
    token: string,
    sessionId: string
  ): Promise<
    TypeDefaultResponse & {
      session?: Sessions & {
        clients: TypeUserData[];
        specialistUsers: TypeUserData[];
      };
    }
  > {
    return await customKy
      .get(`${baseURL}${sessions.getSessionInfoById}/${sessionId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async allowAudioRecordForSpecialist(
    token: string,
    sessionId: string,
    approval: boolean
  ): Promise<TypeDefaultResponse> {
    return await customKy
      .post(
        `${baseURL}${sessions.allowAudioRecordForSpecialist}/${sessionId}`,
        {
          headers: getAuthHeader(token),
          json: { approval },
        }
      )
      .then((res) => res.json());
  }
}

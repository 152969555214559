import { network } from "../config";

const { recordings } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "recordings/";

export const fetchAudioStream = async (token: string, sessionId: string) => {
  try {
    const response = await fetch(
      `${baseURL}${recordings.getAudioRecordStream}/${sessionId}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (response.status === 204) {
      return;
    }

    if (!response.ok || !response.body || !response.headers) {
      throw new Error("Network response was not ok");
    }
    const reader = response.body.getReader();
    const chunks = [];
    while (true) {
      const { done, value } = await reader.read();
      if (done) break;
      chunks.push(value);
    }
    const blob = new Blob(chunks);
    const objectURL = URL.createObjectURL(blob);
    return objectURL;
  } catch (error) {
    console.error("Error fetching audio stream:", error);
    return;
  }
};

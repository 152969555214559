import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../assets/HomePage/close.svg";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import { PaymentFor, PaymentForType } from "../../types/Balance";
import { SpecialistTag } from "../../types/TypeSpecialist";
import { SuccesNotify } from "../../utils/toaster";
import ModalWindow from "../ModalWindow/ModalWindow";
import MultipleSelect from "../MultipleSelect/MultipleSelect";
import PaymentSession from "../PaymentSession/PaymentSession";
import StandartButton from "../StandartButton/StandartButton";
import s from "./RealTimeSessionModalContent.module.css";

type DropDownMenuData = { lable: string; value: string };

type RealTimeSessionModalContentProps = {
  fullName: string;
  sendRequestHandler: (
    sessionSubject: string,
    sessionTags: string[],
    setSubjectValue: Dispatch<SetStateAction<string>>,
    selectSpecialistId: string,
    sessionLang: string
  ) => Promise<{ status: boolean; notEnoughBalance?: boolean }>;
  realTimeError: string;
  allSessionLangs: { lable: string; value: string }[];
  specialistUserId: string;
  allSpecializations: {
    specialistId: string;
    lable: string;
    specialization: string;
    tags: SpecialistTag[];
  }[];
  priceData: {
    [id: string]: { price: number; discountPrice: number; language: string }[];
  };
  initialSpecialization?: string;
  isShowingSpecializationsTab?: boolean;
};

const RealTimeSessionModalContent = ({
  fullName,
  sendRequestHandler,
  realTimeError,
  specialistUserId,
  allSpecializations,
  initialSpecialization,
  isShowingSpecializationsTab = true,
  priceData,
  allSessionLangs,
}: RealTimeSessionModalContentProps) => {
  const navigate = useNavigate();
  const { setUserData, userData } = useContext(ContextProvider);
  const [subjectValue, setSubjectValue] = useState("");
  const [noBalanceModal, setNoBalanceModal] = useState(false);
  const [paymentFor, setPaymentFor] = useState<PaymentFor | null>(null);
  const [selectSpecialistId, setSelectSpecialistId] = useState(
    initialSpecialization ?? allSpecializations[0].specialistId
  );
  const [sessionTags, setSessionTags] = useState<
    { _id: string; lable: string }[]
  >([]);
  const [tagsData, setTagsData] = useState<DropDownMenuData[]>([]);
  const [selectedSessionLang, setSelectedSessionLang] = useState<
    string | undefined
  >(allSessionLangs.length === 1 ? allSessionLangs[0].value : undefined);

  /* const gatTags = async (level: number, parentTagsId?: string) => {
    if (!token || !selectSpecialistId || !userData) return;
    const direction =
      directions[
        allSpecializations.find((el) => el.specialistId === selectSpecialistId)
          ?.specialization!
      ];
    const payload = {
      level: level,
      direction: direction,
      parentTagsIds: parentTagsId ? [parentTagsId] : undefined,
    };

    const response = await SpecialistApi.getSpecialistTags(token, payload);

    if (response.status && response.tags) {
      const tagsData = response.tags.map((item) => ({
        lable: item.labels.find(
          (el) => el.language === userData.selectedLanguage
        )!.text,
        value: item._id,
      }));
      setTagsData(tagsData);
    }
  }; */
  useEffect(() => {
    if (!selectSpecialistId) return;
    /*     const parentTagsId =
      sessionTags.length > 0 &&
      sessionTags.length < 3 &&
      sessionTags[sessionTags.length - 1]._id !== "other"
        ? sessionTags[sessionTags.length - 1]._id
        : undefined;
    gatTags(sessionTags.length + 1, parentTagsId); */
    const currentSpecialistTags = allSpecializations.find(
      (el) => el.specialistId === selectSpecialistId
    )?.tags!;
    const correctLevelTags = currentSpecialistTags.filter(
      (el) => el.level === sessionTags.length + 1
    );
    const parentTagId = sessionTags.length
      ? sessionTags[sessionTags.length - 1]._id
      : undefined;
    const tagsToSetData = parentTagId
      ? correctLevelTags
          .filter((el) => el.parentTag === parentTagId)
          .map((item) => ({
            value: item._id,
            lable: item.labels.find(
              (el) => el.language === userData?.selectedLanguage
            )?.text!,
          }))
      : correctLevelTags.map((item) => ({
          value: item._id,
          lable: item.labels.find(
            (el) => el.language === userData?.selectedLanguage
          )?.text!,
        }));

    setTagsData(tagsToSetData);
  }, [selectSpecialistId, sessionTags]);

  const setPaymentForData = () => {
    if (!selectSpecialistId || !selectedSessionLang) return;

    const paymentForCurrent = {
      type: PaymentForType.REAL_TIME_SESSION,
      bookingFor: specialistUserId,
      subject: subjectValue,
      realTimeSessionDuration: 60,
      bookingForSpecialist: selectSpecialistId,
      sessionLanguage: selectedSessionLang,
      tags: sessionTags
        .filter((el) => el._id !== "other")
        .map((item) => item._id),
    };

    setPaymentFor(paymentForCurrent);
  };
  const sendRequest = async () => {
    if (!selectedSessionLang) return;
    const response = await sendRequestHandler(
      subjectValue,
      sessionTags.filter((el) => el._id !== "other").map((item) => item._id),
      setSubjectValue,
      selectSpecialistId,
      selectedSessionLang
    );
    if (!response.status && response.notEnoughBalance) {
      setPaymentForData();
      setNoBalanceModal(true);
    }
    if (response.status) {
      navigate("/global-chat", {
        state: {
          userId: specialistUserId,
        },
      });
      SuccesNotify(strings.bookingSucces);
      setUserData((prev) => ({
        ...prev!,
        balance:
          prev!.balance -
          priceData[selectSpecialistId].find(
            (el) => el.language === selectedSessionLang
          )?.discountPrice!,
      }));
    }
  };

  return (
    <div className={s.container}>
      <h3 className={s.headTitle}>{strings.realTimeSessionTitle}</h3>
      <div className={s.sessionDetailsBlock}>
        <h4>{strings.sessionDetails}</h4>
        {allSpecializations.length > 1 && isShowingSpecializationsTab ? (
          <div className={s.specializationTabsBlock}>
            {allSpecializations.map((item, index) => (
              <div
                key={index}
                className={
                  selectSpecialistId === item.specialistId
                    ? s.activeSpecializationTab
                    : s.notActiveSpecializationTab
                }
                onClick={() => {
                  setTagsData([]);
                  setSessionTags([]);
                  setSelectSpecialistId(item.specialistId);
                }}
              >
                {item.lable}
              </div>
            ))}
          </div>
        ) : null}
        <div className={s.sessionDetailsElement}>
          <span>{strings.specialistsrealTime} </span>
          <span>{fullName}</span>
        </div>
        {selectedSessionLang && (
          <div className={s.sessionDetailsElement}>
            <span>{strings.price}: </span>
            <span>
              {
                priceData[selectSpecialistId].find(
                  (el) => el.language === selectedSessionLang
                )?.discountPrice
              }
              $
            </span>
          </div>
        )}
        <div className={s.sessionDetailsElement}>
          <span>{strings.time} </span>
          <span>{strings.now}</span>
        </div>
      </div>
      <div>
        <p className={s.freeDaysTitle}>{strings.sessionLang}</p>
        <MultipleSelect
          data={allSessionLangs}
          multiplie={false}
          setValue={(value) => setSelectedSessionLang(value as string)}
          value={selectedSessionLang ?? ""}
          padding="10px"
          width="200px"
          margin="0 0 20px 0"
        />
      </div>
      <div className={s.sessionTagsSubjectSession}>
        {sessionTags.map((item) => item._id).includes("other") ? (
          <div className={s.subjectBlock}>
            <div className={s.selectedSessionTagsBlock}>
              {sessionTags && sessionTags.length
                ? sessionTags.map((item, index) =>
                    item._id === "other" ? (
                      <div key={index}></div>
                    ) : (
                      <div key={index} className={s.tagElement}>
                        <span>{item.lable}</span>{" "}
                        <CloseIcon
                          className={s.closeIcon}
                          onClick={() =>
                            setSessionTags((prev) => prev.slice(0, index))
                          }
                        />
                      </div>
                    )
                  )
                : null}
            </div>
            <div className={s.subjectTitleBlock}>
              <span className={s.grayText}>
                {strings.otherTextAreaDescription}
              </span>
              <span className={s.maxLength}>
                ({subjectValue.length + "/" + "100"})
              </span>
            </div>
            <textarea
              name=""
              id=""
              cols={30}
              rows={3}
              value={subjectValue}
              className={s.textAreaNewNote}
              onChange={(e) => setSubjectValue(e.target.value)}
              maxLength={100}
            ></textarea>
          </div>
        ) : (
          <div className={s.tagsBlock}>
            <div className={s.selectedSessionTagsBlock}>
              {sessionTags && sessionTags.length
                ? sessionTags.map((item, index) =>
                    item._id === "other" ? (
                      <div></div>
                    ) : (
                      <div key={index}>
                        <div className={s.tagElement}>
                          <span>{item.lable}</span>{" "}
                          <CloseIcon
                            className={s.closeIcon}
                            onClick={() =>
                              setSessionTags((prev) => prev.slice(0, index))
                            }
                          />
                        </div>
                      </div>
                    )
                  )
                : null}
            </div>
            {/*          <span className={s.freeDaysTitle}>
                    {tagsSteps[sessionTags.length]}
                  </span> */}
            {sessionTags.length < 3 && (
              <span className={s.grayText}>{strings.pleaseSelect}</span>
            )}
            {sessionTags.length < 3 && (
              <div className={s.tagsDataBlock}>
                {[
                  ...tagsData,
                  { value: "other", lable: strings.otherCertificate },
                ].map((item, index) => (
                  <div
                    key={index}
                    className={s.tagElement}
                    onClick={() => {
                      setSessionTags((prev) => [
                        ...prev,
                        { _id: item.value, lable: item.lable },
                      ]);
                    }}
                  >
                    {item.lable}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      <div className={s.errorTitle}>{realTimeError}</div>
      <div className={s.actionBlock}>
        <StandartButton
          action={sendRequest}
          buttonTitle={strings.sendRequestRealTimeBtn}
          disabled={
            (subjectValue.length || sessionTags.length === 3) &&
            selectedSessionLang
              ? false
              : true
          }
          tooltipTitle={
            subjectValue.length || sessionTags.length === 3
              ? ""
              : strings.subjectError
          }
        />
      </div>
      {paymentFor && (
        <ModalWindow
          isOpen={noBalanceModal}
          setIsOpen={setNoBalanceModal}
          width="fit-content"
        >
          <PaymentSession hidePromocode paymentFor={paymentFor} />
        </ModalWindow>
      )}
    </div>
  );
};

export default RealTimeSessionModalContent;

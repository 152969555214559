import { Dayjs } from "dayjs";

export const validateTime = (
  from: Dayjs | undefined,
  to: Dayjs | undefined
): boolean => {
  if (!from || !to) return true;
  if (!from.isValid() || !to.isValid()) return false;
  const diffMinutes = to.diff(from, "minute");
  return diffMinutes >= 60 && !from.isAfter(to); // Проверка на минимальный интервал и порядок
};

export const validateIntervals = (
  workTime: { from: string; to: string }[]
): boolean => {
  // Функция для конвертации времени в минуты с начала дня
  const timeToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  // Преобразуем интервалы в числовой вид и сортируем по началу
  const intervals = workTime
    .map(({ from, to }) => ({
      start: timeToMinutes(from),
      end: timeToMinutes(to),
    }))
    .sort((a, b) => a.start - b.start);

  // Проверяем, чтобы интервалы не пересекались
  for (let i = 1; i < intervals.length; i++) {
    if (intervals[i].start < intervals[i - 1].end) {
      return false; // Найдено пересечение
    }
  }

  return true; // Все интервалы корректны
};

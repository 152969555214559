import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SessionsApi from "../../api/sessionApi";
import { ReactComponent as CalendarIcon } from "../../assets/Header/calendar.svg";
import CommentComponent from "../../components/CommentComponent/CommentComponent";
import DateRabgePicker from "../../components/DateRangePicker/DateRangePicker";
import Loader from "../../components/Loader/Loader";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import MultipleSelect from "../../components/MultipleSelect/MultipleSelect";
import { ContextProvider } from "../../contextProvider";
import useClickOutside from "../../hooks/useClickOutside";
import strings from "../../localization";
import { setCommentModalVisible } from "../../redux-toolkit/slices/modalsSlice";
import { RootState } from "../../redux-toolkit/store";
import { Sessions } from "../../types/TypeSession";
import { TypeUserData } from "../../types/TypeUsers";
import { formatDateAndTime } from "../../utils/dateActions";
import s from "./HistoryOfSessions.module.css";
import SessionCard from "./SessionCard/SessionCard";
import SessionInfo from "./SessionInfo/SessionInfo";
import SessionsTable from "./SessionsTable/SessionsTable";

type SessionsFilters = {
  price?: { from?: number; to?: number };
  isRefunded?: boolean;
  isPayedOut?: boolean;
  isCanceled?: boolean;
  isFromPackage?: boolean;
  date?: { from?: Date; to?: Date };
};
interface DateRange {
  startDate: Date;
  endDate: Date;
  key: string;
}
interface SessionFilterValue {
  isRefunded?: boolean;
  isPayedOut?: boolean;
  isCanceled?: boolean;
}
type SessionStatusFilterValues = {
  [key: string]: SessionFilterValue | undefined;
};
export type HistorySession = Pick<
  Sessions,
  | "_id"
  | "title"
  | "description"
  | "sessionCost"
  | "project"
  | "isPayedOut"
  | "packageRef"
  | "isRefunded"
  | "isWaitingForRefund"
  | "tags"
  | "subject"
  | "isCanceled"
  | "recordForSpecAllowed"
  | "recordingNow"
  | "recordingAvailableAt"
> & {
  specialists: TypeUserData[];
  clients: TypeUserData[];
  dateAndTime: {
    date: Date;
    duration: number;
  };
  visitDurations:
    | {
        _id: string;
        totalSessionTime: number;
      }[]
    | undefined;
  audioRecord?: string;
};

const HistoryOfSessions = () => {
  const { userData } = useContext(ContextProvider);
  const token = localStorage.getItem("token");
  const datePickerMenuRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const [paganation, setPaganation] = useState({
    page: 0,
    rowPerPage: 5,
    total: 0,
  });
  const [filters, setFilters] = useState<SessionsFilters>({});
  const [sessionsData, setSessionsData] = useState<
    HistorySession[] | undefined
  >();
  const [selectedSession, setSelectedSession] = useState<
    HistorySession | undefined
  >();
  const [actionLoaders, setActionLoaders] = useState({ loadSessions: true });

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [sessionStatusFilterValue, setSessionStatusFilterValue] =
    useState("All");
  const [hasMore, setHasMore] = useState(true);
  const sessionCradListRef = useRef<any>(null);

  const reduxSelectedDate = useSelector(
    (state: RootState) => state.modals.sessionCommentModal
  );

  useClickOutside(datePickerMenuRef, () => setShowDatePicker(false));

  const sessionStatusFilterValues: SessionStatusFilterValues = {
    ["Completed"]: { isRefunded: false, isPayedOut: true },
    ["Scheduled"]: { isRefunded: false, isPayedOut: false },
    ["Missed"]: { isRefunded: true, isPayedOut: false },
    ["Canceled"]: { isCanceled: true },
    ["All"]: undefined,
  };
  const sessionStatusData = [
    {
      value: "All",
      lable: strings.allSessions,
    },
    {
      value: "Completed",
      lable: strings.onlyCompleted,
    },
    {
      value: "Scheduled",
      lable: strings.onlyScheduled,
    },
    {
      value: "Missed",
      lable: strings.onlyMissed,
    },
    {
      value: "Canceled",
      lable: strings.onlyCanceled,
    },
  ];
  useEffect(() => {
    setFilters((prev) => ({
      ...prev,
      isPayedOut:
        sessionStatusFilterValues[sessionStatusFilterValue]?.isPayedOut,
      isRefunded:
        sessionStatusFilterValues[sessionStatusFilterValue]?.isRefunded,
      isCanceled:
        sessionStatusFilterValues[sessionStatusFilterValue]?.isCanceled,
    }));
  }, [sessionStatusFilterValue]);

  const getSessions = async () => {
    if (!token || !userData || !hasMore) return;
    setActionLoaders((prev) => ({ ...prev, loadSessions: true }));

    const response = await SessionsApi.getUsersSessionHistory(
      token,
      userData._id,
      filters,
      paganation.page + 1,
      paganation.rowPerPage
    );

    setActionLoaders((prev) => ({ ...prev, loadSessions: false }));
    if (response.sessions && response.status) {
      setHasMore(response.sessions.length === paganation.rowPerPage);
      setPaganation((prev) => ({ ...prev, total: response.totalAmount }));
      if (window.innerWidth > 768) {
        setSessionsData(response.sessions);
      }
      if (window.innerWidth <= 768) {
        setSessionsData((prev) =>
          prev ? [...prev, ...response.sessions!] : response.sessions
        );
      }
    }
  };

  const handleScroll = () => {
    if (
      window.innerWidth <= 768 &&
      sessionCradListRef.current &&
      sessionCradListRef.current.scrollTop +
        sessionCradListRef.current.clientHeight >=
        sessionCradListRef.current.scrollHeight - 10 &&
      !actionLoaders.loadSessions &&
      hasMore
    ) {
      setActionLoaders((prev) => ({ ...prev, loadSessions: true }));
      setPaganation((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  useEffect(() => {
    if (window.innerWidth > 768) return;
    if (sessionCradListRef.current) {
      sessionCradListRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (sessionCradListRef.current) {
        sessionCradListRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    getSessions();
  }, [
    paganation.page,
    paganation.rowPerPage,
    filters.date,
    filters.isPayedOut,
    filters.isRefunded,
    filters.isCanceled,
  ]);

  /*  const { scrollContainerRef } = useInfiniteScroll({
    fetchData: getSessions,
    hasMore,
    isLoading: actionLoaders.loadSessions,
  }); */

  return (
    <div className={s.container}>
      <div className={s.filtersBlock}>
        <span className={s.filtersTitle}>
          {strings.sessionsHistoryFilterTitle}
        </span>
        <div className={s.filtersActionBlock}>
          <div className={s.dateRangeFilterBlock} ref={datePickerMenuRef}>
            <div
              className={s.selectDateRangeBlock}
              onClick={() => {
                setShowDatePicker((prev) => !prev);
              }}
              style={{
                backgroundColor: showDatePicker
                  ? "var(--secondary-color)"
                  : " var(--gray-main-color)",
              }}
            >
              <CalendarIcon width={25} height={25} />
              {filters.date
                ? formatDateAndTime(
                    filters.date.from!,
                    userData?.selectedLanguage!
                  ).formattedDate +
                  " - " +
                  formatDateAndTime(
                    filters.date.to!,
                    userData?.selectedLanguage!
                  ).formattedDate
                : strings.dateRange}
            </div>
            {showDatePicker && (
              <div className={s.datePickerBlock}>
                <DateRabgePicker
                  onDateChange={(value: DateRange) =>
                    setFilters((prev) => ({
                      ...prev,
                      date: { from: value.startDate, to: value.endDate },
                    }))
                  }
                />
              </div>
            )}
          </div>
          <div className={s.statusFilterBlock}>
            <MultipleSelect
              data={sessionStatusData}
              multiplie={false}
              setValue={(value) => setSessionStatusFilterValue(value as string)}
              value={sessionStatusFilterValue}
              padding="10px"
              width="100%"
            />
          </div>
        </div>
      </div>
      <div className={s.mainContent}>
        <>
          <div className={s.tableBlock}>
            <SessionsTable
              count={paganation.total}
              page={paganation.page}
              rows={sessionsData ?? []}
              rowsPerPage={paganation.rowPerPage}
              setPagination={setPaganation}
              loader={actionLoaders.loadSessions}
              setSelectedSession={setSelectedSession}
              selectedSession={selectedSession}
              setSessionsData={setSessionsData}
            />
          </div>
          <div
            ref={sessionCradListRef}
            className={
              selectedSession
                ? `${s.mobileTableBlockHidden}`
                : `${s.mobileTableBlockVisible}`
            }
          >
            {sessionsData &&
              sessionsData.map((item, index) => (
                <SessionCard
                  session={item}
                  setSelectedSession={setSelectedSession}
                  key={index}
                />
              ))}
            {actionLoaders.loadSessions && (
              <div className={s.rowCenterBlock}>
                <Loader size={30} />
              </div>
            )}
          </div>
          <div
            className={
              selectedSession
                ? `${s.sessionInfoBlock} ${s.sessionInfoBlockActive} ${s.mobileSessionVisible}`
                : `${s.sessionInfoBlock} ${s.mobileSessionHidden}`
            }
          >
            {selectedSession && (
              <SessionInfo
                selectedSession={selectedSession}
                closeInfoFunction={() => setSelectedSession(undefined)}
                setSelectedSession={setSelectedSession}
                setSessionsData={setSessionsData}
              />
            )}
          </div>
        </>
      </div>
      {selectedSession && (
        <ModalWindow
          isOpen={reduxSelectedDate}
          alternativeCloseFunction={() =>
            dispatch(setCommentModalVisible(false))
          }
          width="fit-content"
        >
          <CommentComponent
            sessionId={selectedSession._id}
            closeModal={() => dispatch(setCommentModalVisible(false))}
          />
        </ModalWindow>
      )}
    </div>
  );
};

export default HistoryOfSessions;

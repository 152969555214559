import ky from "ky";
import { logout } from "../utils/general";
import UsersApi from "./usersApi";

const customKy = ky.extend({
  retry: {
    limit: 1,
    statusCodes: [401],
  },
  hooks: {
    beforeRetry: [
      async ({ request }) => {
        const refreshToken = localStorage.getItem("refreshToken");
        const userId = localStorage.getItem("userId");

        if (!refreshToken) return await logout(userId!);

        try {
          const res = await UsersApi.refreshAccessToken({ refreshToken });
          if (res && res.token) {
            request.headers.set("Authorization", `Bearer ${res.token}`);
            localStorage.setItem("token", res.token);
          } else {
            await logout(userId!);
          }
        } catch (error) {
          console.error("Error refreshing access token:", error);
          await logout(userId!);
        }
      },
    ],
  },
});

export default customKy;

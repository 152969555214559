import strings from "../../localization";

export const getSessionStatus = (
  isPayedOut: boolean,
  isRefunded: boolean,
  isCanceled: boolean,
  isWaitingForRefund: boolean,
  s: {
    readonly [key: string]: string;
  }
) => {
  const sessionClassName = isPayedOut
    ? s.completedStatus
    : isRefunded || isCanceled || isWaitingForRefund
    ? s.missedStatus
    : s.scheduledStatus;
  const statusString = isWaitingForRefund
    ? strings.waitRefoundStatus
    : isCanceled
    ? strings.sessionStatusCanceled
    : isPayedOut
    ? strings.sessionStatusCompleted
    : isRefunded
    ? strings.sessionStatusMissed
    : strings.sessionStatusScheduled;
  return { sessionClassName, statusString };
};

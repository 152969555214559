import { useNavigate } from "react-router-dom";
import WelcomImg from "../../../assets/Auth/welcome.png";
import Logo from "../../../assets/logo.png";
import SiteLangsTabs from "../../../components/SiteLangsTabs/SiteLangsTabs";
import strings from "../../../localization";
import s from "./SelectRegMode.module.css";

const SelectRegMode = () => {
  const navigate = useNavigate();
  return (
    <div className={s.mainContainer}>
      <div className={s.headerBlock}>
        <SiteLangsTabs />
      </div>
      <div className={s.container}>
        <div className={s.welcomeBlock}>
          <img src={Logo} alt="" className={s.logo} />
          <h1 className={s.heading}>{strings.welcome}</h1>
          <p className={s.talziDescTitle}>{strings.talziDescription}</p>
        </div>
        <div className={s.roleContainer}>
          <div className={s.roleBlock}>
            <button
              className={s.roleButton}
              onClick={() => navigate("/login-partner")}
            >
              🌟 {strings.specModeBtn}
            </button>
            <p>{strings.specModeDescription}</p>
          </div>
          <div className={s.roleBlock}>
            <button className={s.roleButton} onClick={() => navigate("/login")}>
              🌱 {strings.clientModeBtn}
            </button>
            <p>{strings.clienModeDescription}</p>
          </div>
        </div>
      </div>
      <div className={s.footerBlock}></div>
      <img src={WelcomImg} alt="" className={s.welcomImg} />
    </div>
  );
};

export default SelectRegMode;

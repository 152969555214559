import { CircularProgress, ThemeProvider } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import TodosAPI from "../../../api/todoApi";
import { ReactComponent as ChevronIcon } from "../../../assets/HomePage/chevron-up.svg";
import { ReactComponent as PlusIcon } from "../../../assets/plus-icon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/VideoSession/close.svg";
import Input from "../../../components/Input/Input";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../contextProvider";
import useAutosizeTextArea from "../../../hooks/useAutosizeTextarea";
import strings from "../../../localization";
import { Todo, TodoList, TodoStatus } from "../../../types/TypeToDo";
import { formateDate } from "../../../utils/general";
import { getTranslation } from "../../../utils/getTranslation";
import { theme } from "../../../utils/theme";
import Notify from "../../../utils/toaster";
import CreateForList from "./CreateForList/CreateForList";
import TodosCardVideoChat from "./TodosCardVideoChat/TodosCardVideoChat";
import TodoTaskVideoChat from "./TodoTaskVideoChat/TodoTaskVideoChat";
import s from "./TodoVideoChat.module.css";

type TodoVideoChatProps = {
  setToolsState: Dispatch<
    SetStateAction<{
      chat: boolean;
      tasks: boolean;
      notations: boolean;
    }>
  >;
  userSpecialistIds: string[];
  clientsIds: string[];
  setTodosData: Dispatch<SetStateAction<TodoList[] | null>>;
  todosData: TodoList[] | null;
};

const TodoVideoChat = ({
  setToolsState,
  userSpecialistIds,
  clientsIds,
  todosData,
  setTodosData,
}: TodoVideoChatProps) => {
  const { userData } = useContext(ContextProvider);
  const token = localStorage.getItem("token");
  const [selectedTodoList, setSelectedTodoList] = useState<TodoList | null>(
    null
  );
  const newTodoListTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef<any>(null);
  const updateTodoData = 0;
  const [newTodoModal, setNewTodoModal] = useState(false);
  const [todoListTitle, setTodoListTitle] = useState("");
  const [newTaskModal, setNewTaskModal] = useState(false);
  const [newTaskValues, setNewTaskValues] = useState({
    title: "",
    description: "",
  });
  const [createForTodo, setCreateForTodo] = useState(userData!._id);

  useAutosizeTextArea(newTodoListTextAreaRef.current, todoListTitle);

  const selectTodoListHandler = (todoListData: TodoList) => {
    setSelectedTodoList(todoListData);
    /* setIsEdit(false); */
  };

  const createTodo = async () => {
    if (token && userData && userData._id && selectedTodoList?._id) {
      const payload = {
        newTodo: {
          createdBy: userData._id,
          for: selectedTodoList.for._id,
          status: TodoStatus.NEW,
          title: newTaskValues.title,
          description: newTaskValues.description,
          date: new Date(),
        },
        todoListId: selectedTodoList?._id,
      };

      const response = await TodosAPI.createTodo(token, payload);

      if (response.status && response.todo) {
        if (todosData) {
          const updateTaskData = todosData
            .map((item) => {
              if (selectedTodoList._id === item._id && response.todo) {
                return { ...item, todos: [...item.todos, response.todo] };
              }
              return null;
            })
            .filter((item) => item !== null);
          setSelectedTodoList(updateTaskData[0]);
          if (updateTaskData && updateTaskData[0] && todosData) {
            const updatedTodos = todosData.map((todo) => {
              if (todo._id === updateTaskData[0]?._id) {
                return updateTaskData[0];
              }
              return todo;
            });
            setTodosData(updatedTodos);
          }
        }
        setNewTaskValues({ title: "", description: "" });

        setNewTaskModal(false);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = () => {
    if (
      containerRef.current &&
      containerRef.current.scrollTop + containerRef.current.clientHeight >=
        containerRef.current.scrollHeight - 10 &&
      !loading
    ) {
      setLoading(true);
      setPage((prevPage) => prevPage + 1);
    }
  };

  const updateTodo = async (index: number) => {
    if (token && selectedTodoList) {
      const todoToUpdate = selectedTodoList.todos[index];
      const correspondingTodo = todosData?.find(
        (todo) => todo._id === selectedTodoList._id
      );

      if (!correspondingTodo) return;

      const correspondingTodoIndex = correspondingTodo.todos.findIndex(
        (todo) => todo._id === todoToUpdate._id
      );

      if (correspondingTodoIndex === -1) return;

      const response = await TodosAPI.updateTodo(
        token,
        todoToUpdate,
        selectedTodoList._id
      );

      if (response.status) {
        const updatedTodosData = [...todosData!];
        updatedTodosData[todosData!.indexOf(correspondingTodo)] = {
          ...correspondingTodo,
          todos: correspondingTodo.todos.map((todo) => {
            if (todo._id === response.todo!._id) {
              return response.todo!;
            }
            return todo;
          }),
        };
        setTodosData(updatedTodosData);
      }
      if (!response.status && response.message) {
        Notify(response.message);
      }
    }
  };

  useEffect(() => {
    if (!selectedTodoList || !todosData) return;
    const updatedTodos = todosData.map((todo) => {
      if (todo._id === selectedTodoList._id) {
        return selectedTodoList;
      }
      return todo;
    });
    setTodosData(updatedTodos);
  }, [selectedTodoList]);

  useEffect(() => {
    const fetchTodos = async () => {
      if (token && userData && userData._id) {
        const response = await TodosAPI.getUserTodos(
          token,
          userData._id,
          50,
          page,
          -1,
          "_id"
        );
        if (response.status && response.todoLists) {
          if (selectedTodoList) {
            const updateSelectedTodoList = response.todoLists
              .map((item) => {
                if (item._id === selectedTodoList._id) return item;
                return null;
              })
              .filter((list) => list !== null);

            setSelectedTodoList(updateSelectedTodoList[0]);
          }

          setTodosData(response.todoLists);
          setLoading(false);
        }
      }
    };
    const intervalId = setInterval(fetchTodos, 5000);
    return () => clearInterval(intervalId);
  }, [updateTodoData, page, selectedTodoList]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const changeNewTodoListTitle = (value: string) => {
    if (value.length > 100) return;
    setTodoListTitle(value);
  };
  const createTodoList = async () => {
    if (userData && userData._id && token) {
      const payload = {
        newTodoList: {
          createdBy: userData._id,
          for: createForTodo,
          title: todoListTitle,
          date: new Date(),
        },
      };

      const response = await TodosAPI.createTodoList(token, payload);
      if (response.status) {
        setTodosData((prevTodosData) => {
          if (prevTodosData) {
            const updatedTodosData = [
              response.todoList,
              ...prevTodosData.filter(Boolean),
            ].filter(Boolean);
            return updatedTodosData as TodoList[];
          }
          return [response.todoList] as TodoList[];
        });
        setTodoListTitle("");
        setNewTodoModal(false);
      }
    }
  };

  const onChangeNewTaskTitle = (value: string) => {
    setNewTaskValues((prev) => ({ ...prev, title: value }));
  };

  const handleTodoChange = (
    value: string,
    index: number,
    keyObj: keyof Todo
  ) => {
    setSelectedTodoList((prevTodoList) => {
      if (prevTodoList) {
        const updatedTodos = [...prevTodoList.todos];
        updatedTodos[index] = {
          ...updatedTodos[index],
          [keyObj]: value,
        };

        return {
          ...prevTodoList,
          todos: updatedTodos,
        };
      }
      return prevTodoList;
    });
  };

  return (
    <div className={s.container}>
      <div className={s.todoHeader}>
        <div className={s.headerActionBlock} style={{ gap: "5px" }}>
          {selectedTodoList && (
            <ChevronIcon
              className={s.chevronIcon}
              onClick={() => setSelectedTodoList(null)}
            />
          )}
          <h3 className={s.todoTitle}>{strings.toDoListHeadTitle}</h3>
        </div>
        <div className={s.headerActionBlock}>
          <span
            onClick={() => setNewTodoModal(true)}
            className={s.mobileNewTodoButton}
          >
            <PlusIcon className={s.plusIcon} />
          </span>
          <CloseIcon
            className={s.closeIcon}
            onClick={() =>
              setToolsState((prev) => ({
                chat: false,
                tasks: !prev.tasks,
                notations: false,
              }))
            }
          />
        </div>
      </div>

      {!todosData ? (
        <div className={s.todoBodyLoader}>
          <ThemeProvider theme={theme}>
            <CircularProgress size={100} color="primary" />
          </ThemeProvider>
        </div>
      ) : !todosData.length ? (
        <div className={s.todoBodyLoader}>{strings.noTodosFromSession}</div>
      ) : (
        <div className={s.todoBody}>
          <div
            className={
              selectedTodoList
                ? `${s.todoListBlock} ${s.mobileTodoListBlockHidden}`
                : `${s.todoListBlock} ${s.mobileTodoListBlockVisible}`
            }
          >
            <div
              role="button"
              className={s.createTaskButton}
              onClick={() => setNewTodoModal(true)}
            >
              <span>{strings.newTodoBtn}</span>
              <span>
                <PlusIcon className={s.plusIcon} />
              </span>
            </div>

            <div className={s.tasksList}>
              {todosData &&
                todosData.map((item, index) => {
                  const fullName =
                    getTranslation(
                      item.createdBy.name,
                      userData?.selectedLanguage
                    ) +
                    " " +
                    getTranslation(
                      item.createdBy.surname,
                      userData?.selectedLanguage
                    );
                  return (
                    <div
                      onClick={() => selectTodoListHandler(item)}
                      key={index}
                    >
                      <TodosCardVideoChat
                        key={index}
                        setSelectedTodoList={setSelectedTodoList}
                        date={formateDate(new Date(item.date ? item.date : ""))}
                        fullName={fullName}
                        title={item.title}
                        todoListId={item._id}
                        setTodoData={setTodosData}
                        forId={item.for._id}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          <div
            className={
              !selectedTodoList
                ? `${s.todoContentBlock} ${s.mobileTodoContentBlockHidden}`
                : `${s.todoContentBlock} ${s.mobileTodoContentBlockVisible}`
            }
          >
            {selectedTodoList && (
              <div className={s.headTaskBlock}>
                <div className={s.todoListInfoTitle}>
                  {selectedTodoList?.title}
                </div>
                <div className={s.newTaskBlock}>
                  <StandartButton
                    action={() => setNewTaskModal(true)}
                    buttonTitle={strings.newTaskBtn}
                    height="35px"
                    fontSize="14px"
                    width="fit-content"
                  />
                </div>
              </div>
            )}
            <div className={s.tasksList}>
              {selectedTodoList?.todos && selectedTodoList.todos.length ? (
                selectedTodoList?.todos.map((item, index) => (
                  <TodoTaskVideoChat
                    description={item.description}
                    title={item.title}
                    status={item.status.status}
                    key={index}
                    index={index}
                    onChangeTodo={handleTodoChange}
                    updateTodo={updateTodo}
                    taskId={item._id}
                    setSelectedTodoList={setSelectedTodoList}
                    selectedTodoList={selectedTodoList}
                  />
                ))
              ) : (
                <div className={s.noDataTitleBlock}>
                  <h2 className={s.noDataTitle}>{strings.noTaskData}</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <ModalWindow
        isOpen={newTaskModal}
        setIsOpen={setNewTaskModal}
        width="fit-content"
      >
        <div className={s.newTaskModalBlock}>
          <span className={s.newTaskTitle}>{strings.newTaskHeadTitle}</span>
          <div>
            <span>{strings.newTaskTitleLable}</span>
            <Input
              inputValue={newTaskValues.title}
              onChangeInput={(value: string) => onChangeNewTaskTitle(value)}
              required
              isVisible
            />
          </div>
          <div>
            <span>{strings.newTaskDescriptionLable}</span>
            <textarea
              name=""
              id=""
              cols={30}
              rows={10}
              value={newTaskValues.description}
              className={s.textAreaNewTask}
              onChange={(event) =>
                setNewTaskValues((prev) => ({
                  ...prev,
                  description: event.target.value,
                }))
              }
            ></textarea>
          </div>
          <div className={s.createTaskBlock}>
            <StandartButton
              action={createTodo}
              buttonTitle={strings.createTaskBtn}
              width="200px"
              fontSize="20px"
            />
          </div>
        </div>
      </ModalWindow>
      <ModalWindow
        isOpen={newTodoModal}
        setIsOpen={setNewTodoModal}
        width="fit-content"
      >
        <div className={s.newTodoModal}>
          <span className={s.newTodoListTitle}>
            {strings.titleNewTodoStatusTask}
          </span>
          <textarea
            ref={newTodoListTextAreaRef}
            rows={1}
            value={todoListTitle}
            className={s.textAreaNewTask}
            onChange={(event) => changeNewTodoListTitle(event.target.value)}
          />

          {userSpecialistIds.includes(userData!._id) && (
            <CreateForList
              clientsIds={clientsIds}
              selectedCreateFor={createForTodo}
              setSelectedCreateFor={setCreateForTodo}
            />
          )}

          <StandartButton
            action={createTodoList}
            buttonTitle={strings.createTaskBtn}
            width="200px"
            fontSize="20px"
            disabled={todoListTitle.replace(/\s+/g, "").length < 1}
            tooltipTitle={
              todoListTitle.replace(/\s+/g, "").length < 1
                ? strings.titleNewTodoStatusTask
                : ""
            }
          />
        </div>
      </ModalWindow>
    </div>
  );
};

export default TodoVideoChat;

import { enUS, ru, uk } from "date-fns/locale";
import { useContext, useState } from "react";
import { DateRangePicker } from "react-date-range";
import {
  defaultInputRanges,
  defaultStaticRanges,
} from "react-date-range/dist/defaultRanges";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import "./DateRangePicker.css";

interface DateRange {
  startDate: Date;
  endDate: Date;
  key: string;
}

interface DateRangePickerProps {
  onDateChange: (ranges: any) => void;
  initialRange?: DateRange;
}

const DateRabgePicker = ({
  onDateChange,
  initialRange,
}: DateRangePickerProps) => {
  const { userData } = useContext(ContextProvider);
  const [range, setRange] = useState<DateRange>({
    startDate: initialRange?.startDate || new Date(),
    endDate: initialRange?.endDate || new Date(),
    key: "selection",
  });
  const handleSelect = (ranges: { selection: DateRange }) => {
    setRange(ranges.selection);
    onDateChange(ranges.selection);
  };

  const dateLocalization = {
    uk: uk,
    ru: ru,
    en: enUS,
  };

  const staticRangesLabels = {
    Today: strings.today,
    Yesterday: strings.yesterday,
    "This Week": strings.thisWeek,
    "Last Week": strings.lastWeek,
    "This Month": strings.thisMonth,
    "Last Month": strings.lastMonth,
  };

  const inputRangesLabels = {
    "days up to today": strings.upToToday,
    "days starting today": strings.startingToday,
  };

  const lacleRanges = defaultStaticRanges.map((item: any) => ({
    ...item,
    //@ts-expect-error
    label: staticRangesLabels[item.label],
  }));
  const lacleInputRanges = defaultInputRanges.map((item: any) => ({
    ...item,
    //@ts-expect-error
    label: inputRangesLabels[item.label],
  }));

  return (
    <DateRangePicker
      onChange={(item: { selection: DateRange }) => {
        handleSelect(item);
      }}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      staticRanges={window.innerWidth > 768 ? lacleRanges : []}
      inputRanges={window.innerWidth > 768 ? lacleInputRanges : []}
      months={2}
      ranges={[range]}
      direction={window.innerWidth > 768 ? "horizontal" : "vertical"}
      preventSnapRefocus={true}
      calendarFocus="backwards"
      //@ts-expect-error
      locale={dateLocalization[userData!.selectedLanguage]}
    />
  );
};

export default DateRabgePicker;

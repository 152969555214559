import { network } from "../config";
import { Payment } from "../types/Balance";
import TypeDefaultResponse from "../types/TypeDefaultResponse";
import { SpecialistData } from "../types/TypeSpecialist";
import {
  Genders,
  GetUserAvatar,
  TypeConfirmRegister,
  TypeGeoInfo,
  TypeNewUser,
  TypeUpdatedUserInfo,
  TypeUserData,
} from "../types/TypeUsers";
import customKy from "./kyExtend";
const { users } = network;

const baseURL = process.env.REACT_APP_HTTP_CONNECTION_STRING + "users/";

const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

export default class UsersApi {
  static async register(
    registerData: TypeNewUser,
    selectedLanguage: string
  ): Promise<
    TypeDefaultResponse & {
      userData?: TypeUserData | null;
      skipTwilioConfirm?: boolean;
    }
  > {
    return await customKy
      .post(`${baseURL}${users.register}`, {
        json: { registerData, selectedLanguage },
        timeout: 300000,
      })
      .then((res) => res.json());
  }

  static async resendConfCode(payload: {
    userId: string;
  }): Promise<TypeDefaultResponse & { userData?: TypeUserData | null }> {
    return await customKy
      .post(`${baseURL}${users.resendConfCode}`, {
        json: payload,
        timeout: 30000,
      })
      .then((res) => res.json());
  }

  static async getUserById(
    token: string,
    id: string
  ): Promise<
    TypeDefaultResponse & { userData?: TypeUserData | null; token?: string }
  > {
    return await customKy
      .get(`${baseURL}${users.getUserById}/${id}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getUserSelectedFields(
    token: string,
    userId: string,
    selectUserFields: string,
    selectSpecFields: string
  ): Promise<TypeDefaultResponse & { userData?: Partial<TypeUserData> }> {
    return await customKy
      .get(
        `${baseURL}${users.getUserSelectedFields}/${userId}/${selectUserFields}/${selectSpecFields}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getUserProfileInfoById(
    token: string,
    id: string
  ): Promise<
    TypeDefaultResponse & {
      user?: (TypeUserData & { specialistId?: SpecialistData }) | null;
    }
  > {
    return await customKy
      .get(`${baseURL}${users.getUserProfileInfoById}/${id}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getUserPublicInfo(
    username: string,
    selectedLanguage: string,
    token?: string
  ): Promise<
    TypeDefaultResponse & {
      user?: (TypeUserData & { specialistIds?: SpecialistData[] }) | null;
    }
  > {
    return await customKy
      .get(
        `${baseURL}${users.getUserPublicInfo}/${username}/${selectedLanguage}`,
        {
          headers: token ? getAuthHeader(token) : undefined,
        }
      )
      .then((res) => res.json());
  }

  static async getUserPublicInfoById(
    userIdToGetInfo: string,
    selectedLanguage: string,
    token?: string
  ): Promise<
    TypeDefaultResponse & {
      user?: (TypeUserData & { specialistIds?: SpecialistData[] }) | null;
    }
  > {
    return await customKy
      .get(
        `${baseURL}${users.getUserPublicInfoById}/${userIdToGetInfo}/${selectedLanguage}`,
        {
          headers: token ? getAuthHeader(token) : undefined,
        }
      )
      .then((res) => res.json());
  }

  static async getUserByToken(token: string): Promise<TypeUserData | null> {
    return await customKy
      .get(`${baseURL}${users.getUserByToken}/${token}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async auth(
    phoneNumberOrEmail: string,
    password: string,
    selectedLanguage: string,
    rememberMe?: boolean
  ): Promise<
    TypeDefaultResponse & {
      userData?: TypeUserData | null;
      token?: string;
      refreshToken?: string;
    }
  > {
    return await customKy
      .post(`${baseURL}${users.auth}`, {
        json: {
          phoneNumberOrEmail: phoneNumberOrEmail,
          password: password,
          selectedLanguage: selectedLanguage,
          rememberMe: rememberMe,
        },
      })
      .then((res) => res.json());
  }

  static async confirmRegister(confirmedData: TypeConfirmRegister): Promise<
    TypeDefaultResponse & {
      userData?: TypeUserData | null;
      token?: string;
      refreshToken?: string;
    }
  > {
    return await customKy
      .post(`${baseURL}${users.confirmRegister}`, {
        json: confirmedData,
      })
      .then((res) => res.json());
  }

  static async confirmPassUpdate(
    email: string
  ): Promise<TypeDefaultResponse & { id: string }> {
    return await customKy
      .post(`${baseURL}${users.confirmPassUpdate}`, {
        json: { email },
      })
      .then((res) => res.json());
  }

  static async updatePassword(updateData: {
    password: string;
    id: string;
    confirmationCode: number;
  }): Promise<
    TypeDefaultResponse & {
      password: string;
      id: string;
      confirmationCode: number;
    }
  > {
    return await customKy
      .post(`${baseURL}${users.updatePassword}`, {
        json: updateData,
      })
      .then((res) => res.json());
  }

  static async updateUserData(
    token: string,
    updateData: TypeUpdatedUserInfo
  ): Promise<
    TypeDefaultResponse & {
      userData?: TypeUserData | null;
    }
  > {
    return await customKy
      .post(`${baseURL}${users.update}/${updateData._id}`, {
        headers: getAuthHeader(token),
        json: { updateData },
      })
      .then((res) => res.json());
  }

  static async updateAvatar(
    token: string,
    formData: FormData,
    userId: string
  ): Promise<
    TypeDefaultResponse & {
      userData?: TypeUserData | null;
    }
  > {
    return await customKy
      .post(`${baseURL}${users.updateAvatar}/${userId}`, {
        headers: getAuthHeader(token),
        body: formData,
        timeout: 60000,
      })
      .then((res) => res.json());
  }

  static async getUserAvatarAndName(
    token: string,
    id: string
  ): Promise<GetUserAvatar> {
    return await customKy
      .get(`${baseURL}${users.getUserAvatarAndName}/${id}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getUserPublicAvatarAndName(id: string): Promise<GetUserAvatar> {
    return await customKy
      .get(`${baseURL}${users.getUserPublicAvatarAndName}/${id}`, {})
      .then((res) => res.json());
  }

  static async checkIfUserJoinedAnyCompany(
    token: string,
    userId: string
  ): Promise<GetUserAvatar> {
    return await customKy
      .get(`${baseURL}${users.checkIfUserJoinedAnyCompany}/${userId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getAllGenders(token: string): Promise<
    TypeDefaultResponse & {
      genders?: Genders[];
    }
  > {
    return await customKy
      .get(`${baseURL}${users.getAllGenders}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async getUserPayments(
    token: string,
    userId: string,
    page: number,
    limit: number
  ): Promise<
    TypeDefaultResponse & {
      payments: Payment[];
    }
  > {
    return await customKy
      .get(`${baseURL}${users.getUserPayments}/${userId}/${page}/${limit}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async fixScheduleType(token: string): Promise<
    TypeDefaultResponse & {
      payments: Payment[];
    }
  > {
    return await customKy
      .get(`${baseURL}${users.fixScheduleType}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async addFavouriteUser(
    token: string,
    userId: string,
    favouriteUserId: string
  ): Promise<TypeDefaultResponse> {
    return await customKy
      .post(`${baseURL}${users.addFavouriteUser}`, {
        headers: getAuthHeader(token),
        json: { userId, favouriteUserId },
        timeout: 60000,
      })
      .then((res) => res.json());
  }

  static async removeFavouriteUser(
    token: string,
    userId: string,
    favouriteUserId: string
  ): Promise<TypeDefaultResponse> {
    return await customKy
      .post(`${baseURL}${users.removeFavouriteUser}`, {
        headers: getAuthHeader(token),
        json: { userId, favouriteUserId },
        timeout: 60000,
      })
      .then((res) => res.json());
  }

  static async getUsersFavouritesList(
    token: string,
    userId: string,
    page: number,
    limit: number
  ): Promise<
    TypeDefaultResponse & {
      payments: Payment[];
    }
  > {
    return await customKy
      .get(
        `${baseURL}${users.getUsersFavouritesList}/${userId}/${page}/${limit}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async checkIfUserIsFavourite(
    token: string,
    specialistUserId: string
  ): Promise<
    TypeDefaultResponse & {
      isFavourite?: boolean;
    }
  > {
    return await customKy
      .get(`${baseURL}${users.checkIfUserIsFavourite}/${specialistUserId}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async checkIfUserWorkedWithSpecialist(
    token: string,
    specialistUserId: string
  ): Promise<
    TypeDefaultResponse & {
      worked: boolean;
      commonSessionsAmount?: number;
    }
  > {
    return await customKy
      .get(
        `${baseURL}${users.checkIfUserWorkedWithSpecialist}/${specialistUserId}`,
        {
          headers: getAuthHeader(token),
        }
      )
      .then((res) => res.json());
  }

  static async getUserPublicInfoLatinName(
    partialUserIdToGetInfo: string,
    name: string,
    surname: string,
    selectedLanguage: string,
    token?: string
  ): Promise<
    TypeDefaultResponse & {
      user: TypeUserData;
    }
  > {
    return await customKy
      .get(
        `${baseURL}${users.getUserPublicInfoLatinName}/${partialUserIdToGetInfo}/${name}/${surname}/${selectedLanguage}`,
        {
          headers: token ? getAuthHeader(token) : undefined,
        }
      )
      .then((res) => res.json());
  }
  static async connectPhoneNumber(
    token: string,
    phoneNumber: string,
    selectedLanguage: string
  ): Promise<TypeDefaultResponse & { userData: TypeUserData }> {
    return await customKy
      .post(`${baseURL}${users.connectPhoneNumber}`, {
        headers: getAuthHeader(token),
        json: { phoneNumber, selectedLanguage },
        timeout: 60000,
      })
      .then((res) => res.json());
  }

  static async getUserGeoInfo(): Promise<
    TypeDefaultResponse & {
      geo?: TypeGeoInfo;
    }
  > {
    return await customKy
      .get(`${baseURL}${users.getUserGeoInfo}`)
      .then((res) => res.json());
  }

  static async refreshAccessToken(payload: { refreshToken: string }): Promise<
    TypeDefaultResponse & {
      token?: string;
    }
  > {
    return await customKy
      .post(`${baseURL}${users.refreshAccessToken}`, {
        json: payload,
      })
      .then((res) => res.json());
  }

  static async logout(payload: {
    userId: string;
  }): Promise<TypeDefaultResponse> {
    return await customKy
      .post(`${baseURL}${users.logout}`, {
        json: payload,
      })
      .then((res) => res.json());
  }

  static async sendEmailConfirmation(
    token: string,
    payload: {
      userId: string;
    }
  ): Promise<TypeDefaultResponse & { resendEmailConfCodeDate?: string }> {
    return await customKy
      .post(`${baseURL}${users.sendEmailConfirmation}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async confirmEmailWithCode(
    token: string,
    payload: {
      userId: string;
      code: number;
    }
  ): Promise<TypeDefaultResponse> {
    return await customKy
      .post(`${baseURL}${users.confirmEmailWithCode}`, {
        headers: getAuthHeader(token),
        json: payload,
      })
      .then((res) => res.json());
  }

  static async sendPhoneConfirmation(
    token: string
  ): Promise<TypeDefaultResponse & { userData: TypeUserData }> {
    return await customKy
      .post(`${baseURL}${users.sendPhoneConfirmation}`, {
        headers: getAuthHeader(token),
      })
      .then((res) => res.json());
  }

  static async confirmPhoneNumberWithCode(
    token: string,
    code: number
  ): Promise<TypeDefaultResponse> {
    return await customKy
      .post(`${baseURL}${users.confirmPhoneNumberWithCode}`, {
        headers: getAuthHeader(token),
        json: { code },
      })
      .then((res) => res.json());
  }

  static async isTwilioAvailable(): Promise<
    TypeDefaultResponse & { isAvailable: boolean }
  > {
    return await customKy
      .get(`${baseURL}${users.isTwilioAvailable}`)
      .then((res) => res.json());
  }
}

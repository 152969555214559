import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useContext, useEffect, useState } from "react";
import Stripe from "stripe";
import StripeApi from "../../api/stripeApi";
import DollarIcon from "../../assets/Balance/dollar.svg";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import { PaymentFor } from "../../types/Balance";
import Notify from "../../utils/toaster";
import Input from "../Input/Input";
import Loader from "../Loader/Loader";
import StandartButton from "../StandartButton/StandartButton";
import s from "./Replenish.module.css";

type ReplenishDataType = {
  amount: number;
  promocode?: string;
};
type ReplenishProps = {
  hidePromocode?: boolean;
  isPaymentSession?: boolean;
  paymentFor?: PaymentFor;
  subscriptionPayload?: {
    subscriptionId: string;
    daysAmount: number;
  };
};
const Replenish = ({
  isPaymentSession,
  paymentFor,
  hidePromocode,
  subscriptionPayload,
}: ReplenishProps) => {
  const token = localStorage.getItem("token");
  const { userData } = useContext(ContextProvider);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [replenishData, setReplenishData] = useState<ReplenishDataType>({
    amount: 0,
  });
  const [checkout, setCheckout] = useState<
    Stripe.Response<Stripe.Checkout.Session> | undefined
  >();

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!
  );

  const paymentSubscriptionHandler = async () => {
    if (!token || !subscriptionPayload) return { status: false };

    const response = await StripeApi.createPaymentForSubscription(
      token,
      subscriptionPayload
    );

    return response;
  };

  const paymentSessionHandler = async () => {
    if (!token || !paymentFor) return { status: false };

    const payload = {
      paymentFor: { ...paymentFor, packagePromocode: replenishData.promocode },
    };

    const response = await StripeApi.createPaymentForSession(token, payload);

    return response;
  };
  useEffect(() => {
    if ((isPaymentSession && hidePromocode) || subscriptionPayload) {
      createPaymentCheckout();
    }
  }, [isPaymentSession, hidePromocode]);

  const createPaymentCheckout = async () => {
    if (!token) return;
    let paymentResponse;
    setIsLoading(true);
    if (isPaymentSession && paymentFor) {
      paymentResponse = await paymentSessionHandler();
    } else if (isPaymentSession && subscriptionPayload) {
      paymentResponse = await paymentSubscriptionHandler();
    } else {
      const payload = {
        order: {
          amount: replenishData.amount * 100,
          promocode:
            replenishData.promocode && replenishData.promocode.length
              ? replenishData.promocode
              : undefined,
        },
        userId: userData!._id,
      };
      paymentResponse = await StripeApi.createPayment(token, payload);
    }

    setIsLoading(false);
    if (!paymentResponse.status && paymentResponse.message) {
      Notify(paymentResponse.message);
    }

    if (paymentResponse.status) setCheckout(paymentResponse.checkout);
  };

  return (
    <div className={s.container}>
      <div>
        {!isPaymentSession && !checkout && !isLoading && (
          <div style={{ position: "relative", marginBottom: "20px" }}>
            <Input
              inputValue={
                replenishData.amount === 0
                  ? ""
                  : replenishData.amount.toString()
              }
              isVisible
              onChangeInput={(value) =>
                setReplenishData((prev) => ({
                  ...prev,
                  amount: Number(value),
                }))
              }
              required
              lable={strings.amount}
              placeholder="100"
              type="number"
              enableValidateNumber={false}
            />
            <img src={DollarIcon} alt="" className={s.dollarIcon} />
          </div>
        )}
      </div>
      {checkout && (
        <div className={s.cardInfoBlock}>
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret: checkout.client_secret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </div>
      )}

      {!hidePromocode && !checkout && !isLoading && (
        <div className={s.promocodeBlock}>
          <div className={s.promocodeInput}>
            <Input
              inputValue={
                !replenishData.promocode ? "" : replenishData.promocode
              }
              isVisible
              onChangeInput={(value) =>
                setReplenishData((prev) => ({ ...prev, promocode: value }))
              }
              required={false}
              placeholder={"HsX137"}
              type="text"
              lable={strings.promoCode}
            />
          </div>
        </div>
      )}
      {isLoading ? (
        <div className={s.loaderBlock}>
          <Loader size={50} />
        </div>
      ) : (
        !checkout && (
          <StandartButton
            action={createPaymentCheckout}
            buttonTitle={
              isPaymentSession
                ? strings.pay +
                  " " +
                  (!hidePromocode && !replenishData.promocode
                    ? strings.withoutPromocode
                    : "")
                : strings.replenish
            }
            width="100%"
            height="46px"
            disabled={!isPaymentSession && replenishData.amount === 0}
          />
        )
      )}
    </div>
  );
};

export default Replenish;

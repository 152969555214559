import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { TableFooter, TablePagination } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import { styled, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { Dispatch, SetStateAction, useContext } from "react";
import CircleAvatar from "../../../components/CircleAvatar/CircleAvatar";
import Loader from "../../../components/Loader/Loader";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import NavigateToProfile from "../../../components/NavigateToProfile/NavigateToProfile";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import { Sessions } from "../../../types/TypeSession";
import { TypeUserData } from "../../../types/TypeUsers";
import { formatDateAndTime } from "../../../utils/dateActions";
import { getTranslation } from "../../../utils/getTranslation";
import { getSessionStatus } from "../getSessionStatus";
import { HistorySession } from "../HistoryOfSessions";
import SessionInfo from "../SessionInfo/SessionInfo";
import s from "./SessionsTable.module.css";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        className={s.mobileHidden}
      >
        {theme.direction === "rtl" ? (
          <LastPageIcon className={s.paginationIcon} />
        ) : (
          <FirstPageIcon className={s.paginationIcon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight color="success" className={s.paginationIcon} />
        ) : (
          <KeyboardArrowLeft color="success" className={s.paginationIcon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft color="success" className={s.paginationIcon} />
        ) : (
          <KeyboardArrowRight color="success" className={s.paginationIcon} />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        className={s.mobileHidden}
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon color="success" className={s.paginationIcon} />
        ) : (
          <LastPageIcon color="success" className={s.paginationIcon} />
        )}
      </IconButton>
    </Box>
  );
}

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "10px 20px",
  },
}));

const StyledTableRow = styled(TableRow)(
  ({
    rowId,
    selectedTodoListId,
  }: {
    rowId: string;
    selectedTodoListId: string | null;
  }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor:
        selectedTodoListId === rowId ? "var(--secondary-color)" : "#F0F3F2",
    },
    "&:nth-of-type(even)": {
      backgroundColor:
        selectedTodoListId === rowId ? "var(--secondary-color)" : "#FFFFFF",
      border: 0,
    },
    // hide last border
    "td, th": {
      border: 0,
    },
  })
);

type RowsType = Pick<
  Sessions,
  | "_id"
  | "title"
  | "description"
  | "sessionCost"
  | "project"
  | "isPayedOut"
  | "packageRef"
  | "isRefunded"
  | "isWaitingForRefund"
  | "tags"
  | "subject"
  | "isCanceled"
  | "recordForSpecAllowed"
  | "recordingNow"
  | "recordingAvailableAt"
> & {
  specialists: TypeUserData[];
  clients: TypeUserData[];
  dateAndTime: {
    date: Date;
    duration: number;
  };
  visitDurations:
    | {
        _id: string;
        totalSessionTime: number;
      }[]
    | undefined;
};

type SpecialistTableProps = {
  rows: RowsType[];
  page: number;
  rowsPerPage: number;
  setPagination: React.Dispatch<
    React.SetStateAction<{ page: number; rowPerPage: number; total: number }>
  >;
  count: number;
  loader: boolean;
  setSelectedSession: Dispatch<SetStateAction<RowsType | undefined>>;
  setSessionsData: Dispatch<SetStateAction<HistorySession[] | undefined>>;
  selectedSession: HistorySession | undefined;
};
export default function SessionsTable({
  rows,
  page,
  rowsPerPage,
  setPagination,
  count,
  loader,
  setSelectedSession,
  selectedSession,
  setSessionsData,
}: SpecialistTableProps) {
  const { userData } = useContext(ContextProvider);
  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPagination((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPagination((prev) => ({
      ...prev,
      rowPerPage: parseInt(event.target.value, 10),
    }));
    setPagination((prev) => ({ ...prev, page: 0 }));
  };

  return (
    <div>
      <TableContainer
        component={Paper}
        className={s.tableContainer}
        sx={{
          minWidth: window.innerWidth > 768 ? 0 : "90svw",
          width: "100%",
          borderRadius: "20px",
          backgroundColor: "transparent",
          maxHeight:
            window.innerHeight > 500 ? "calc(100vh - 250px)" : "calc(200vh)",
        }}
      >
        <Table
          sx={{
            minWidth: window.innerWidth > 768 ? 0 : "90svw",
            width: "100%",
          }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead className={s.tableHead}>
            <TableRow>
              <StyledTableCell align="left">
                <span>{strings.sessionsTableClient}</span>
              </StyledTableCell>
              <StyledTableCell align="left">
                <span>{strings.sessionsTableSpecialist}</span>
              </StyledTableCell>
              <StyledTableCell align="left">
                <span>{strings.sessionsTableDate}</span>
              </StyledTableCell>
              <StyledTableCell align="left">
                <span>{strings.sessionsTableCost}</span>
              </StyledTableCell>
              <StyledTableCell align="left">
                <span>{strings.sessionsTableStatus}</span>
              </StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loader ? (
              <TableRow>
                <StyledTableCell colSpan={5} align="center">
                  <div className={s.loaderContainer}>
                    <Loader size={100} />
                  </div>
                </StyledTableCell>
              </TableRow>
            ) : !rows.length ? (
              <TableRow>
                <StyledTableCell colSpan={5} align="center">
                  <h2 className={s.noSessions}>{strings.noSessions}</h2>
                </StyledTableCell>
              </TableRow>
            ) : (
              rows.map((row) => (
                <StyledTableRow
                  key={row._id}
                  onClick={() => setSelectedSession(row)}
                  rowId={row._id}
                  selectedTodoListId={selectedSession?._id ?? ""}
                >
                  <StyledTableCell align="left">
                    <NavigateToProfile userId={row.clients[0]._id}>
                      <div className={s.avatarBlock}>
                        <CircleAvatar userId={row.clients[0]._id} />
                        <span>
                          {getTranslation(
                            row.clients[0].name,
                            userData?.selectedLanguage
                          ) +
                            " " +
                            getTranslation(
                              row.clients[0].surname,
                              userData?.selectedLanguage
                            )}
                        </span>
                      </div>
                    </NavigateToProfile>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <NavigateToProfile userId={row.specialists[0]._id}>
                      <div className={s.avatarBlock}>
                        <CircleAvatar userId={row.specialists[0]._id} />
                        <span>
                          {getTranslation(
                            row.specialists[0].name,
                            userData?.selectedLanguage
                          ) +
                            " " +
                            getTranslation(
                              row.specialists[0].surname,
                              userData?.selectedLanguage
                            )}
                        </span>
                      </div>
                    </NavigateToProfile>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={s.dateBlock}>
                      {formatDateAndTime(
                        new Date(row.dateAndTime.date),
                        userData?.selectedLanguage!
                      ).formattedDate +
                        " " +
                        formatDateAndTime(
                          new Date(row.dateAndTime.date),
                          userData?.selectedLanguage!
                        ).formattedTime}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div className={s.costBlock}>
                      <span>{row.sessionCost / 100} $</span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <div
                      className={
                        getSessionStatus(
                          row.isPayedOut,
                          row.isRefunded,
                          row.isCanceled,
                          row.isWaitingForRefund,
                          s
                        ).sessionClassName
                      }
                    >
                      {
                        getSessionStatus(
                          row.isPayedOut,
                          row.isRefunded,
                          row.isCanceled,
                          row.isWaitingForRefund,
                          s
                        ).statusString
                      }
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>

          <TableFooter className={s.tableFooter}>
            <TableRow
              sx={{
                minWidth: 700,
                width: "100%",
                border: "none",
              }}
            >
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                colSpan={5}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={strings.rowPerPage}
                slotProps={{
                  select: {
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                sx={{ border: "none", color: "black" }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      {selectedSession && window.innerWidth > 768 && (
        <ModalWindow
          isOpen={selectedSession ? true : false}
          alternativeCloseFunction={() => {
            setSelectedSession(undefined);
          }}
          width={"fit-content"}
          position="bottom"
          showCloseBtn={false}
          borderRadius="20px 20px 0 0"
        >
          <SessionInfo
            selectedSession={selectedSession}
            closeInfoFunction={() => setSelectedSession(undefined)}
            setSelectedSession={setSelectedSession}
            setSessionsData={setSessionsData}
          />
        </ModalWindow>
      )}
    </div>
  );
}

import dayjs, { Dayjs } from "dayjs";
import moment from "moment-timezone";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import UsersApi from "../../api/usersApi";
import { ReactComponent as ChevronIcon } from "../../assets/HomePage/chevron-up.svg";
import { ReactComponent as CloseIcon } from "../../assets/HomePage/close.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Notes/delete.svg";
import { ReactComponent as CheckIcon } from "../../assets/SpecialistSetupInfo/checked.svg";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import { PaymentFor, PaymentForType } from "../../types/Balance";
import { SpecialistTag } from "../../types/TypeSpecialist";
import { drawDatesInWeek } from "../../utils/sessionBooking";
import { formatDate } from "../../utils/setupProfileInfo";
import CircleAvatar from "../CircleAvatar/CircleAvatar";
import ModalWindow from "../ModalWindow/ModalWindow";
import MuiCalendar from "../MuiCalendar/MuiCalendar";
import MultipleSelect from "../MultipleSelect/MultipleSelect";
import PaymentSession from "../PaymentSession/PaymentSession";
import StandartButton from "../StandartButton/StandartButton";
import s from "./SelectSessionDate.module.css";

type DropDownMenuData = { lable: string; value: string };

type SelectSessionDateProps = {
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  fullname?: string;
  allSpecializations: {
    specialistId: string;
    lable: string;
    bookingTimeStep: number;
    specialization: string;
    tags: SpecialistTag[];
    introductionSession: {
      cost: number;
      duration: number;
      switch: boolean;
    };
  }[];
  allSessionLangs: { lable: string; value: string }[];
  priceData: {
    [id: string]: { price: number; discountPrice: number; language: string }[];
  };
  bookingHandler: (
    sessionBookingData: { date: Date | string; duration: number }[],
    specialistId: string,
    sessionLanguage: string,
    tags?: string[],
    subject?: string
  ) => Promise<{ status: boolean; notEnoughBalance: boolean }>;
  overlapTime: {
    [id: string]: { start: Date; end: Date }[];
  };

  userId: string;
  projectId?: string;
  isShowingSpecializationsTab?: boolean;
};

const SelectSessionDate = ({
  setModalOpen,
  fullname,
  allSpecializations,
  bookingHandler,
  overlapTime,
  userId,
  projectId,
  allSessionLangs,
  priceData,
  isShowingSpecializationsTab = true,
}: SelectSessionDateProps) => {
  const token = localStorage.getItem("token");
  const weekDays = [
    strings.sun,
    strings.mon,
    strings.tue,
    strings.wed,
    strings.thu,
    strings.fri,
    strings.sat,
  ];
  const { userData, setUserData } = useContext(ContextProvider);
  const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(null);
  const [weekDates, setWeekDates] = useState<{ date: Date; day: string }[]>([]);
  const [selectedTime, setSelectedTime] = useState<
    { date: Date; duration: number }[]
  >([]);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [startX, setStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [timeSlots, setTimeSlots] = useState<{ date: string; time: string }[]>(
    []
  );
  const [noBalanceModal, setNoBalanceModal] = useState(false);
  const [daySlots, setDaySlots] = useState<string[]>([]);
  const [paymentFor, setPaymentFor] = useState<PaymentFor | null>(null);
  const [selectSpecialistId, setSelectSpecialistId] = useState<
    string | undefined
  >();
  const [sessionTags, setSessionTags] = useState<
    { _id: string; lable: string }[]
  >([]);
  const [tagsData, setTagsData] = useState<DropDownMenuData[]>([]);
  const [sessionDescription, setSessionDescription] = useState("");
  const [showTagsBlock, setShowTagsBlock] = useState(false);
  const [isSelectSpecStep, setIsSelectSpecStep] = useState<boolean>(
    allSpecializations.length > 1 || allSessionLangs.length > 1
  );
  const [selectedSessionLang, setSelectedSessionLang] = useState<
    string | undefined
  >(allSessionLangs.length === 1 ? allSessionLangs[0].value : undefined);
  const [stepInfo, setStepInfo] = useState({
    stepNumber: 1,
    stepTitle: strings.bookingStep1,
  });
  const [alreadyWork, setAlreadyWork] = useState(false);
  /*   const tagsSteps = [strings.direction, strings.topic, strings.result]; */

  useEffect(() => {
    const makeAsync = async () => {
      if (!token || !userId) return;
      const checkWorkResponse = await UsersApi.checkIfUserWorkedWithSpecialist(
        token,
        userId
      );

      if (checkWorkResponse.status) {
        setAlreadyWork(checkWorkResponse.worked);
      }
    };
    makeAsync();
  }, [userId]);

  useEffect(() => {
    if (allSpecializations.length > 1) return;
    setSelectSpecialistId(allSpecializations[0].specialistId);
  }, [allSpecializations]);

  /*   const gatTags = async (level: number, parentTagsId?: string) => {
    if (!token || !selectSpecialistId || !userData) return;
    const direction =
      directions[
        allSpecializations.find((el) => el.specialistId === selectSpecialistId)
          ?.specialization!
      ];
    const payload = {
      level: level,
      direction: direction,
      parentTagsIds: parentTagsId ? [parentTagsId] : undefined,
    };

    const response = await SpecialistApi.getSpecialistTags(token, payload);

    if (response.status && response.tags) {
      const tagsData = response.tags.map((item) => ({
        lable: item.labels.find(
          (el) => el.language === userData.selectedLanguage
        )!.text,
        value: item._id,
      }));
      setTagsData(tagsData);
    }
  }; */
  useEffect(() => {
    if (!selectSpecialistId) return setSessionTags([]);
    /*     const parentTagsId =
      sessionTags.length > 0 &&
      sessionTags.length < 3 &&
      sessionTags[sessionTags.length - 1]._id !== "other"
        ? sessionTags[sessionTags.length - 1]._id
        : undefined;
    gatTags(sessionTags.length + 1, parentTagsId); */
    const currentSpecialistTags = allSpecializations.find(
      (el) => el.specialistId === selectSpecialistId
    )?.tags!;
    const correctLevelTags = currentSpecialistTags.filter(
      (el) => el.level === sessionTags.length + 1
    );
    const parentTagId = sessionTags.length
      ? sessionTags[sessionTags.length - 1]._id
      : undefined;
    const tagsToSetData = parentTagId
      ? correctLevelTags
          .filter((el) => el.parentTag === parentTagId)
          .map((item) => ({
            value: item._id,
            lable: item.labels.find(
              (el) => el.language === userData?.selectedLanguage
            )?.text!,
          }))
      : correctLevelTags.map((item) => ({
          value: item._id,
          lable: item.labels.find(
            (el) => el.language === userData?.selectedLanguage
          )?.text!,
        }));

    setTagsData(tagsToSetData);
  }, [selectSpecialistId, sessionTags]);

  useEffect(() => {
    if (
      !selectSpecialistId ||
      sessionTags.map((item) => item._id).includes("other") ||
      sessionTags.length === 3
    )
      return;
    if (!tagsData || !tagsData.length) {
      setSessionTags((prev) => [
        ...prev,
        { _id: "other", lable: strings.otherCertificate },
      ]);
    }
  }, [tagsData]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsDragging(true);
    setStartX(e.clientX - e.currentTarget.offsetLeft);
    setScrollLeft(e.currentTarget.scrollLeft);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!isDragging) return;
    const x = e.clientX - e.currentTarget.offsetLeft;
    const walk = (x - startX) * 2;
    e.currentTarget.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  useEffect(() => {
    if (!selectedDate) return;
    const dates = drawDatesInWeek(selectedDate.toDate());
    const datesAndDays = dates.map((item, index) => ({
      date: item,
      day: weekDays[index],
    }));

    setWeekDates(datesAndDays);
  }, [selectedDate]);

  const sendRequestHandler = async () => {
    if (
      selectedDate &&
      selectedTime &&
      selectedTime.length &&
      selectSpecialistId &&
      selectedSessionLang
    ) {
      const bookingsData = selectedTime.map((item) => {
        const userMoment = moment(item.date.toISOString())
          .tz(userData?.timezone!, true)
          .utc();

        return {
          date: userMoment.format(),
          duration: item.duration,
        };
      });

      const response = await bookingHandler(
        bookingsData,
        selectSpecialistId,
        selectedSessionLang,
        sessionTags.filter((el) => el._id !== "other").map((item) => item._id),
        sessionDescription
      );

      if (!response.status && response.notEnoughBalance) {
        setPaymentForData();
        setNoBalanceModal(true);
      }
      if (response.status) {
        setUserData((prev) => ({
          ...prev!,
          balance:
            prev!.balance -
            priceData[selectSpecialistId].find(
              (el) => el.language === selectedSessionLang
            )?.discountPrice!,
        }));
      }
    }
  };
  const getTimeAndDateSlots = (
    sessions: { start: Date; end: Date }[],
    interval: number // Интервал в минутах (15, 30, 45 или 60)
  ): { timeSlots: { date: string; time: string }[]; dateSlots: string[] } => {
    const timeSlots: { date: string; time: string }[] = [];
    const dateSlotsSet: Set<string> = new Set();

    for (const session of sessions) {
      const startDate = new Date(session.start);
      const endDate = new Date(session.end);

      const startTime =
        startDate.getUTCHours() * 60 + startDate.getUTCMinutes(); // Время начала в минутах
      const endTime = endDate.getUTCHours() * 60 + endDate.getUTCMinutes(); // Время конца в минутах
      const startDateISO = startDate.toISOString().slice(0, 10);
      const endDateISO = endDate.toISOString().slice(0, 10);

      dateSlotsSet.add(startDateISO);
      if (startDateISO !== endDateISO && endTime !== 0) {
        dateSlotsSet.add(endDateISO);
      }

      if (startDateISO === endDateISO || startTime < endTime) {
        for (let i = startTime; i < endTime; i += interval) {
          const hours = Math.floor(i / 60);
          const minutes = i % 60;
          const timeSlot = `${hours < 10 ? "0" + hours : hours}:${
            minutes < 10 ? "0" + minutes : minutes
          }`;
          timeSlots.push({ date: startDateISO, time: timeSlot });
        }
      } else {
        for (let i = startTime; i < 24 * 60; i += interval) {
          const hours = Math.floor(i / 60);
          const minutes = i % 60;
          const timeSlot = `${hours < 10 ? "0" + hours : hours}:${
            minutes < 10 ? "0" + minutes : minutes
          }`;
          timeSlots.push({ date: startDateISO, time: timeSlot });
        }
        for (let i = 0; i < endTime; i += interval) {
          const hours = Math.floor(i / 60);
          const minutes = i % 60;
          const timeSlot = `${hours < 10 ? "0" + hours : hours}:${
            minutes < 10 ? "0" + minutes : minutes
          }`;
          timeSlots.push({ date: endDateISO, time: timeSlot });
        }
      }
    }

    const dateSlots = Array.from(dateSlotsSet).sort();

    return { timeSlots, dateSlots };
  };

  /*  const getTimeSlotsForDate = (date: Date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    const slotGap = allSpecializations.find(
      (el) => el.specialistId === selectSpecialistId
    )!.bookingTimeStep;

    const freeTimeSlots = getTimeAndDateSlots(
      overlapTime[selectSpecialistId],
      slotGap
    );
    const slots = freeTimeSlots.timeSlots.filter(
      (slot) => slot.date === formattedDate
    );

    return slots;
  }; */

  useEffect(() => {
    if (!selectSpecialistId) return;
    const date = selectedDate?.toDate();
    const slotGap = allSpecializations.find(
      (el) => el.specialistId === selectSpecialistId
    )!.bookingTimeStep;

    const freeTimeSlots = getTimeAndDateSlots(
      overlapTime[selectSpecialistId],
      slotGap
    );
    freeTimeSlots.timeSlots.sort((a, b) => {
      if (a.date === b.date) {
        return a.time.localeCompare(b.time);
      }
      return a.date.localeCompare(b.date);
    });

    setDaySlots(freeTimeSlots.dateSlots);
    if (date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;

      const filterTimeSlots = () => {
        const slots = freeTimeSlots.timeSlots.filter(
          (slot) => slot.date === formattedDate
        );

        if (!selectedTime.length) return slots;

        const filteredSlots = slots.filter((slot) => {
          const slotDateTime = combineDateTime(slot.date, slot.time);

          return !selectedTime.some((session) => {
            const sessionEnd = new Date(
              session.date.getTime() + session.duration * 60000
            );
            return (
              (slotDateTime > session.date && slotDateTime < sessionEnd) ||
              (slotDateTime < session.date &&
                session.date < new Date(slotDateTime.getTime() + 60 * 60000))
            );
          });
        });

        return filteredSlots;
      };

      const freeSlots = filterTimeSlots();

      if (!freeSlots) return;
      setTimeSlots(freeSlots);
    }
  }, [selectedDate, /* selectedTime, */ selectSpecialistId]);

  useEffect(() => {
    const scrollContainer = document.querySelector(".selectTimeBlock");
    if (scrollContainer) {
      scrollContainer.scrollLeft = 0; // установите начальное положение скролла
    }
  }, []);

  const combineDateTime = (dateStr: string, timeStr: string): Date => {
    // Парсим дату и время
    const dateParts = dateStr.split("-").map((part) => parseInt(part, 10));
    const timeParts = timeStr.split(":").map((part) => parseInt(part, 10));

    // Создаем новый объект Date
    const year = dateParts[0];
    const month = dateParts[1] - 1; // Месяцы в JavaScript начинаются с 0
    const day = dateParts[2];
    const hour = timeParts[0];
    const minute = timeParts[1];

    return new Date(year, month, day, hour, minute);
  };

  const areDatesEqual = (date1: Date, date2: Date) => {
    return date1.getTime() === date2.getTime();
  };

  /*   const incrementDuration = (index: number) => {
    setSelectedTime((prev) => {
      // Создаем копию текущего состояния и сортируем ее по времени и дате
      const sortedTimes = [...prev].sort(
        (a, b) => a.date.getTime() - b.date.getTime()
      );

      // Находим индекс текущего элемента в отсортированном массиве
      const sortedIndex = sortedTimes.findIndex(
        (item) => item.date.getTime() === prev[index].date.getTime()
      );

      // Проверяем, есть ли следующий элемент
      const currentItem = sortedTimes[sortedIndex];
      const nextItem = sortedTimes[sortedIndex + 1];

      if (nextItem) {
        const currentItemEndTime = new Date(
          currentItem.date.getTime() + currentItem.duration * 60000
        );
        const nextItemStartTime = new Date(nextItem.date);

        // Проверяем, перекрывает ли увеличение следующий слот, учитывая день
        if (
          currentItemEndTime.getHours() >= nextItemStartTime.getHours() &&
          currentItemEndTime.toDateString() === nextItemStartTime.toDateString()
        ) {
          return prev; // Не изменяем длительность, если перекрывает следующий слот
        }
      }

      const currentTime = currentItem.date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      const currentSlots = getTimeSlotsForDate(currentItem.date).sort(
        (a, b) => {
          if (a.date === b.date) {
            return a.time.localeCompare(b.time);
          }
          return a.date.localeCompare(b.date);
        }
      );
      const currentTimeSlotIndex = currentSlots.findIndex(
        (obj) => obj.time === currentTime
      );
      if (
        currentTimeSlotIndex !== -1 &&
        currentTimeSlotIndex < currentSlots.length - 1
      ) {
        let currentTime = currentSlots[currentTimeSlotIndex];
        let nextTime =
          currentSlots[currentTimeSlotIndex + prev[index].duration / 60];
        if (!nextTime) return prev;
        let currentTimeObj = new Date(`2000-01-01T${currentTime.time}`);
        let nextTimeObj = new Date(`2000-01-01T${nextTime.time}`);

        let differenceInMinutes =
          (nextTimeObj.getTime() - currentTimeObj.getTime()) / (1000 * 60);

        if (differenceInMinutes > prev[index].duration) return prev;
      }

      const lastTimeSlot = currentSlots[currentSlots.length - 1].time;
      if (currentTime === lastTimeSlot) return prev;
      // Увеличиваем duration, если не перекрывает или если нет следующего слота
      return prev.map((item, i) => {
        if (i === index && item.duration + 60 <= 180) {
          return { ...item, duration: item.duration + 60 };
        }
        return item;
      });
    });
  }; */

  const setPaymentForData = () => {
    if (!selectSpecialistId || !selectedSessionLang) return;
    const bookingsData = selectedTime.map((item) => {
      const userMoment = moment(item.date.toISOString())
        .tz(userData?.timezone!, true)
        .utc();

      return {
        date: userMoment.format(),
        duration: item.duration,
      };
    });

    const paymentForCurrent = {
      type: PaymentForType.SESSION,
      bookingFor: userId,
      datesAndTime: bookingsData,
      projectId: projectId,
      bookingForSpecialist: selectSpecialistId,
      sessionLanguage: selectedSessionLang,
      tags: sessionTags
        .filter((el) => el._id !== "other")
        .map((item) => item._id),
      subject: sessionDescription,
    };

    setPaymentFor(paymentForCurrent);
  };

  useEffect(() => {
    if (showTagsBlock) {
      setStepInfo({
        stepNumber: 3,
        stepTitle: strings.bookingStep3,
      });
    }
    if (!isSelectSpecStep && !showTagsBlock) {
      setStepInfo({
        stepNumber: 2,
        stepTitle: strings.bookingStep2,
      });
    }
    if (isSelectSpecStep) {
      setStepInfo({
        stepNumber: 1,
        stepTitle: strings.bookingStep1,
      });
    }
  }, [showTagsBlock, isSelectSpecStep]);

  return (
    <div className={s.container}>
      <div className={s.headerBlock}>
        <div>
          {((stepInfo.stepNumber === 2 &&
            (allSpecializations.length > 1 || allSessionLangs.length > 1)) ||
            (stepInfo.stepNumber === 2 && selectedDate)) && (
            <ChevronIcon
              className={s.chevronBackIcon}
              onClick={() => {
                if (!selectedDate) {
                  setSelectSpecialistId(undefined);
                  setIsSelectSpecStep(true);
                  setSelectedDate(null);
                }
                if (selectedDate) {
                  setSelectedDate(null);
                }
              }}
            />
          )}
          {stepInfo.stepNumber === 3 && (
            <ChevronIcon
              className={s.chevronBackIcon}
              onClick={() => {
                if (showTagsBlock) {
                  setShowTagsBlock(false);
                  setSessionTags([]);
                  setSessionDescription("");
                } else {
                  setSelectedDate(null);
                }
              }}
            />
          )}
        </div>
        <div className={s.stepBlock}>
          <span className={s.stepTitle}>{stepInfo.stepTitle}</span>
          <span
            className={s.stepNumber}
          >{`${strings.step} ${stepInfo.stepNumber} ${strings.of} 3`}</span>
        </div>
        <div role={"button"} onClick={() => setModalOpen(false)}>
          <CloseIcon className={s.closeModalIcon} />
        </div>
      </div>
      {stepInfo.stepNumber !== 3 && (
        <div className={s.specialistInfoBlock}>
          <div className={s.avtarBlock}>
            <CircleAvatar
              userId={userId}
              width="80px"
              height="80px"
              fontSize="32px"
            />
          </div>
          <div className={s.titleBlock}>
            <div className={s.nameBlcok}>
              <span className={s.nameTitle}>{fullname}</span>
              <span className={s.checkBlock}>
                <CheckIcon className={s.checkIcon} />
              </span>
            </div>
          </div>
        </div>
      )}

      {isSelectSpecStep &&
      (allSpecializations.length > 1 || allSessionLangs.length > 1) &&
      isShowingSpecializationsTab ? (
        <div className={s.selectSpecBlock}>
          <div>
            <p className={s.freeDaysTitle}>
              {strings.chooseSpecializationBookingLable}
            </p>
            <div className={s.specializationTabsBlock}>
              {allSpecializations.map((item, index) => (
                <div
                  key={index}
                  className={
                    selectSpecialistId === item.specialistId
                      ? s.activeSpecializationTab
                      : s.notActiveSpecializationTab
                  }
                  onClick={() => setSelectSpecialistId(item.specialistId)}
                >
                  {item.lable}
                </div>
              ))}
            </div>
          </div>
          <div>
            <p className={s.freeDaysTitle}>
              {strings.setupLanguagePlaceHodler}
            </p>
            <MultipleSelect
              data={allSessionLangs}
              multiplie={false}
              setValue={(value) => setSelectedSessionLang(value as string)}
              value={selectedSessionLang ?? ""}
              padding="10px"
            />
          </div>
          <div className={s.nextBlock}>
            <div>
              {selectSpecialistId && selectedSessionLang && (
                <div className={s.priceBlock}>
                  <span>
                    {strings.price}:{" "}
                    {
                      priceData[selectSpecialistId].find(
                        (el) => el.language === selectedSessionLang
                      )?.discountPrice
                    }
                    $
                  </span>
                </div>
              )}
            </div>
            <StandartButton
              action={() => setIsSelectSpecStep(false)}
              buttonTitle={strings.nextBtn}
              disabled={
                selectSpecialistId && selectedSessionLang ? false : true
              }
              width={"150px"}
            />
          </div>
        </div>
      ) : null}

      {!isSelectSpecStep && (
        <div>
          {!showTagsBlock && (
            <div>
              {/*  {allSpecializations.length > 1 && isShowingSpecializationsTab ? (
                <MultipleSelect
                  data={allSpecializations.map((item) => ({
                    lable: item.lable,
                    value: item.specialistId,
                  }))}
                  multiplie={false}
                  setValue={(value) => setSelectSpecialistId(value as string)}
                  value={selectSpecialistId ?? ""}
                  width={"300px"}
                  padding="10px"
                  lable={strings.chooseSpecializationBookingLable}
                  margin={"30px 0 10px 0"}
                />
              ) : null} */}
              <div className={s.decriptionTitleBlock}>
                <p className={s.freeDaysTitle}>
                  {selectedDate ? strings.freeTime : strings.freDays}
                </p>
                {selectedDate && (
                  <p className={s.timeszoneTitle}>
                    {strings.timeRepresentedDescription} ({userData?.timezone})
                  </p>
                )}
              </div>
              {selectedDate ? (
                <div className={s.timeBookingBlock}>
                  <div className={s.selectDateBlock}>
                    {weekDates.length &&
                      weekDates.map((item, index) => {
                        const freeDays = daySlots.map((item) =>
                          new Date(item).getDate()
                        );
                        return (
                          <div className={s.datesList} key={index}>
                            <span>{item.day}</span>
                            <span
                              className={
                                selectedDate.toDate().getDate() ===
                                item.date.getDate()
                                  ? s.selectedDate
                                  : freeDays.includes(
                                      new Date(item.date).getDate()
                                    )
                                  ? ""
                                  : s.notFreeDay
                              }
                              onClick={
                                freeDays.includes(new Date(item.date).getDate())
                                  ? () => {
                                      setSelectedDate(dayjs(item.date));
                                      /*  setSelectedTime(""); */
                                    }
                                  : () => null
                              }
                            >
                              {item.date.getDate()}
                            </span>
                          </div>
                        );
                      })}
                  </div>
                  <div
                    className={s.selectTimeBlock}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                  >
                    {timeSlots.map((item, index) => (
                      <button
                        key={index}
                        className={
                          selectedTime.some((selected) =>
                            areDatesEqual(
                              selected.date,
                              combineDateTime(item.date, item.time)
                            )
                          )
                            ? s.selectedFreeTimeButton
                            : s.freeTimeButton
                        }
                        onClick={() => {
                          const newDateTime = combineDateTime(
                            item.date,
                            item.time
                          );
                          setSelectedTime((prev) => {
                            const existingIndex = prev.findIndex((selected) =>
                              areDatesEqual(selected.date, newDateTime)
                            );
                            if (existingIndex > -1) {
                              return [
                                ...prev.slice(0, existingIndex),
                                ...prev.slice(existingIndex + 1),
                              ];
                            } else {
                              return [{ date: newDateTime, duration: 60 }];
                            }
                          });
                        }}
                      >
                        {item.time}
                      </button>
                    ))}
                  </div>
                </div>
              ) : (
                <div className={s.calendarBlock}>
                  <MuiCalendar
                    setSelectedDate={setSelectedDate}
                    dateSlots={daySlots}
                    isSelectedMode={false}
                    fontSize="14px"
                    disableAutoSelectDay
                  />
                </div>
              )}
            </div>
          )}
          {selectedTime.length && !showTagsBlock ? (
            <div className={s.selectedSessionList}>
              <h4>{strings.selectBookSlot}</h4>
              <div className={s.selectBookSlot}>
                <span className={s.sessionDateTitle}>
                  {strings.dateBooking}
                </span>
                <span className={s.sessionTimeTitle}>
                  {strings.timeBooking}
                </span>
                <span>{strings.durationBooking}</span>
              </div>
              <div className={s.selectedSessionElementContainer}>
                {selectedTime.map((item, index) => (
                  <div className={s.selectedSessionElement} key={index}>
                    <span className={s.sessionDateTitle}>
                      {formatDate(item.date)}
                      {/* {item.date.getDate()} */}
                    </span>
                    <span className={s.sessionTimeTitle}>
                      {item.date.getHours().toString().padStart(2, "0") +
                        ":" +
                        item.date.getMinutes().toString().padStart(2, "0")}
                    </span>
                    <div className={s.durationValueBlock}>
                      <div className={s.durationValue}>
                        {/* <button
                      className={s.durationButton}
                      onClick={() =>
                        setSelectedTime((prev) =>
                          prev.map((itemPrev, i) => {
                            if (i === index && item.duration > 60) {
                              return {
                                ...itemPrev,
                                duration: itemPrev.duration - 60,
                              };
                            }
                            return itemPrev;
                          })
                        )
                      }
                    >
                      -
                    </button> */}
                        <span className={s.durationValueTitle}>
                          {/*     {item.duration} */}{" "}
                          {!alreadyWork &&
                          allSpecializations.find(
                            (el) => el.specialistId === selectSpecialistId
                          )?.introductionSession.switch
                            ? allSpecializations.find(
                                (el) => el.specialistId === selectSpecialistId
                              )?.introductionSession.duration
                            : "50"}
                        </span>
                        {/*   <button
                      className={s.durationButton}
                      onClick={() => incrementDuration(index)}
                    >
                      +
                    </button> */}
                      </div>
                      <DeleteIcon
                        className={s.deletIcon}
                        onClick={() =>
                          setSelectedTime((prev) =>
                            prev.filter((_, i) => i !== index)
                          )
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {showTagsBlock && (
            <div className={s.sessionTagsSubjectSession}>
              {sessionTags.map((item) => item._id).includes("other") ? (
                <div className={s.subjectBlock}>
                  <div className={s.selectedSessionTagsBlock}>
                    {sessionTags && sessionTags.length
                      ? sessionTags.map((item, index) =>
                          item._id === "other" ? (
                            <div key={index}></div>
                          ) : (
                            <div key={index} className={s.tagElement}>
                              <span>{item.lable}</span>{" "}
                              <CloseIcon
                                className={s.closeIcon}
                                onClick={() =>
                                  setSessionTags((prev) => prev.slice(0, index))
                                }
                              />
                            </div>
                          )
                        )
                      : null}
                  </div>
                  <div className={s.subjectTitleBlock}>
                    <span className={s.grayText}>
                      {strings.otherTextAreaDescription}
                    </span>
                    <span className={s.maxLength}>
                      ({sessionDescription.length + "/" + "100"})
                    </span>
                  </div>
                  <textarea
                    name=""
                    id=""
                    cols={30}
                    rows={3}
                    value={sessionDescription}
                    className={s.textAreaNewNote}
                    onChange={(e) => setSessionDescription(e.target.value)}
                    maxLength={100}
                  ></textarea>
                </div>
              ) : (
                <div className={s.tagsBlock}>
                  <div className={s.selectedSessionTagsBlock}>
                    {sessionTags && sessionTags.length
                      ? sessionTags.map((item, index) =>
                          item._id === "other" ? (
                            <div></div>
                          ) : (
                            <div key={index}>
                              <div className={s.tagElement}>
                                <span>{item.lable}</span>{" "}
                                <CloseIcon
                                  className={s.closeIcon}
                                  onClick={() =>
                                    setSessionTags((prev) =>
                                      prev.slice(0, index)
                                    )
                                  }
                                />
                              </div>
                            </div>
                          )
                        )
                      : null}
                  </div>
                  {/*          <span className={s.freeDaysTitle}>
                    {tagsSteps[sessionTags.length]}
                  </span> */}
                  {sessionTags.length < 3 && (
                    <span className={s.grayText}>{strings.pleaseSelect}</span>
                  )}
                  {sessionTags.length < 3 && (
                    <div className={s.tagsDataBlock}>
                      {[
                        ...tagsData,
                        { value: "other", lable: strings.otherCertificate },
                      ].map((item, index) => (
                        <div
                          key={index}
                          className={s.tagElement}
                          onClick={() => {
                            setSessionTags((prev) => [
                              ...prev,
                              { _id: item.value, lable: item.lable },
                            ]);
                          }}
                        >
                          {item.lable}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {!showTagsBlock && (
            <div className={s.nextBlock}>
              <div>
                {selectSpecialistId && selectedSessionLang && (
                  <div className={s.priceBlock}>
                    <span>
                      {strings.price}:{" "}
                      {
                        priceData[selectSpecialistId].find(
                          (el) => el.language === selectedSessionLang
                        )?.discountPrice
                      }
                      $
                    </span>
                  </div>
                )}
              </div>
              <StandartButton
                action={() => {
                  setShowTagsBlock(true);
                }}
                buttonTitle={strings.nextBtn}
                width={"150px"}
                disabled={selectedTime.length && !showTagsBlock ? false : true}
              />{" "}
            </div>
          )}

          {selectedTime.length && showTagsBlock ? (
            <div className={s.bookBtnBlock}>
              <StandartButton
                action={() => {
                  sendRequestHandler();
                }}
                buttonTitle={strings.sendRequestBtn}
                width={"200px"}
                disabled={
                  sessionDescription.length || sessionTags.length === 3
                    ? false
                    : true
                }
                tooltipTitle={
                  sessionDescription.length || sessionTags.length === 3
                    ? ""
                    : strings.subjectError
                }
              />
            </div>
          ) : null}

          {paymentFor && (
            <ModalWindow
              isOpen={noBalanceModal}
              setIsOpen={setNoBalanceModal}
              width="fit-content"
            >
              <PaymentSession hidePromocode paymentFor={paymentFor} />
            </ModalWindow>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectSessionDate;

import { CircularProgress, ThemeProvider } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UsersApi from "../../../api/usersApi";
import EyeSlashIcon from "../../../assets/Auth/eye-slash.svg";
import EyeIcon from "../../../assets/Auth/eye.svg";
import GroupBg2 from "../../../assets/Auth/Group-1.png";
import GroupBg3 from "../../../assets/Auth/Group-2.png";
import GroupBg1 from "../../../assets/Auth/Group.png";
import ResetBgImage from "../../../assets/Auth/resetbg.png";
import Logo from "../../../assets/logo.png";
import Input from "../../../components/Input/Input";
import SiteLangsTabs from "../../../components/SiteLangsTabs/SiteLangsTabs";
import StandartButton from "../../../components/StandartButton/StandartButton";
import strings from "../../../localization";
import { theme } from "../../../utils/theme";
import s from "./UpdatePassword.module.css";

const UpdatePassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [userId, setUserId] = useState("");
  const [resetStep, setResetStep] = useState(1);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const sendEmail = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Invalid email address");
      return;
    }
    setLoader(true);
    const response = await UsersApi.confirmPassUpdate(email);
    if (response.status) {
      setUserId(response.id);
      setError("");
      setResetStep(2);
      setLoader(false);
    }
  };
  const updatePassword = async () => {
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+={}\[\]:;<>,.?~\\/-]{8,}$/;

    if (userId === "") return;
    if (!passwordRegex.test(newPassword)) {
      setError(
        "Invalid password. It must be at least 8 characters long and contain at least one letter and one number"
      );
      return;
    }
    setLoader(true);

    const response = await UsersApi.updatePassword({
      id: userId,
      confirmationCode: Number(code),
      password: newPassword,
    });

    setLoader(false);
    if (response.message === "Wrong confirmation code") {
      setResetStep(2);
      setError("Wrong confirmation code");
      return;
    }
    if (response.status) {
      navigate("/login");
    }
  };
  const saveCode = () => {
    const regex = /^\d{5}$/;
    if (!regex.test(code)) {
      setError("Invalid confirm code");
      return;
    }
    setError("");
    setResetStep(3);
  };
  const onChangeHandler = (value: string) => {
    resetStep === 1
      ? setEmail(value)
      : resetStep === 2
      ? setCode(value)
      : setNewPassword(value);
  };

  const stepTitle = {
    step1: strings.pleaseEnterEmail,
    setp2: strings.weHaveSent,
    setp3: strings.nowEnterPassword,
  };

  if (loader) {
    return (
      <div className={s.loaderContainer}>
        <ThemeProvider theme={theme}>
          <CircularProgress size={150} color="primary" />
        </ThemeProvider>
      </div>
    );
  }
  return (
    <div className={s.container}>
      <SiteLangsTabs />
      <div className={s.contentBlock}>
        <img src={Logo} alt="" className={s.logo} />
        <h1 className={s.headTitle}>{strings.resetTitle}</h1>
        <div className={s.mainContent}>
          <p>{strings.resetdescription}</p>
          <p>
            {resetStep === 1
              ? stepTitle.step1
              : resetStep === 2
              ? stepTitle.setp2
              : stepTitle.setp3}
          </p>
          <div className={s.inputBlock}>
            <Input
              required
              lable={
                resetStep === 1
                  ? strings.emailLable
                  : resetStep === 2
                  ? strings.codeLable
                  : strings.passwordLable
              }
              onChangeInput={onChangeHandler}
              inputValue={
                resetStep === 1 ? email : resetStep === 2 ? code : newPassword
              }
              isVisible={
                resetStep === 1 || resetStep === 2 ? true : passwordVisible
              }
              placeholder={
                resetStep === 1
                  ? "email@example.com"
                  : resetStep === 2
                  ? "00000"
                  : ""
              }
            />
            {resetStep === 3 && (
              <img
                data-testid="auth-visible-switcher"
                src={passwordVisible ? EyeSlashIcon : EyeIcon}
                alt=""
                className={s.eyeIcon}
                onClick={() => setPasswordVisible(!passwordVisible)}
              />
            )}
          </div>
          <div className={s.errorBlock}>{error !== "" && <p>{error}</p>}</div>
          <div className={s.buttonsBlock}>
            <StandartButton
              width="150px"
              action={
                resetStep === 1
                  ? sendEmail
                  : resetStep === 2
                  ? saveCode
                  : updatePassword
              }
              buttonTitle={
                resetStep === 1 || resetStep === 2
                  ? strings.nextBtn
                  : strings.okBtn
              }
            />
          </div>
        </div>
      </div>
      <div className={s.footerBlock}></div>
      <img src={ResetBgImage} alt="" className={s.resetBg} />
      <img
        src={GroupBg1}
        alt=""
        className={s.group1}
        style={{ display: resetStep === 1 ? "none" : "block" }}
      />
      <img
        src={GroupBg2}
        alt=""
        className={s.group2}
        style={{ display: resetStep === 1 ? "none" : "block" }}
      />
      <img
        src={GroupBg3}
        alt=""
        className={s.group3}
        style={{ display: resetStep === 1 ? "none" : "block" }}
      />
    </div>
  );
};

export default UpdatePassword;

import { useEffect, useState } from "react";
import s from "./Input.module.css";

type AuthInputProps = {
  inputValue: string;
  onChangeInput: (value: string) => void;
  placeholder?: string;
  isVisible?: boolean;
  required?: boolean;
  lable?: string;
  bgColor?: string;
  padding?: string;
  fontSize?: string;
  margin?: string;
  type?: string;
  border?: string;
  showRequiredStar?: boolean;
  maxNumber?: number;
  minNumber?: number;
  enableValidateNumber?: boolean;
};

const Input = ({
  inputValue,
  onChangeInput,
  placeholder,
  isVisible = true,
  required = true,
  lable,
  bgColor,
  padding,
  fontSize,
  margin,
  type,
  border,
  maxNumber,
  minNumber,
  showRequiredStar,
  enableValidateNumber = true,
}: AuthInputProps) => {
  const [internalValue, setInternalValue] = useState<string>(inputValue);

  useEffect(() => {
    if (inputValue === internalValue) return;
    setInternalValue(inputValue);
  }, [inputValue]);

  const handleChange = (value: string) => {
    // Сохраняем промежуточное значение
    setInternalValue(value);
    if (type !== "number" || !enableValidateNumber) {
      onChangeInput(value);
    }
  };

  const handleBlur = () => {
    if (type !== "number") {
      onChangeInput(internalValue);
      return;
    }
    const numericValue = Number(internalValue);
    let validatedValue = numericValue;

    // Проверяем на минимальное и максимальное значение
    if (maxNumber !== undefined && numericValue > maxNumber) {
      validatedValue = maxNumber;
    } else if (minNumber !== undefined && numericValue < minNumber) {
      validatedValue = minNumber;
    }

    // Применяем проверенное значение
    onChangeInput(validatedValue.toString());
    setInternalValue(validatedValue.toString());
  };

  return (
    <div className={s.container}>
      <span className={s.lable}>
        {lable}
        {showRequiredStar && <span className={s.requiredStar}>*</span>}
      </span>
      <input
        value={internalValue}
        type={isVisible ? type ?? "text" : "password"}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={type === "number" ? handleBlur : undefined}
        className={s.input}
        placeholder={placeholder ? placeholder : ""}
        required={required}
        onWheel={(e) => (type === "number" ? e.currentTarget.blur() : null)}
        style={{
          letterSpacing: isVisible ? "1px" : "5px",
          backgroundColor: bgColor ?? "#f0f0f0",
          padding: padding ?? "14px 20px",
          fontSize: fontSize ?? "16px",
          margin: margin ?? "0",
          border: border ?? "none",
        }}
      />
    </div>
  );
};

export default Input;

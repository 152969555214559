import Modal from "@mui/material/Modal";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { ReactComponent as CloseIcon } from "../../assets/HomePage/close.svg";
import s from "./ModalWindow.module.css";

type ModalWindowProps = {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  width?: string;
  height?: string;
  bgColor?: string;
  padding?: string;
  position?: "right" | "left" | "bottom";
  maxWidth?: string;
  alternativeCloseFunction?: () => void;
  showCloseBtn?: boolean;
  headTitle?: string;
  borderRadius?: string;
  disableBackdropClick?: boolean;
  headerMargin?: string;
};
const ModalWindow = ({
  children,
  isOpen,
  setIsOpen,
  width,
  height,
  bgColor,
  padding,
  position,
  maxWidth,
  alternativeCloseFunction,
  showCloseBtn = true,
  headTitle,
  borderRadius = "20px",
  disableBackdropClick = false,
  headerMargin = "0 0 20px 0",
}: ModalWindowProps) => {
  return (
    <div style={{ outline: "none" }}>
      <Modal
        open={isOpen}
        onClose={(_, reason) => {
          if (disableBackdropClick && reason === "backdropClick") return;
          if (alternativeCloseFunction) {
            alternativeCloseFunction();
          } else if (setIsOpen) {
            setIsOpen(false);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={s.modal}
      >
        <div
          className={
            position === "right"
              ? s.modalContentRight
              : position === "left"
              ? s.modalContentLeft
              : position === "bottom"
              ? s.modalContentBottom
              : s.modalContent
          }
          style={{
            maxWidth: maxWidth ?? "auto",
            width: width ? width : "50%",
            height: height ? height : "fit-content",
            backgroundColor: bgColor ? bgColor : "white",
            padding: padding
              ? padding
              : window.innerWidth > 768
              ? "20px 20px"
              : "20px",
            outline: "none",
            overflow: "hidden",
            borderRadius: borderRadius,
          }}
        >
          {showCloseBtn && (
            <div className={s.closeBtnBlock} style={{ margin: headerMargin }}>
              <div className={s.headTitle}>{headTitle}</div>
              <div
                className={s.closeBtn}
                role="button"
                onClick={
                  alternativeCloseFunction
                    ? alternativeCloseFunction
                    : setIsOpen
                    ? () => setIsOpen(false)
                    : () => null
                }
              >
                <CloseIcon className={s.closeIcon} />
              </div>
            </div>
          )}
          {children}
        </div>
      </Modal>
    </div>
  );
};
export default ModalWindow;

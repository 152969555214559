import { Tooltip } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Peer from "simple-peer";
import MessageChatApi from "../../api/messageChatApi";
import RecordingsApi from "../../api/recordingsApi";
import SessionsApi from "../../api/sessionApi";
import { ReactComponent as NotesIcon } from "../../assets/SideBar/notes.svg";
import { ReactComponent as TasksIcon } from "../../assets/SideBar/todo.svg";
import { ReactComponent as AlertCircle } from "../../assets/VideoSession/alert-circle.svg";
import { ReactComponent as ChatIcon } from "../../assets/VideoSession/chat.svg";
import { ReactComponent as FullScreenIcon } from "../../assets/VideoSession/full-screen.svg";
import CircleAvatar from "../../components/CircleAvatar/CircleAvatar";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import StandartButton from "../../components/StandartButton/StandartButton";
import { network } from "../../config";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import TypeDefaultResponse from "../../types/TypeDefaultResponse";
import { TypeSessionChatResponse } from "../../types/TypeMessageChat";
import { Sessions } from "../../types/TypeSession";
import { TodoList } from "../../types/TypeToDo";
import { TypeUserData } from "../../types/TypeUsers";
import { formatDateAndTime } from "../../utils/dateActions";
import { getTranslation } from "../../utils/getTranslation";
import socket from "../../utils/socket";
import BottomMenu from "./BottomMenu/BottomMenu";
import Chat from "./Chat/Chat";
import NotesVideoChat from "./NotesVideoChat/NotesVideoChat";
import s from "./SessionVideoChat.module.css";
import TodoVideoChat from "./TodoVideoChat/TodoVideoChat";

const recordStartSound = new Audio("/sounds/recording-start.mp3");
const recordStopSound = new Audio("/sounds/recording-end.mp3");

recordStartSound.volume = 0.5;
recordStopSound.volume = 0.5;

const { sessions } = network;
const width = window.innerWidth;
const height = window.innerHeight;

const Video = (props: any) => {
  const ref = useRef<any>();
  const { userData } = useContext(ContextProvider);
  const [videoVisible, setVideoVisible] = useState(false);

  useEffect(() => {
    props.peer.once("stream", (stream: MediaStream) => {
      if (ref.current) ref.current.srcObject = stream;
    });
    //@ts-expect-error
    props.peer.on("data", (data) => {
      if (data.toString() === "video-false") setVideoVisible(false);
      else if (data.toString() === "video-true") setVideoVisible(true);
    });
  }, []);

  useEffect(() => {
    const audioDeviceId =
      props.outputAudioDevices &&
      props.outputAudioDevices.find(
        (device: { isSelect: boolean }) => device.isSelect === true
      )?.deviseId;
    if (ref.current && audioDeviceId) {
      try {
        ref.current.setSinkId(audioDeviceId);
      } catch (error) {}
    }
  }, [props.outputAudioDevices]);

  return (
    <>
      <video
        className={s.mainVideo}
        autoPlay
        ref={ref}
        style={!videoVisible ? { opacity: 0, position: "absolute" } : {}}
      />
      <div style={videoVisible ? { opacity: 0, position: "absolute" } : {}}>
        <CircleAvatar
          userId={
            props.chatData
              ? props.chatData.avatars.filter(
                  (item: { id: string | undefined }) =>
                    item.id !== userData?._id
                )[0].id
              : ""
          }
          height="40svh"
          width="40svh"
          marginRight="0"
          fontSize="124px"
        />
      </div>
    </>
  );
};

export type DevicesList = {
  title: string;
  deviseId: string;
  isSelect: boolean;
};

const SessionVideoChat = () => {
  const token = localStorage.getItem("token");
  const location = useLocation();
  const { sessionId, specialistId } = location.state;
  const { userData } = useContext(ContextProvider);
  const [peers, setPeers] = useState<{ peerID: string; peer: Peer.Instance }[]>(
    []
  );
  const [myStream, setMyStream] = useState<MediaStream>();
  const [todosData, setTodosData] = useState<TodoList[] | null>(null);
  const [deltaPosition, setDeltaPosition] = useState({ x: 0, y: 0 });
  const [camera, setCamera] = useState(false);
  const [microphone, setMicrophone] = useState(false);
  const [time, setTime] = useState(new Date());
  const [recordEnable, setRecordEnable] = useState(false);
  const [audioRecordPeer, setAudioRecordPeer] = useState<Peer.Instance>();
  const [approveRecordModal, setApproveRecordModal] = useState(false);
  const [approvals, setApprovals] = useState<
    { userId: string; status: boolean }[] | undefined
  >();
  const [isRecordStarted, setIsRecordStarted] = useState(false);
  const [initializedModals, setInitializedModals] = useState({
    infrotamtionModal: false,
    informationConfirmModal: false,
  });
  const [notifyFromUserData, setNotifyFromUserData] = useState<
    { userId: string; name: string; surname: string } | undefined
  >();

  const [chatData, setChatData] = useState<
    TypeDefaultResponse & {
      avatars: { id: string; avatar: string; specialistIds?: string[] }[];
      chat?: TypeSessionChatResponse | null | undefined;
    }
  >();
  const [toolsState, setToolsState] = useState({
    chat: false,
    tasks: false,
    notations: false,
  });
  const [inputAudioDevices, setInputAudioDevices] = useState<
    DevicesList[] | null
  >(null);
  const [outputAudioDevices, setOutputAudioDevices] = useState<
    DevicesList[] | null
  >(null);
  const [inputVideoDevices, setInputVideoDevices] = useState<
    DevicesList[] | null
  >(null);
  const [sessionInfo, setSessionInfo] = useState<
    | (Sessions & {
        clients: TypeUserData[];
        specialistUsers: TypeUserData[];
      })
    | undefined
  >();
  const [userIsSpecialist, setUserIsSpecialist] = useState<boolean | undefined>(
    undefined
  );
  const [approveActionStatus, setApproveActionStatus] = useState<
    "sent" | "decline" | undefined
  >();
  const [actionLoaders, setActionLoaders] = useState({ aprroveLoader: false });
  const [isChatFullscreen, setIsChatFullscreen] = useState(false);

  const peersRef = useRef<{ peerID: string; peer: Peer.Instance }[]>([]);
  const myVideoRef = useRef<HTMLVideoElement>(null);
  const effectHasRun = useRef(false);
  const cameraSwitchRef = useRef<boolean>(false);

  const { recordings } = network;

  useEffect(() => {
    if (!approvals || userIsSpecialist === undefined || !sessionInfo) return;
    if (!approvals.length) {
      if (!userIsSpecialist || sessionInfo.askSpecForRecording) {
        setApproveRecordModal(true);
      }

      setRecordEnable(false);
      return;
    }
    const myApprove = approvals.find((el) => el.userId === userData?._id);
    if (!myApprove) {
      if (!userIsSpecialist || sessionInfo.askSpecForRecording) {
        setApproveRecordModal(true);
      }
      setRecordEnable(false);
      return;
    }
    setRecordEnable(myApprove.status);
  }, [approvals, userIsSpecialist, sessionInfo]);

  useEffect(() => {
    if (!approvals || !approvals.length) return;
    const myApprove = approvals.find((el) => el.userId === userData?._id);
    const notMyApprovs = approvals.filter((el) => el.userId !== userData?._id);
    if (!myApprove) return;

    if (
      myApprove.status &&
      notMyApprovs &&
      notMyApprovs.length &&
      myApprove.status &&
      notMyApprovs.some((el) => !el.status) &&
      approveActionStatus !== "sent"
    ) {
      setApproveActionStatus("decline");
    }
  }, [approvals]);
  useEffect(() => {
    if (isRecordStarted) {
      setApproveActionStatus(undefined);
      if (userIsSpecialist && !sessionInfo?.askSpecForRecording) {
        setInitializedModals({
          infrotamtionModal: true,
          informationConfirmModal: false,
        });
      }
    }
  }, [isRecordStarted]);

  useEffect(() => {
    if (userIsSpecialist === undefined || !sessionInfo) return;
    socket.on(recordings.recordStarted, () => {
      setIsRecordStarted(true);
      setApproveActionStatus(undefined);
      recordStartSound.play();
    });
    socket.on(recordings.recordStopped, () => {
      setApprovals((prev) =>
        prev ? prev.map((item) => ({ ...item, status: false })) : prev
      );
      setIsRecordStarted(false);
      recordStopSound.play();
    });
    socket.on(
      recordings.recordInitialized,
      (data: { sessionId: string; userId: string }) => {
        setApproveRecordModal(false);
        if (userIsSpecialist && !sessionInfo?.askSpecForRecording) {
          setInitializedModals({
            infrotamtionModal: true,
            informationConfirmModal: false,
          });
        }
        if (
          (userIsSpecialist && sessionInfo?.askSpecForRecording) ||
          !userIsSpecialist
        ) {
          setInitializedModals({
            infrotamtionModal: false,
            informationConfirmModal: true,
          });
        }

        const socketUserData = [
          ...sessionInfo.clients,
          ...sessionInfo.specialistUsers,
        ].find((el) => el._id === data.userId);
        if (!socketUserData) return;
        setNotifyFromUserData({
          name: getTranslation(socketUserData.name, userData?.selectedLanguage),
          surname: getTranslation(
            socketUserData.surname,
            userData?.selectedLanguage
          ),
          userId: socketUserData._id,
        });
      }
    );
    socket.on(recordings.recordCancel, (data) => {
      const socketUserData = [
        ...sessionInfo.clients,
        ...sessionInfo.specialistUsers,
      ].find((el) => el._id === data.userId);
      setApproveActionStatus("decline");
      setApprovals((prev) =>
        prev ? prev.map((item) => ({ ...item, status: false })) : prev
      );
      if (!socketUserData) return;
      setNotifyFromUserData({
        name: getTranslation(socketUserData.name, userData?.selectedLanguage),
        surname: getTranslation(
          socketUserData.surname,
          userData?.selectedLanguage
        ),
        userId: socketUserData._id,
      });
    });
  }, [userIsSpecialist, sessionInfo]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleDrag = (_: any, ui: any) => {
    const { x, y } = deltaPosition;
    setDeltaPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    });
  };

  useEffect(() => {
    (async () => {
      if (!token || !sessionId) return;
      const response = await SessionsApi.getSessionInfoById(token, sessionId);
      if (response.status && response.session) {
        setUserIsSpecialist(
          response.session.specialistUsers
            .map((item) => item._id)
            .includes(userData!._id)
        );
        setSessionInfo(response.session);
      }
    })();
  }, [sessionId]);

  useEffect(() => {
    cameraSwitchRef.current = camera;
    if (!myStream) return;
    const videoTrack = myStream.getVideoTracks()[0];

    if (!videoTrack) return;

    peers.forEach((peerObj) => {
      if (
        peerObj &&
        peerObj.peer &&
        peerObj.peer.connected &&
        !peerObj.peer.destroyed && //@ts-expect-error
        peerObj.peer.channelName
      )
        peerObj.peer.send(`video-${camera}`);
    });
  }, [camera]);

  useEffect(() => {
    if (myStream && myVideoRef && myVideoRef.current)
      myVideoRef.current.srcObject = myStream;
  }, [myStream]);

  const connectToAudioRecord = (stream: MediaStream) => {
    try {
      const peer = new Peer({
        initiator: true,
        trickle: false,
        stream,
      });

      peer.on("error", (err) => {
        console.log("Error occurred:", err);

        if (err.message === "Connection failed.") {
          console.log("Connection failed. Reconnecting...");
          window.location.reload();
        }

        connectToAudioRecord(stream);
      });

      peer.on("signal", (signalData) => {
        console.log("callAudioRecorder: ", signalData);
        socket.emit("callAudioRecorder", {
          signalData,
          userId: userData?._id,
          sessionId,
        });
      });

      socket.on("audioRecordAccepted", ({ signal }) => {
        console.log("audioRecordAccepted: ", signal);

        setAudioRecordPeer(peer);

        peer.signal(signal);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updatePeersStreams = (stream: MediaStream) => {
    peersRef.current.forEach((peer) => {
      if (peer && peer.peer)
        peer.peer.streams[0].getTracks().forEach((track) => {
          const newTrack = stream
            .getTracks()
            .find((t) => t.kind === track.kind);
          if (newTrack) {
            peer.peer.replaceTrack(track, newTrack, peer.peer.streams[0]);
          }
        });
    });
    if (
      audioRecordPeer &&
      audioRecordPeer.streams[0].getAudioTracks()[0] !== undefined &&
      stream.getAudioTracks()[0]
    )
      audioRecordPeer.replaceTrack(
        audioRecordPeer.streams[0].getAudioTracks()[0],
        stream.getAudioTracks()[0],
        audioRecordPeer.streams[0]
      );
  };

  function isCurrentDeviceMobile() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  useEffect(() => {
    if (!inputVideoDevices || !inputAudioDevices) return;
    const videDeviceId =
      inputVideoDevices &&
      inputVideoDevices.find((device) => device.isSelect === true)?.deviseId;
    const audioDeviceId =
      inputAudioDevices &&
      inputAudioDevices.find((device) => device.isSelect === true)?.deviseId;

    const prevVideoTrackEnabled = Boolean(
      myStream?.getVideoTracks()[0].enabled
    );
    const prevAudioTrackEnabled = Boolean(
      myStream?.getAudioTracks()[0].enabled
    );

    if (myStream && isCurrentDeviceMobile()) {
      //Because can't get more than 1 stream at a time
      myStream.getTracks().forEach((track) => {
        track.stop();
      });
    }

    if (!videDeviceId && !audioDeviceId) setMyStream(new MediaStream());
    else {
      navigator.mediaDevices
        .getUserMedia({
          video: videDeviceId ? { deviceId: { exact: videDeviceId } } : false,
          audio: audioDeviceId ? { deviceId: { exact: audioDeviceId } } : false,
        })
        .then((stream) => {
          if (myStream) {
            if (stream.getVideoTracks()[0]) {
              stream.getVideoTracks()[0].enabled = prevVideoTrackEnabled;
            }

            if (stream.getAudioTracks()[0]) {
              stream.getAudioTracks()[0].enabled = prevAudioTrackEnabled;
            }
          } else
            stream.getTracks().forEach((track) => {
              track.enabled = false;
            });
          updatePeersStreams(stream);
          if (!audioRecordPeer) connectToAudioRecord(stream);
          setMyStream(stream);
        });
    }
  }, [inputVideoDevices, inputAudioDevices]);

  useEffect(() => {
    (async () => {
      const token = localStorage.getItem("token");
      if (userData && userData._id && token) {
        MessageChatApi.joinChat(sessionId, userData?._id);
        const chatDataResponse = await MessageChatApi.getSessionChat(
          sessionId,
          token
        );

        if (chatDataResponse.status && chatDataResponse.chat?.messages)
          if (JSON.stringify(chatData) !== JSON.stringify(chatDataResponse))
            setChatData(chatDataResponse);
      }
    })();
  }, [toolsState.chat]);

  useEffect(() => {
    const makeAsync = async () => {
      setPeers([]);
      peersRef.current = [];
      socket.emit(sessions.disconnectFromSession);
      await new Promise<{ status: boolean }>((resolve) => {
        socket.emit(sessions.disconnectFromSession);
        socket.once("onDisconnectFromSession", (response) => {
          resolve(response);
        });
      });
    };
    makeAsync();
  }, []);

  useEffect(() => {
    const loadModelsAsync = async () => {
      if (
        effectHasRun.current ||
        !myVideoRef ||
        !myVideoRef.current ||
        !myStream
      )
        return;
      myVideoRef!.current!.srcObject = myStream;
      socket.on(sessions.onJoinRoom, (payload) => {
        console.log(payload);
      });
      socket.on(sessions.cameraDisconnected, (id) => {
        const index = peersRef.current.findIndex(
          (peer: any) => peer.peerID == id
        );

        if (peersRef.current[index]) peersRef.current[index].peer.destroy();
        setPeers((prev) => prev.filter((el) => el.peerID !== id));
        peersRef.current = peersRef.current.filter(
          (el: any) => el.peerID !== id
        );
      });

      socket.on(sessions.allUsers, (users: any[]) => {
        const allPeers: {
          peerID: string;
          peer: Peer.Instance;
        }[] = [];
        peersRef.current = [];

        users.forEach((user) => {
          if (user.id === socket.id) return;
          const peer = createPeer(user.id, socket.id, myStream);
          peersRef.current.push({
            peerID: user.id,
            peer,
          });
          allPeers.push({ peer, peerID: user.id });
        });
        setPeers(allPeers);
      });

      socket.on(sessions.incomingCall, (payload) => {
        if (peersRef.current.find((user) => user.peerID === payload.from))
          return;
        const peer = addPeer(payload.signal, payload.from, myStream);
        peersRef.current.push({
          peerID: payload.from,
          peer,
        });

        setPeers((users) => [...users, { peer, peerID: payload.from }]);
      });

      socket.on(sessions.callAccepted, (payload) => {
        try {
          const item = peersRef.current.find((p) => p.peerID === payload.from);
          if (item && item.peer) {
            item.peer.signal(payload.signal);
          }
        } catch (error) {
          console.log(error);
        }
      });
      socket.emit(sessions.joinRoom, sessionId, userData?._id, specialistId);

      effectHasRun.current = true;
    };

    loadModelsAsync();
  }, [myVideoRef, myStream]);

  function createPeer(userToCall: any, from: any, stream: any) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
    });

    peer.on("error", (err) => {
      console.log("Error occurred:", err);

      if (err.message === "Connection failed.") {
        console.log("Connection failed. Reconnecting...");
        window.location.reload();
      }
    });

    peer.on("connect", () => {
      const orientation = width > height ? "albom" : "portrait";
      peer.send(`orientation-${orientation}`);
      peer.send(`video-${cameraSwitchRef.current}`);
      peer.send(`video-state-request`);
    });

    peer.on("signal", (signalData) => {
      socket.emit(sessions.callUser, { userToCall, from, signalData });
    });

    return peer;
  }

  function addPeer(signal: any, to: any, stream: any) {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
    });
    peer.on("error", (err) => {
      console.log("Error occurred:", err);
      if (err.message === "Connection failed.") {
        console.log("Connection failed. Reconnecting...");
        window.location.reload();
      }
    });
    peer.on("connect", () => {
      const orientation = width > height ? "albom" : "portrait";
      peer.send(`orientation-${orientation}`);
      peer.send(`video-${cameraSwitchRef.current}`);
      peer.send(`video-state-request`);
    });
    peer.on("signal", (signal) => {
      socket.emit(sessions.acceptCall, { signal, to });
    });

    setTimeout(() => {
      peer.signal(signal);
    }, 2000);

    return peer;
  }

  useEffect(() => {
    return () => {
      socket.emit(sessions.disconnectFromSession);
      window.location.replace(window.location.pathname);
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (!token) return;
      const response = await RecordingsApi.getSessionRecordingInfo(
        token,
        sessionId
      );

      if (!response.status) {
        setApprovals([]);
      }
      if (response.status && response.approvedForRecord !== undefined) {
        setIsRecordStarted(response.approvedForRecord);
      }
      if (response.status && response.approvals) {
        setApprovals(response.approvals);
      }
    })();
  }, []);

  const approveRecord = async (approval: boolean) => {
    if (!token || !sessionId) return;
    setActionLoaders((prev) => ({ ...prev, aprroveLoader: true }));
    if (approval) {
      setApproveActionStatus("sent");
    }
    if (!approval) {
      setApproveActionStatus(undefined);
    }
    const response = await RecordingsApi.approveRecording(
      token,
      sessionId,
      approval
    );

    setActionLoaders((prev) => ({ ...prev, aprroveLoader: false }));
    if (response.status && response.approvals) {
      setApproveRecordModal(false);
      setApprovals(response.approvals);
    }
  };

  const toolsBtnsData = [
    {
      icon: ChatIcon,
      action: () =>
        setToolsState((prev) => ({
          chat: !prev.chat,
          tasks: false,
          notations: false,
        })),
      title: strings.chatTitle,
      selected: toolsState.chat,
    },
    {
      icon: TasksIcon,
      action: () =>
        setToolsState({
          chat: false,
          tasks: !toolsState.tasks,
          notations: false,
        }),
      title: strings.todosTitle,
      selected: toolsState.tasks,
    },
    {
      icon: NotesIcon,
      action: () =>
        setToolsState({
          chat: false,
          tasks: false,
          notations: !toolsState.notations,
        }),
      title: strings.notesMenuTitle,
      selected: toolsState.notations,
    },
    {
      icon: FullScreenIcon,
      action: () => setIsChatFullscreen((prev) => !prev),
      title: strings.fullScreen,
      selected: isChatFullscreen,
    },
  ];

  const windowWidth = window.innerWidth;

  return (
    <div className={s.container}>
      <div
        className={`${s.content} ${
          (toolsState.chat || toolsState.notations || toolsState.tasks) &&
          s.moveContent
        } ${isChatFullscreen && s.fullscreenChat}`}
      >
        <div className={s.mainstreamBlockContext}>
          <div className={s.streamBlock}>
            {!peers || !peers.length ? (
              <div className={s.noUsersBlock}>
                <h1 style={{ textAlign: "center" }}>
                  {strings.noUsersInSession}
                </h1>
              </div>
            ) : peersRef.current.length ? (
              peersRef.current.map((peer, index: number) => {
                return (
                  <Video
                    peer={peer.peer}
                    outputAudioDevices={outputAudioDevices}
                    chatData={chatData}
                    key={index}
                  />
                );
              })
            ) : null}

            <Draggable onDrag={handleDrag} bounds="parent" scale={0.7}>
              <div className={s.myselfVideoBlock}>
                <video
                  autoPlay
                  ref={myVideoRef}
                  muted
                  style={!camera ? { opacity: 0, position: "absolute" } : {}}
                />
                <span
                  style={camera ? { opacity: 0, position: "absolute" } : {}}
                >
                  <CircleAvatar
                    userId={userData?._id}
                    height="100px"
                    width="100px"
                    marginRight="0"
                  />
                </span>
              </div>
            </Draggable>
          </div>
          <div
            className={`${s.tollsBlockFullScreen} ${
              (toolsState.chat || toolsState.tasks || toolsState.notations) &&
              s.tollsBlockFullScreenActive
            }`}
            style={{
              display: isChatFullscreen && windowWidth > 768 ? "block" : "none",
            }}
          >
            {!toolsState.notations && !toolsState.tasks && (
              <Chat
                sessionId={sessionId}
                setToolsState={setToolsState}
                chatHistory={chatData?.chat?.messages || []}
                avatars={
                  chatData
                    ? chatData.avatars.filter(
                        (item) => item.id !== userData?._id
                      )
                    : []
                }
              />
            )}
            {toolsState.tasks && sessionInfo && (
              <TodoVideoChat
                setToolsState={setToolsState}
                setTodosData={setTodosData}
                todosData={todosData}
                userSpecialistIds={sessionInfo.specialistUsers.map(
                  (item) => item._id
                )}
                clientsIds={sessionInfo.clients.map((item) => item._id)}
              />
            )}
            {toolsState.notations && (
              <NotesVideoChat
                setToolsState={setToolsState}
                sessionId={sessionId}
              />
            )}
          </div>
        </div>
        <div className={s.bottomMenuBlock}>
          <div className={s.leftPartBottomMenu}>
            <div className={s.currentTime}>
              {
                formatDateAndTime(time, userData!.selectedLanguage)
                  .formattedTime
              }
            </div>
            {isRecordStarted && (
              <div className={s.recordIndicatorBlock}>
                <div className={s.recordingDot}></div>
                <span>{strings.audioRecordTitle}</span>
              </div>
            )}
          </div>
          <BottomMenu
            myStream={myStream}
            peers={peers}
            inputAudioDevices={inputAudioDevices}
            inputVideoDevices={inputVideoDevices}
            outputAudioDevices={outputAudioDevices}
            setInputAudioDevices={setInputAudioDevices}
            setInputVideoDevices={setInputVideoDevices}
            setOutputAudioDevices={setOutputAudioDevices}
            setMyStream={setMyStream}
            sessionId={sessionId}
            camera={camera}
            setCamera={setCamera}
            microphone={microphone}
            setMicrophone={setMicrophone}
            recordEnable={recordEnable}
            approveRecord={approveRecord}
            toolsBtnsData={toolsBtnsData}
            userIsSpecialist={userIsSpecialist}
            sessionInfo={sessionInfo}
            approveActionStatus={approveActionStatus}
            isRecordStarted={isRecordStarted}
            notifyFromUserData={notifyFromUserData}
            actionLoaders={actionLoaders}
            setApproveRecordModal={setApproveRecordModal}
          />
          <div className={s.toolsButtonsBlock}>
            {toolsBtnsData.map((item, index) => (
              <div key={index} onClick={item.action} className={s.toolsBtn}>
                <Tooltip title={item.title} placement="top">
                  <item.icon
                    className={item.selected ? s.activeIcon : s.toolsIcon}
                    width={25}
                    height={25}
                  />
                </Tooltip>
              </div>
            ))}
          </div>
        </div>
        {/*  <div className={s.rightMenuBlock}>
          <RightMenu toolsState={toolsState} setToolsState={setToolsState} />
        </div> */}
      </div>

      <div
        className={`${s.tollsBlock} ${toolsState.chat && s.chatActive} ${
          toolsState.tasks && s.tasksActive
        } ${toolsState.notations && s.notationsActive}`}
        style={{
          display: !isChatFullscreen || windowWidth <= 768 ? "block" : "none",
        }}
      >
        {!toolsState.notations && !toolsState.tasks && (
          <Chat
            sessionId={sessionId}
            setToolsState={setToolsState}
            chatHistory={chatData?.chat?.messages || []}
            avatars={
              chatData
                ? chatData.avatars.filter((item) => item.id !== userData?._id)
                : []
            }
          />
        )}
        {toolsState.tasks && sessionInfo && (
          <TodoVideoChat
            setToolsState={setToolsState}
            setTodosData={setTodosData}
            todosData={todosData}
            userSpecialistIds={sessionInfo.specialistUsers.map(
              (item) => item._id
            )}
            clientsIds={sessionInfo.clients.map((item) => item._id)}
          />
        )}
        {toolsState.notations && (
          <NotesVideoChat setToolsState={setToolsState} sessionId={sessionId} />
        )}
      </div>
      <ModalWindow
        isOpen={approveRecordModal}
        setIsOpen={setApproveRecordModal}
        showCloseBtn={false}
        bgColor="#242424"
        width="fit-content"
        alternativeCloseFunction={() => {
          return;
        }}
      >
        <div className={s.recordApproveModalContent}>
          <div className={s.alerCircleBlock}>
            <AlertCircle />
          </div>
          <h4>{strings.revordingConset}</h4>
          <p>{strings.revordingConsetDescription}</p>
          <div className={s.recordModalActionBlock}>
            <div
              className={s.cancelBtn}
              role="button"
              onClick={() => {
                setApproveRecordModal(false);
                approveRecord(false);
              }}
            >
              {strings.cancelBtn}
            </div>
            <StandartButton
              action={() => approveRecord(true)}
              buttonTitle={strings.iAgreeBtn}
            />
          </div>
        </div>
      </ModalWindow>
      <ModalWindow
        isOpen={initializedModals.infrotamtionModal}
        showCloseBtn={false}
        bgColor="#242424"
        width="fit-content"
        alternativeCloseFunction={() => {
          setInitializedModals((prev) => ({
            ...prev,
            infrotamtionModal: false,
          }));
          setNotifyFromUserData(undefined);
        }}
        disableBackdropClick
      >
        <div className={s.recordApproveModalContent}>
          <div className={s.alerCircleBlock}>
            <AlertCircle />
          </div>
          <h4>{strings.audioRecording}</h4>
          <p>{strings.recordEnabledInfoDescription}</p>
          <div className={s.recordModalActionBlock}>
            <StandartButton
              action={() => {
                setInitializedModals((prev) => ({
                  ...prev,
                  infrotamtionModal: false,
                }));
                setNotifyFromUserData(undefined);
              }}
              buttonTitle={strings.okBtn}
              width="150px"
            />
          </div>
        </div>
      </ModalWindow>
      <ModalWindow
        isOpen={initializedModals.informationConfirmModal}
        alternativeCloseFunction={() => {
          setInitializedModals((prev) => ({
            ...prev,
            informationConfirmModal: false,
          }));
          setNotifyFromUserData(undefined);
        }}
        showCloseBtn={false}
        bgColor="#242424"
        width="fit-content"
        disableBackdropClick
      >
        <div className={s.recordApproveModalContent}>
          <div className={s.alerCircleBlock}>
            <AlertCircle />
          </div>
          <h4>{strings.audioRecording}</h4>
          <p>{strings.recordConfirmInitializedDescription}</p>
          <div className={s.recordModalActionBlock}>
            <div
              className={s.cancelBtn}
              role="button"
              onClick={() => {
                setInitializedModals((prev) => ({
                  ...prev,
                  informationConfirmModal: false,
                }));
                setNotifyFromUserData(undefined);

                approveRecord(false);
              }}
            >
              {strings.cancelBtn}
            </div>
            <StandartButton
              action={() => {
                setInitializedModals((prev) => ({
                  ...prev,
                  informationConfirmModal: false,
                }));
                setNotifyFromUserData(undefined);
                approveRecord(true);
              }}
              buttonTitle={strings.iAgreeBtn}
            />
          </div>
        </div>
      </ModalWindow>
      <ToastContainer />
    </div>
  );
};

export default SessionVideoChat;

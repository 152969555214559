import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import "./RangeSlider.css";

function valuetext(value: number) {
  return `${value}°C`;
}

type RangeSliderProps = {
  value: number;
  setValue: (value: number) => void;
  min: number;
  max: number;
  step?: number;
  orientation?: "horizontal" | "vertical";
  height?: string;
};

const DefualtSlider = ({
  value,
  setValue,
  min,
  max,
  orientation = "horizontal",
  height = "auto",
  step = 1,
}: RangeSliderProps) => {
  return (
    <Box sx={{ width: "100%", height: height }}>
      <Slider
        getAriaLabel={() => "Minimum distance"}
        min={min}
        max={max}
        value={value}
        onChange={(_, newValue) => setValue(newValue as number)}
        valueLabelDisplay="off"
        getAriaValueText={valuetext}
        disableSwap
        orientation={orientation}
        step={step}
        sx={{
          "& .MuiSlider-thumb": {
            width: 15, // Размер ползунка
            height: 15, // Размер ползунка
            backgroundColor: "#3bb182", // Цвет ползунка
            "&:hover, &.Mui-focusVisible": {
              boxShadow: "0 0 0 8px rgba(59, 177, 130, 0.2)", // Тень при наведении
            },
          },
          "& .MuiSlider-track": {
            backgroundColor: "#3bb182", // Цвет заполненной части
          },
          "& .MuiSlider-rail": {
            backgroundColor: "#ddd", // Цвет незаполненной части
          },
        }}
      />
    </Box>
  );
};

export default DefualtSlider;

import { Dispatch, SetStateAction, useContext } from "react";
import { ReactComponent as RecordIcon } from "../../../assets/General/record-icon.svg";
import CircleAvatar from "../../../components/CircleAvatar/CircleAvatar";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import { formatDateAndTime } from "../../../utils/dateActions";
import { getTranslation } from "../../../utils/getTranslation";
import { getSessionStatus } from "../getSessionStatus";
import { HistorySession } from "../HistoryOfSessions";
import s from "./SessionCard.module.css";

type SessionCardProps = {
  session: HistorySession;
  setSelectedSession: Dispatch<SetStateAction<HistorySession | undefined>>;
};

const SessionCard = ({ session, setSelectedSession }: SessionCardProps) => {
  const { userData } = useContext(ContextProvider);

  const findUser = () => {
    const isUserSpec = session.specialists
      .map((item) => item._id)
      .includes(userData!._id);
    const user = isUserSpec ? session.clients[0] : session.specialists[0];
    return { user: user, isSpecialist: !isUserSpec };
  };

  return (
    <div className={s.container} onClick={() => setSelectedSession(session)}>
      <div className={s.userBlock}>
        {findUser().user._id && <CircleAvatar userId={findUser().user._id} />}
        <div className={s.userNameBlock}>
          <span className={s.userNameTitle}>
            {getTranslation(findUser().user.name, userData?.selectedLanguage) +
              " " +
              getTranslation(
                findUser().user.surname,
                userData?.selectedLanguage
              )}
          </span>
          <span className={s.userRole}>
            {findUser().isSpecialist
              ? strings.specialistHeaderTitle
              : strings.clientHeaderTitle}
          </span>
        </div>
      </div>
      <div
        className={
          getSessionStatus(
            session.isPayedOut,
            session.isRefunded,
            session.isCanceled,
            session.isWaitingForRefund,
            s
          ).sessionClassName
        }
      >
        {
          getSessionStatus(
            session.isPayedOut,
            session.isRefunded,
            session.isCanceled,
            session.isWaitingForRefund,
            s
          ).statusString
        }
      </div>
      <div className={s.bottomBlock}>
        <div className={s.pricedateBlock}>
          <span>{session.sessionCost / 100}$</span>{" "}
          <span>
            {formatDateAndTime(
              new Date(session.dateAndTime.date),
              userData?.selectedLanguage!
            ).formattedDate +
              " " +
              formatDateAndTime(
                new Date(session.dateAndTime.date),
                userData?.selectedLanguage!
              ).formattedTime}
          </span>
        </div>
        <div>{session.audioRecord && <RecordIcon />}</div>
      </div>
    </div>
  );
};

export default SessionCard;

import s from "./Tabs.module.css";

type TabsProps<T extends string> = {
  tabsData: { title: string; value: T }[];
  activeTab: T;
  tabClickFunc: (value: T) => void;
};

const Tabs = <T extends string>({
  tabsData,
  activeTab,
  tabClickFunc,
}: TabsProps<T>) => {
  return (
    <div className={s.container}>
      {tabsData.map((item) => (
        <div
          className={activeTab === item.value ? s.activeTab : s.notActiveTab}
          onClick={() => tabClickFunc(item.value as T)}
          key={item.value}
        >
          <span>{item.title}</span>
          <span
            className={
              activeTab === item.value
                ? s.tabActiveIndicator
                : s.indicatorHidden
            }
          ></span>
        </div>
      ))}
    </div>
  );
};

export default Tabs;

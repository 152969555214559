import { useEffect, useRef, useState } from "react";
import { ReactComponent as DownloadIcon } from "../../assets/Media/download-icon.svg";
import { ReactComponent as PauseIcon } from "../../assets/Media/pause.svg";
import { ReactComponent as PlayIcon } from "../../assets/Media/play.svg";
import { ReactComponent as VolumeIcon } from "../../assets/Media/volume-max.svg";
import { ReactComponent as DisableVolumeIcon } from "../../assets/Media/volume-xmark.svg";
import s from "./AudioPlayer.module.css";

import WaveSurfer from "wavesurfer.js";
import DefualtSlider from "../RangeSlider/DefualtSlider";

type AudioPlayerProps = {
  url: string;
  waveHeight?: number;
  waveColor?: string;
};

export default function AudioPlayer({
  url,
  waveHeight = 50,
  waveColor = "#eee",
}: AudioPlayerProps) {
  const waveformRef = useRef<null | HTMLDivElement>(null);
  const wavesurfer = useRef<WaveSurfer | null>(null);
  const [playing, setPlay] = useState(false);
  const [volume, setVolume] = useState(0.5);
  const [showVolumeControl, setShowVolumeControl] = useState(false);
  const [prevVolume, setPrevVolume] = useState(0.5);
  const [currentTime, setCurrentTime] = useState(0); // Текущее время
  const [duration, setDuration] = useState(0); // Общая длительность

  const formWaveSurferOptions = (ref: HTMLDivElement) => ({
    container: ref,
    waveColor: waveColor,
    progressColor: "#3bb182",
    cursorColor: "#3bb182",
    barWidth: 2,
    barRadius: 2,
    /*     barHeight: 1, */
    responsive: true,
    height: waveHeight,
    // If true, normalize by the maximum peak instead of 1.0.
    normalize: true,
    // Use the PeakCache to improve rendering speed of large waveforms.
    partialRender: true,
    minPxPerSec: 20,
    autoCenter: true,
    backend: "MediaElement" as "MediaElement" | "WebAudio" | undefined,
  });

  // create new WaveSurfer instance
  // On component mount and when url changes
  useEffect(() => {
    setPlay(false);

    if (!waveformRef || !waveformRef.current || !url || !wavesurfer) return;
    const options = formWaveSurferOptions(waveformRef.current);

    wavesurfer.current = WaveSurfer.create(options);

    wavesurfer.current.load(url);

    wavesurfer.current.on("ready", () => {
      if (wavesurfer.current) {
        wavesurfer.current.setVolume(volume);

        setDuration(wavesurfer.current.getDuration()); // Установить общую длительность
      }
    });

    wavesurfer.current.on("audioprocess", () => {
      if (wavesurfer.current) {
        setCurrentTime(wavesurfer.current.getCurrentTime()); // Обновить текущее время
      }
    });

    return () => {
      if (wavesurfer.current) wavesurfer.current.destroy();
    };
  }, [url, wavesurfer]);

  const handlePlayPause = () => {
    if (!wavesurfer || !wavesurfer.current) return;
    setPlay(!playing);
    wavesurfer.current.playPause();
  };

  const onVolumeChange = (value: number) => {
    if (!wavesurfer || !wavesurfer.current) return;
    setVolume(value);
    wavesurfer.current.setVolume(value);
  };

  const handleMute = () => {
    setPrevVolume(volume);
    const newVolume = volume === 0 ? prevVolume : 0;
    setVolume(newVolume);
    if (wavesurfer.current) wavesurfer.current.setVolume(newVolume);
  };

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, "0");
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "audio.mp3"; // Можно заменить на другое название
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={s.container}>
      <div onClick={handlePlayPause} className={s.playBtn}>
        {!playing ? (
          <PlayIcon height={22} width={22} className={s.playIcon} />
        ) : (
          <PauseIcon width={22} height={22} />
        )}
      </div>
      <div className={s.waveformBlock}>
        <div id="waveform" ref={waveformRef} className={s.waveform} />
        <div className={s.timeContainer}>
          <span>{formatTime(currentTime)}</span>/
          <span>{formatTime(duration)}</span>
        </div>
      </div>
      <div
        className={s.volumeContainer}
        onMouseEnter={() => setShowVolumeControl(true)}
        onMouseLeave={() => setShowVolumeControl(false)}
      >
        {volume > 0 ? (
          <VolumeIcon onClick={handleMute} className={s.volumeIcon} />
        ) : (
          <DisableVolumeIcon onClick={handleMute} className={s.volumeIcon} />
        )}
        {showVolumeControl && (
          <div
            className={s.transparentBlock}
            onMouseEnter={() => setShowVolumeControl(true)}
            onMouseLeave={() => setShowVolumeControl(false)}
          >
            <div className={s.sliderContainer}>
              <DefualtSlider
                value={volume}
                setValue={(value: number) => onVolumeChange(value)}
                max={1}
                min={0}
                step={0.01}
                height="100px"
                orientation="vertical"
              />
              {/* <input
                type="range"
                className={s.verticalSlider}
                min="0"
                max="1"
                step="0.01"
                value={volume}
                onChange={onVolumeChange}
                style={{ height: "100px", writingMode: "vertical-rl" }}
              /> */}
            </div>
          </div>
        )}
      </div>
      <button
        className={`resetButton ${s.customButton}`}
        onClick={handleDownload}
      >
        <DownloadIcon width={25} height={25} />
      </button>
    </div>
  );
}

import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import NotesAPI from "../../../api/notesApi";
import { ReactComponent as ChevronIcon } from "../../../assets/HomePage/chevron-up.svg";
import CheckIcon from "../../../assets/Notes/check.svg";
import { ReactComponent as EditIcon } from "../../../assets/Notes/edit.svg";
import { ReactComponent as PlusIcon } from "../../../assets/plus-icon.svg";
import { ReactComponent as CloseIcon } from "../../../assets/VideoSession/close.svg";
import Input from "../../../components/Input/Input";
import Loader from "../../../components/Loader/Loader";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import NoteCard from "../../../components/NoteCard/NoteCard";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import { Note } from "../../../types/TypeNotes";
import s from "./NotesVideoChat.module.css";

type TodoVideoChatProps = {
  setToolsState: Dispatch<
    SetStateAction<{
      chat: boolean;
      tasks: boolean;
      notations: boolean;
    }>
  >;
  sessionId: string;
};

const currentDate = new Date();

const day = currentDate.getDate().toString().padStart(2, "0");
const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // добавляем 1, так как месяцы в JS начинаются с 0
const year = currentDate.getFullYear().toString().slice(-2); // берем только последние две цифры года

const formattedDate = `${day}.${month}.${year}`;

const NotesVideoChat = ({ setToolsState, sessionId }: TodoVideoChatProps) => {
  const { userData } = useContext(ContextProvider);
  const token = localStorage.getItem("token");
  const [notesData, setNotesData] = useState<Note[] | null>(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectNote, setSelectNote] = useState<Note | null>(null);
  const [isEdite, setIsEdite] = useState(false);
  const [newNote, setNewNote] = useState({
    title: "",
    date: formattedDate,
    noteText: "",
  });
  const [newNoteModal, setNewNoteModal] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const containerRef = useRef<any>(null);

  const formateDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    const formattedDate = `${day}.${month}.${year}`;
    return formattedDate;
  };

  useEffect(() => {
    const makeAsync = async () => {
      if (token && userData && userData._id) {
        setLoading(true);
        const response = await NotesAPI.getUserNotes(
          token,
          userData._id,
          10,
          page
        );

        if (response.status) {
          setHasMore(response.notes.length === 10);
          setNotesData((prevNotes) => {
            if (!prevNotes) return response.notes;
            const uniqueNotes = response.notes.filter((newNote) => {
              return !prevNotes.some(
                (prevNote) => prevNote._id === newNote._id
              );
            });

            return [...prevNotes, ...uniqueNotes];
          });
          setLoading(false);
        }
      }
    };

    makeAsync();
  }, [page]);

  const selectNoteHandler = (noteData: Note) => {
    setSelectNote(noteData);
    setIsEdite(false);
  };
  const createNote = async () => {
    if (token && userData && userData._id) {
      const payload = {
        userId: userData._id,
        newNote: {
          title: newNote.title,
          description: newNote.noteText,
          date: new Date(),
          linkedSession: sessionId,
        },
      };
      const response = await NotesAPI.createNote(token, payload);

      if (response.status) {
        /*  setUpdateNoteData(updateNoteData + 1); */
        setNotesData(notesData && [...notesData, response.note]);
        setNewNoteModal(false);
        setNewNote({
          title: "",
          date: formattedDate,
          noteText: "",
        });
      }
    }
  };

  const updateNote = async () => {
    if (token && selectNote) {
      const response = await NotesAPI.updateNote(token, selectNote);

      if (response.status) {
        setNotesData((prevNotes) => {
          if (!prevNotes) return prevNotes;
          const updatedNotes = prevNotes.map((note) => {
            if (note._id === response.note._id) {
              return Object.assign({}, note, response.note);
            }
            return note;
          });

          return updatedNotes;
        });
        setIsEdite(false);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = () => {
    if (
      containerRef.current &&
      containerRef.current.scrollTop + containerRef.current.clientHeight >=
        containerRef.current.scrollHeight - 10 &&
      !loading &&
      hasMore
    ) {
      setLoading(true);
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);
  return (
    <div className={s.container}>
      <div className={s.noteHeader}>
        <div className={s.headerActionBlock} style={{ gap: "5px" }}>
          {selectNote && (
            <ChevronIcon
              className={s.chevronIcon}
              onClick={() => setSelectNote(null)}
            />
          )}
          <h3 className={s.noteTitle}>{strings.notesHeadTitle}</h3>
        </div>

        <div className={s.headerActionBlock}>
          <span
            className={s.mobileNewTodoButton}
            onClick={() => setNewNoteModal(true)}
          >
            <PlusIcon className={s.plusIcon} />
          </span>
          <CloseIcon
            className={s.closeIcon}
            onClick={() =>
              setToolsState((prev) => ({
                chat: false,
                tasks: false,
                notations: !prev.notations,
              }))
            }
          />
        </div>
      </div>

      {loading /* && (!notesData || !notesData.length) */ ? (
        <div className={s.todoBodyLoader}>
          <Loader size={100} />
        </div>
      ) : (
        <div className={s.noteBody}>
          <div
            className={
              selectNote
                ? `${s.notesList} ${s.mobileNotesListHidden}`
                : `${s.notesList} ${s.mobileNotesListVisible}`
            }
            ref={containerRef}
          >
            {notesData ? (
              notesData.map((item, index) => (
                <div
                  className={s.noteCardBlock}
                  onClick={() => selectNoteHandler(item)}
                  key={index}
                >
                  <NoteCard
                    setIsEdit={setIsEdite}
                    noteId={item._id}
                    date={item.date.toString()}
                    title={item.title}
                    key={index}
                    setNotesData={setNotesData}
                    setSelectNote={setSelectNote}
                    noteData={item}
                    selectNote={selectNote}
                    isDashboardCard={false}
                  />
                </div>
              ))
            ) : (
              <h2 className={s.noDataTitle}>{strings.noNotes}</h2>
            )}
            {loading && (
              <div></div>
              /*  <ThemeProvider theme={theme}>
              <CircularProgress size={50} color="primary" />
            </ThemeProvider> */
            )}
          </div>
          <div
            className={
              !selectNote
                ? `${s.notesContentBlock} ${s.mobileNotesContentBlockHidden}`
                : `${s.notesContentBlock} ${s.mobileNotesContentBlockVisible}`
            }
          >
            {selectNote && (
              <div className={s.noteDataBlock}>
                <div className={s.headNoteData}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {isEdite ? (
                      <input
                        className={s.updateTitleInput}
                        type="text"
                        value={selectNote.title}
                        onChange={(event) =>
                          setSelectNote((prev) => {
                            if (typeof prev === "object" && prev !== null) {
                              return { ...prev, title: event.target.value };
                            }
                            return prev;
                          })
                        }
                      />
                    ) : (
                      <span>{selectNote.title} </span>
                    )}
                    <span>{formateDate(new Date(selectNote.date))}</span>
                  </div>
                  <div className={s.editButtonBlock}>
                    {isEdite ? (
                      <img
                        src={CheckIcon}
                        alt=""
                        className={s.saveNoteButton}
                        onClick={updateNote}
                      />
                    ) : (
                      <EditIcon
                        className={s.editIcon}
                        onClick={() => setIsEdite(true)}
                      />
                    )}
                  </div>
                </div>
                <div className={s.noteTextData}>
                  <textarea
                    readOnly={!isEdite}
                    name=""
                    id=""
                    value={selectNote.description}
                    className={isEdite ? s.textAreaNewNote : s.textArea}
                    onChange={(event) =>
                      setSelectNote((prev) => {
                        if (typeof prev === "object" && prev !== null) {
                          return { ...prev, description: event.target.value };
                        }
                        return prev;
                      })
                    }
                  ></textarea>
                  {/*  {isEdite && <ScrapIcon className={s.scrapIcon} />} */}
                </div>
              </div>
            )}

            <ModalWindow
              isOpen={newNoteModal}
              setIsOpen={setNewNoteModal}
              width="fit-content"
            >
              <div className={s.newNoteBlock}>
                <span className={s.newNoteTitle}>
                  {strings.newNoteHeadTitle}
                </span>
                <div>
                  <span>{strings.newNoteTitleLable}</span>
                  <Input
                    inputValue={newNote.title}
                    onChangeInput={(value: any) =>
                      setNewNote((prev) => ({ ...prev, title: value }))
                    }
                    isVisible
                  />
                </div>
                <div>
                  <span>{strings.newNoteDescriptionLable}</span>
                  <textarea
                    name=""
                    id=""
                    cols={30}
                    rows={10}
                    value={newNote.noteText}
                    className={s.textAreaNewNote}
                    onChange={(e) =>
                      setNewNote((prev) => ({
                        ...prev,
                        noteText: e.target.value,
                      }))
                    }
                  ></textarea>
                </div>
                <div className={s.createNoteBlock}>
                  <StandartButton
                    action={createNote}
                    buttonTitle={strings.newNoteCreateBtn}
                    width="200px"
                    fontSize="20px"
                    disabled={
                      newNote.noteText.replace(/\s+/g, "") === "" ||
                      newNote.title.replace(/\s+/g, "") === ""
                    }
                    tooltipTitle={
                      newNote.noteText.replace(/\s+/g, "") === "" ||
                      newNote.title.replace(/\s+/g, "") === ""
                        ? strings.newNoteHeadTitle
                        : ""
                    }
                  />
                </div>
              </div>
            </ModalWindow>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotesVideoChat;

import { CircularProgress, ThemeProvider } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import TodosAPI from "../../api/todoApi";
import { ReactComponent as CloseIcon } from "../../assets/HomePage/close.svg";
import { ReactComponent as PlusIcon } from "../../assets/plus-icon.svg";
import { ReactComponent as ArrowLeft } from "../../assets/Todo/arrow-left.svg";
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal";
import Input from "../../components/Input/Input";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import StandartButton from "../../components/StandartButton/StandartButton";
import TodoListTable from "../../components/Table/TodoListTable";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import { Todo, TodoList, TodoStatus } from "../../types/TypeToDo";
import { theme } from "../../utils/theme";
import { SuccesNotify } from "../../utils/toaster";
import TaskCard from "./TaskCard/TaskCard";
import s from "./ToDo.module.css";

type RowsType = {
  _id: string;
  title: string;
  for: {
    _id: string;
    name: { text: string; language: string }[];
    surname: { text: string; language: string }[];
  };
  createdBy: {
    _id: string;
    name: { text: string; language: string }[];
    surname: { text: string; language: string }[];
  };
  date: Date | string;
};

const ToDo = () => {
  const { userData } = useContext(ContextProvider);
  const token = localStorage.getItem("token");
  const [selectedTodoList, setSelectedTodoList] = useState<TodoList | null>(
    null
  );
  const [actionLoaders, setActionLoaders] = useState({
    createTodoListLoader: false,
  });
  const [todosData, setTodosData] = useState<TodoList[] | null>(null);
  const updateTodoData = 0;
  const [newTodoModal, setNewTodoModal] = useState(false);
  const [todoListTitle, setTodoListTitle] = useState("");
  const [newTaskModal, setNewTaskModal] = useState(false);
  const [newTaskValues, setNewTaskValues] = useState({
    title: "",
    description: "",
  });
  const [paganation, setPaganation] = useState({
    page: 0,
    rowPerPage: 5,
    total: 0,
  });
  const [todoListTableData, setTodoListTableData] = useState<RowsType[] | null>(
    null
  );
  const [deleteTodoListModal, setDeleteTodoListModal] = useState(false);
  const [idForRemove, setIdForRemove] = useState<string | null>(null);
  const [isLoader, setIsLoader] = useState(false);
  const [updateListTriger, setUpdateListTrigger] = useState(1);
  const [sortData, setSortData] = useState<{
    sortVector: 1 | -1 | undefined;
    sortField: "date" | "title";
  }>({
    sortVector: -1,
    sortField: "date",
  });

  useEffect(() => {
    if (todosData && todosData.length) {
      const tableTodoListData = todosData.map((item) => ({
        _id: item._id,
        title: item.title,
        for: item.for,
        createdBy: item.createdBy,
        date: item.date!,
      }));
      setTodoListTableData(tableTodoListData);
    }
  }, [todosData]);

  const selectTodoListHandler = (todoListId: string) => {
    if (!todosData || !todosData.length) return;
    const selectTodolist = todosData.filter((item) => item._id === todoListId);
    if (!selectTodolist) return;
    setSelectedTodoList(selectTodolist[0]);
  };

  const createTodo = async () => {
    if (token && userData && userData._id && selectedTodoList?._id) {
      const payload = {
        newTodo: {
          createdBy: userData._id,
          for: userData._id,
          status: TodoStatus.NEW,
          title: newTaskValues.title,
          description: newTaskValues.description,
          date: new Date(),
        },
        todoListId: selectedTodoList?._id,
      };

      const response = await TodosAPI.createTodo(token, payload);

      if (response.status) {
        setNewTaskValues({ title: "", description: "" });

        setSelectedTodoList((prevTodoList) => {
          if (prevTodoList && response.todo) {
            const updatedTodos = [...prevTodoList.todos, response.todo];
            return {
              ...prevTodoList,
              todos: updatedTodos,
            };
          }
          return prevTodoList;
        });
        setTodosData((prevTodosData) => {
          if (prevTodosData && response.todo) {
            const updatedTodosData = prevTodosData.map((todoList) => {
              if (todoList._id === selectedTodoList?._id) {
                return {
                  ...todoList,
                  todos: [...todoList.todos, response.todo!],
                };
              }
              return todoList;
            });
            return updatedTodosData;
          }
          return prevTodosData;
        });
        setNewTaskModal(false);
      }
    }
  };

  const updateTodo = async (index: number) => {
    if (token && selectedTodoList) {
      const todoToUpdate = selectedTodoList.todos[index];
      const correspondingTodo = todosData?.find(
        (todo) => todo._id === selectedTodoList._id
      );
      if (!correspondingTodo) return;

      const correspondingTodoIndex = correspondingTodo.todos.findIndex(
        (todo) => todo._id === todoToUpdate._id
      );
      if (correspondingTodoIndex === -1) return;

      const correspondingTodoItem =
        correspondingTodo.todos[correspondingTodoIndex];

      if (
        todoToUpdate.title !== correspondingTodoItem.title ||
        todoToUpdate.description !== correspondingTodoItem.description
      ) {
        const response = await TodosAPI.updateTodo(
          token,
          todoToUpdate,
          selectedTodoList._id
        );

        if (response.status) {
          const updatedTodosData = [...todosData!];
          updatedTodosData[todosData!.indexOf(correspondingTodo)] = {
            ...correspondingTodo,
            todos: correspondingTodo.todos.map((todo) => {
              if (todo._id === response.todo!._id) {
                return response.todo!;
              }
              return todo;
            }),
          };
          setTodosData(updatedTodosData);
        }
      }
    }
  };
  const createTodoList = async () => {
    if (userData && userData._id && token) {
      const payload = {
        newTodoList: {
          createdBy: userData._id,
          for: userData._id,
          title: todoListTitle,
          date: new Date(),
        },
      };
      setActionLoaders((prev) => ({ ...prev, createTodoListLoader: true }));
      const response = await TodosAPI.createTodoList(token, payload);
      setActionLoaders((prev) => ({ ...prev, createTodoListLoader: false }));
      if (response.status) {
        setTodoListTitle("");
        setUpdateListTrigger((prev) => prev + 1);
        SuccesNotify(strings.todoListCreateNotify);
        setNewTodoModal(false);
      }
    }
  };

  useEffect(() => {
    const makeAsync = async () => {
      if (token && userData && userData._id) {
        setIsLoader(true);
        const response = await TodosAPI.getUserTodos(
          token,
          userData._id,
          paganation.rowPerPage,
          paganation.page,
          sortData.sortVector,
          sortData.sortField
        );

        setIsLoader(false);
        if (response.status) {
          setPaganation((prev) => ({ ...prev, total: response.total }));
          setTodosData(response.todoLists);
        }
      }
    };
    makeAsync();
  }, [
    updateTodoData,
    paganation.page,
    paganation.rowPerPage,
    sortData,
    updateListTriger,
  ]);

  const changeNewTodoListTitle = (value: string) => {
    if (value.length >= 100) return;
    setTodoListTitle(value);
  };

  const onChangeNewTaskTitle = (value: string) => {
    if (value.length >= 100) return;
    setNewTaskValues((prev) => ({ ...prev, title: value }));
  };

  const handleTodoChange = (
    value: string,
    index: number,
    keyObj: keyof Todo
  ) => {
    setSelectedTodoList((prevTodoList) => {
      if (prevTodoList) {
        const updatedTodos = [...prevTodoList.todos];
        updatedTodos[index] = {
          ...updatedTodos[index],
          [keyObj]: value,
        };

        return {
          ...prevTodoList,
          todos: updatedTodos,
        };
      }
      return prevTodoList;
    });
  };

  const deleteToDoList = async () => {
    if (!token || !idForRemove) return;
    const response = await TodosAPI.deleteTodoList(token, idForRemove);
    if (response.status) {
      setSelectedTodoList(null);
      setIdForRemove(null);
      setDeleteTodoListModal(false);
      setTodosData((prevTodoData) => {
        if (prevTodoData) {
          const updatedTodoListArray: TodoList[] = prevTodoData.filter(
            (todo) => todo._id !== idForRemove
          );
          return updatedTodoListArray;
        }
        return null;
      });
    }
  };

  return (
    <div className={s.container}>
      <h1 className={s.headTitle}>{strings.toDoListHeadTitle}</h1>
      <div className={s.headBlock}>
        <div className={s.legendBlock}>
          <div className={s.legendElement}>
            <span className={s.newTodoLegend}></span>
            <span>{strings.newStatusTask}</span>
          </div>
          <div className={s.legendElement}>
            <span className={s.progressLegend}></span>
            <span>{strings.inProgressStatusTask}</span>
          </div>
          <div className={s.legendElement}>
            <span className={s.urgentLegend}></span>
            <span>{strings.urgentStatusTask}</span>
          </div>
          <div className={s.legendElement}>
            <span className={s.completeLegend}></span>
            <span>{strings.completeStatusTask}</span>
          </div>
        </div>
        <span
          className={s.mobileNewTodoButton}
          onClick={() => setNewTodoModal(true)}
        >
          <PlusIcon className={s.plusIcon} />
        </span>
      </div>

      <div className={s.mainContent}>
        {isLoader ? (
          <div className={s.loaderContainer}>
            <ThemeProvider theme={theme}>
              <CircularProgress size={150} color="primary" />
            </ThemeProvider>
          </div>
        ) : (
          todoListTableData && (
            <>
              <div
                className={
                  selectedTodoList
                    ? `${s.tableBlock} ${s.mobileTableBlockHidden}`
                    : `${s.tableBlock} ${s.mobileTableBlockVisible}`
                }
              >
                <TodoListTable
                  count={paganation.total}
                  page={paganation.page}
                  rows={todoListTableData}
                  rowsPerPage={paganation.rowPerPage}
                  setPagination={setPaganation}
                  selectTodoListHandler={selectTodoListHandler}
                  setModalConfirm={setDeleteTodoListModal}
                  setIdForRemove={setIdForRemove}
                  setTodosData={setTodosData}
                  setSortData={setSortData}
                  sortData={sortData}
                  selectedTodoListId={selectedTodoList && selectedTodoList._id}
                />
              </div>
              <div
                className={
                  selectedTodoList
                    ? `${s.todolistInfoBlock} ${s.todolistInfoBlockActive} ${s.mobileTodolistVisible}`
                    : `${s.todolistInfoBlock} ${s.mobileTodolistHidden}`
                }
              >
                <div className={s.buttonsBlock}>
                  <div
                    className={s.backButton}
                    role="buttom"
                    onClick={() => setSelectedTodoList(null)}
                  >
                    <ArrowLeft />
                    <span className={s.backTitle}>
                      {strings.myProgramBackBtn}
                    </span>
                  </div>
                  <div className={s.newTaskBlock}>
                    <StandartButton
                      action={() => setNewTaskModal(true)}
                      buttonTitle={strings.newTaskBtn}
                      height="35px"
                      fontSize="14px"
                      width="fit-content"
                      disabled={selectedTodoList ? false : true}
                    />
                  </div>
                </div>
                <div className={s.tasksList}>
                  {selectedTodoList?.todos && selectedTodoList.todos.length ? (
                    selectedTodoList?.todos.map((item, index) => (
                      <TaskCard
                        description={item.description}
                        title={item.title}
                        status={item.status.status}
                        key={index}
                        index={index}
                        onChangeTodo={handleTodoChange}
                        updateTodo={updateTodo}
                        taskId={item._id}
                        setSelectedTodoList={setSelectedTodoList}
                        selectedTodoList={selectedTodoList}
                        setTodosData={setTodosData}
                        createdDate={item.date}
                      />
                    ))
                  ) : (
                    <div className={s.noDataTitleBlock}>
                      <h2 className={s.noDataTitle}>{strings.noTaskData}</h2>
                    </div>
                  )}
                </div>
              </div>
            </>
          )
        )}
      </div>
      <ModalWindow
        isOpen={newTaskModal}
        setIsOpen={setNewTaskModal}
        width="fit-content"
        padding="0"
        position="right"
        showCloseBtn={false}
      >
        <div className={s.newTaskModalBlock}>
          <div className={s.newTodoListHeader}>
            <span>{strings.newTaskModalTitle}</span>
            <CloseIcon
              className={s.closeIcon}
              onClick={() => setNewTaskModal(false)}
            />
          </div>
          <div className={s.newTaskInputBlock}>
            <span>{strings.newTaskTitleLable}</span>
            <Input
              inputValue={newTaskValues.title}
              onChangeInput={onChangeNewTaskTitle}
              isVisible
              required
            />
          </div>
          <div className={s.newTaskInputBlock}>
            <span>{strings.newTaskDescriptionLable}</span>
            <textarea
              name=""
              id=""
              cols={30}
              rows={10}
              value={newTaskValues.description}
              className={s.textAreaNewTask}
              onChange={(event) =>
                setNewTaskValues((prev) => ({
                  ...prev,
                  description: event.target.value,
                }))
              }
            ></textarea>
          </div>

          <div className={s.doneBtnBlock}>
            <StandartButton
              action={createTodo}
              buttonTitle={strings.doneBtn}
              width="fit-content"
              fontSize="14px"
              disabled={
                newTaskValues.description.length && newTaskValues.title.length
                  ? false
                  : true
              }
              tooltipTitle={strings.createTaskBtnTooltip}
            />
          </div>
        </div>
      </ModalWindow>
      <ModalWindow
        isOpen={newTodoModal}
        setIsOpen={setNewTodoModal}
        width="fit-content"
        position="right"
        padding="0"
        showCloseBtn={false}
      >
        <div className={s.newTodoModal}>
          <div className={s.newTodoListHeader}>
            <span>{strings.newTodoListTitle}</span>
            <CloseIcon
              className={s.closeIcon}
              onClick={() => setNewTodoModal(false)}
            />
          </div>
          <div className={s.newListModalContent}>
            <span className={s.newListTitle}>
              {strings.titleNewTodoStatusTask}
            </span>
            <Input
              inputValue={todoListTitle}
              onChangeInput={changeNewTodoListTitle}
              isVisible
              required
              placeholder={strings.newTaskTitleLable}
            />

            {actionLoaders.createTodoListLoader ? (
              <div className={s.actionModalBlock}>
                <ThemeProvider theme={theme}>
                  <CircularProgress size={45} color="primary" />
                </ThemeProvider>
              </div>
            ) : (
              <div className={s.actionModalBlock}>
                <StandartButton
                  action={createTodoList}
                  buttonTitle={strings.doneBtn}
                  width="fit-content"
                  fontSize="20px"
                  disabled={todoListTitle.replace(/\s+/g, "").length < 1}
                  tooltipTitle={
                    todoListTitle.replace(/\s+/g, "").length < 1
                      ? strings.titleNewTodoStatusTask
                      : ""
                  }
                />
              </div>
            )}
          </div>
        </div>
      </ModalWindow>
      <ConfirmationModal
        modalVisible={deleteTodoListModal}
        setModalVisible={setDeleteTodoListModal}
        confirmFunc={deleteToDoList}
        confirmTitle={strings.confirmDeleteTodoListTitle}
      ></ConfirmationModal>
    </div>
  );
};

export default ToDo;

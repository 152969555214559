import { Fade, Paper, Popper } from "@mui/material";
import React from "react";

type Placement =
  | "auto-end"
  | "auto-start"
  | "auto"
  | "bottom-end"
  | "bottom-start"
  | "bottom"
  | "left-end"
  | "left-start"
  | "left"
  | "right-end"
  | "right-start"
  | "right"
  | "top-end"
  | "top-start"
  | "top";

interface PopperComponentProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  content: React.ReactNode;
  placement?: Placement;
  arrow?: boolean;
}

const PopperComponent: React.FC<PopperComponentProps> = ({
  open,
  anchorEl,
  content,
  placement = "top",
  arrow = true,
}) => {
  const modifiers = [
    {
      name: "arrow",
      enabled: arrow,
    },
  ];

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      transition
      placement={placement}
      sx={{ zIndex: 9999 }}
      modifiers={modifiers}
    >
      {({ TransitionProps }) => (
        <Fade
          {...TransitionProps}
          timeout={350}
          style={{ padding: 0, backgroundColor: "transparent" }}
        >
          <Paper sx={{ padding: 0 }}>
            <div>
              {content} <div data-popper-arrow></div>
            </div>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
};

export default PopperComponent;

import { Skeleton } from "@mui/material";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import SessionsApi from "../../../api/sessionApi";
import { ReactComponent as CloseIcon } from "../../../assets/General/close.svg";
import { ReactComponent as TimerIcon } from "../../../assets/HistoryOfSessions/timer.svg";
import AudioPlayer from "../../../components/AudioPlayer/AudioPlayer";
import MuiSwitch from "../../../components/MuiSwitch/MuiSwitch";
import StandartButton from "../../../components/StandartButton/StandartButton";
import { translations } from "../../../constans/languagesList";
import { ContextProvider } from "../../../contextProvider";
import strings from "../../../localization";
import { setCommentModalVisible } from "../../../redux-toolkit/slices/modalsSlice";
import { formatDateAndTime } from "../../../utils/dateActions";
import { fetchAudioStream } from "../../../utils/getAudioStream";
import { getTranslation } from "../../../utils/getTranslation";
import { getSessionStatus } from "../getSessionStatus";
import { HistorySession } from "../HistoryOfSessions";
import s from "./SessionInfo.module.css";

type SessionInfoProps = {
  selectedSession: HistorySession;
  closeInfoFunction?: () => void;
  setSelectedSession: Dispatch<SetStateAction<HistorySession | undefined>>;
  setSessionsData: Dispatch<SetStateAction<HistorySession[] | undefined>>;
};

const SessionInfo = ({
  selectedSession,
  closeInfoFunction,
  setSessionsData,
  setSelectedSession,
}: SessionInfoProps) => {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const { userData } = useContext(ContextProvider);
  const [audioUrl, setAudioUrl] = useState<string | undefined>();
  const [canLeaveComment, setCanLeaveComment] = useState(false);

  useEffect(() => {
    (async () => {
      if (!token || !selectedSession) return;
      const audioStream = await fetchAudioStream(token, selectedSession._id);
      setAudioUrl(audioStream);
    })();
  }, [selectedSession]);

  const formatMilliseconds = (milliseconds: number): string => {
    const { hours, minutes, seconds } =
      //@ts-expect-error
      translations[userData!.selectedLanguage!] || translations["en"];

    const secs = Math.floor((milliseconds / 1000) % 60);
    const mins = Math.floor((milliseconds / (1000 * 60)) % 60);
    const hrs = Math.floor(milliseconds / (1000 * 60 * 60));

    let result = "";

    if (hrs > 0) {
      result += `${hrs}${hours} `;
    }

    if (mins > 0 || hrs > 0) {
      result += `${mins}${minutes} `;
    }

    result += `${secs}${seconds}`;

    return result.trim();
  };

  const getMinimumTime = (arr: number[]): string => {
    const minMilliseconds = Math.min(...arr);
    return formatMilliseconds(minMilliseconds);
  };
  const tagsSteps = [strings.direction, strings.topic, strings.result];

  const checkIfCanLeaveComment = async () => {
    if (token && selectedSession && !selectedSession.isCanceled) {
      const commentLeaveResponse = await SessionsApi.checkIfCanLeaveComment(
        token,
        selectedSession._id
      );
      setCanLeaveComment(commentLeaveResponse.status);
    }
  };

  useEffect(() => {
    checkIfCanLeaveComment();
  }, [selectedSession]);

  const allowAudioRecordForSpecialist = async (value: boolean) => {
    if (!token) return;
    setSelectedSession({ ...selectedSession, recordForSpecAllowed: value });
    setSessionsData((prev) =>
      prev
        ? prev!.map((item) => {
            if (item._id === selectedSession._id) {
              return { ...item, recordForSpecAllowed: value };
            }
            return item;
          })
        : prev
    );
    const response = await SessionsApi.allowAudioRecordForSpecialist(
      token,
      selectedSession._id,
      value
    );

    if (!response.status) {
      setSelectedSession({ ...selectedSession, recordForSpecAllowed: !value });
      setSessionsData((prev) =>
        prev
          ? prev!.map((item) => {
              if (item._id === selectedSession._id) {
                return { ...item, recordForSpecAllowed: !value };
              }
              return item;
            })
          : prev
      );
    }
  };

  function isDateInPast(dateISO: string): boolean {
    return new Date(dateISO) < new Date();
  }
  /* 
  const [audio, setAudio] = useState<File | null>(null);
  const handleAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const file = event.target.files[0];
      if (file) {
        setAudio(file);
      }
    }
  };

  const uploadAudio = async () => {
    console.log(audio);

    if (!audio) return;
    const formData = new FormData();
    formData.append("sessionId", "6790c8e390a866b8643996f5");
    formData.append("file", audio);
    const response = await RecordingsApi.testSaveAudioToSession(formData);
    console.log(response);
  }; */
  return (
    <div className={s.container}>
      {/*  <label>
        <span className={s.updateAvatarBtn} role="button">
          upload mp3
        </span>
        <input
          style={{ display: "none" }}
          type="file"
          onChange={(e) => handleAvatarChange(e)}
        />
      </label>
      <button onClick={uploadAudio}>save mp3</button> */}
      <div className={s.headerInfo}>
        <div className={s.leftHeaderBlock}>
          <div
            className={
              getSessionStatus(
                selectedSession.isPayedOut,
                selectedSession.isRefunded,
                selectedSession.isCanceled,
                selectedSession.isWaitingForRefund,
                s
              ).sessionClassName
            }
          >
            {
              getSessionStatus(
                selectedSession.isPayedOut,
                selectedSession.isRefunded,
                selectedSession.isCanceled,
                selectedSession.isWaitingForRefund,
                s
              ).statusString
            }
          </div>
          <div className={s.dateInfo}>
            {formatDateAndTime(
              new Date(selectedSession.dateAndTime.date),
              userData?.selectedLanguage!
            ).formattedDate +
              " " +
              formatDateAndTime(
                new Date(selectedSession.dateAndTime.date),
                userData?.selectedLanguage!
              ).formattedTime}
          </div>
        </div>
        <div className={s.row20gap}>
          {canLeaveComment &&
            selectedSession.clients[0]._id === userData?._id && (
              <StandartButton
                action={() => dispatch(setCommentModalVisible(true))}
                buttonTitle={strings.leaveFeedback}
                height="35px"
                fontSize="14px"
                width="fit-content"
              />
            )}
          <div onClick={closeInfoFunction}>
            {closeInfoFunction && <CloseIcon className={s.closeIcon} />}
          </div>
        </div>
      </div>
      {selectedSession.title && selectedSession.title.length > 0 && (
        <h3>
          {getTranslation(selectedSession.title, userData?.selectedLanguage)}
        </h3>
      )}
      <div className={s.spaceBeetween}>
        <div className={s.row20gap}>
          <span className={s.infoLable}>{strings.sessionInfoDuration}</span>
          <span className={s.tagValue}>
            <TimerIcon />
            {selectedSession.visitDurations &&
            selectedSession.visitDurations.length
              ? getMinimumTime(
                  selectedSession.visitDurations.map(
                    (item) => item.totalSessionTime
                  )
                )
              : "00:00"}
          </span>
        </div>
        <div className={s.infoLable}>
          {strings.price + " " + selectedSession.sessionCost / 100}$
        </div>
      </div>
      <div>
        {selectedSession.tags && selectedSession.tags.length ? (
          <div className={s.tagsBlock}>
            {selectedSession.tags.map((item, index) => (
              <div key={index} className={s.columnBlock}>
                <span className={s.infoLable}>
                  {tagsSteps[item.level - 1]}:
                </span>
                <span className={s.tagValue}>
                  {
                    item.labels.find(
                      (el) => el.language === userData?.selectedLanguage
                    )?.text
                  }
                </span>
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {selectedSession.subject && selectedSession.subject.length ? (
        <div className={s.columnBlock}>
          <span className={s.infoLable}>{strings.infoModalComment}</span>
          <span>{selectedSession.subject}</span>
        </div>
      ) : null}
      {selectedSession.packageRef && (
        <div className={s.packageBlock}>
          <span className={s.blockLable}>{strings.packageSessionInfo}</span>
          <div className={s.rowInfoElement}>
            <span className={s.infoRowValue}>
              {selectedSession.packageRef.title.find(
                (el) => el.language === userData?.selectedLanguage
              )?.text ??
                selectedSession.packageRef.title[0].text ??
                "-"}
            </span>
          </div>
        </div>
      )}
      {selectedSession.project && (
        <div className={s.packageBlock}>
          <span className={s.blockLable}>{strings.sessionInfoProject}</span>
          <div className={s.rowInfoElement}>
            <span className={s.infoRowValue}>
              {selectedSession.project?.title ?? "-"}
            </span>
          </div>
          <div>
            <span className={s.infoRowValue}>
              {selectedSession.project?.description ?? "-"}
            </span>
          </div>
        </div>
      )}
      {(selectedSession.audioRecord || selectedSession.recordingAvailableAt) &&
        (!selectedSession.specialists
          .map((item) => item._id)
          .includes(userData!._id) ||
          selectedSession.recordForSpecAllowed) && (
          <div className={s.audioRecordBlock}>
            <div className={s.audioRecordTitleBlock}>
              <span className={s.blockLable}>{strings.audioRecordTitle}</span>
              <span className={s.grayText}>{strings.recordingEnsures}</span>
            </div>
            {selectedSession.recordingAvailableAt &&
            !isDateInPast(selectedSession.recordingAvailableAt) &&
            !selectedSession.audioRecord ? (
              <div>
                <div>{strings.audioRecorWillBeAvailable}</div>
                <div>
                  {formatDateAndTime(
                    new Date(selectedSession.recordingAvailableAt),
                    userData?.selectedLanguage!
                  ).formattedDate +
                    " " +
                    formatDateAndTime(
                      new Date(selectedSession.recordingAvailableAt),
                      userData?.selectedLanguage!
                    ).formattedTime}
                </div>
              </div>
            ) : audioUrl ? (
              <AudioPlayer url={audioUrl} waveColor="#c2c2c2" />
            ) : (
              <Skeleton variant="rectangular" width={"100%"} height={50} />
            )}
          </div>
        )}
      {selectedSession.audioRecord &&
        !selectedSession.specialists
          .map((item) => item._id)
          .includes(userData!._id) && (
          <div className={s.grantRecordBlock}>
            <div className={s.audioRecordTitleBlock}>
              <span className={s.blockLable}>
                {strings.grantSpecialistRecordTitle}
              </span>
              <span className={s.grayText}>
                {strings.grantSpecialistRecordDescription}
              </span>
            </div>
            <MuiSwitch
              checked={
                selectedSession.recordForSpecAllowed === undefined
                  ? false
                  : selectedSession.recordForSpecAllowed
              }
              setChecked={(value) => allowAudioRecordForSpecialist(value)}
            />
          </div>
        )}
    </div>
  );
};

export default SessionInfo;

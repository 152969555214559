import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import VerificationInput from "react-verification-input";
import UsersApi from "../../api/usersApi";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import Notify, { SuccesNotify } from "../../utils/toaster";
import Loader from "../Loader/Loader";
import StandartButton from "../StandartButton/StandartButton";
import TimerComponent from "../TimerComponent/TimerComponent";
import s from "./ConfirmEmail.module.css";

type ConfirmEmailProps = {
  setModal: Dispatch<SetStateAction<boolean>>;
  verificationType: "email" | "phoneNumber";
  codeLength: number;
};

const ConfirmEmail = ({
  setModal,
  verificationType,
  codeLength,
}: ConfirmEmailProps) => {
  const token = localStorage.getItem("token");
  const { userData, setUserData } = useContext(ContextProvider);
  const [confirmCode, setConfirmCode] = useState("");
  const initialLastResendDate =
    verificationType === "email"
      ? userData?.resendEmailConfCodeDate
      : userData?.resendConfCodeDate;
  const [lastResendDate, setLastResendDate] = useState<Date | undefined>(
    initialLastResendDate
      ? new Date(initialLastResendDate.replace("Z", ""))
      : undefined
  );
  const [timeOff, setTimeOff] = useState(false);
  const [actionLoaders, setActionLoaders] = useState({ resendLoader: false });
  const timeLimit: number = 5 * 60 * 1000;

  const sendCodeToEmail = async () => {
    if (!token || !userData) return;
    setActionLoaders((prev) => ({ ...prev, resendLoader: true }));
    const response = await UsersApi.sendEmailConfirmation(token, {
      userId: userData._id,
    });
    setActionLoaders((prev) => ({ ...prev, resendLoader: false }));
    if (response.status && response.resendEmailConfCodeDate) {
      setLastResendDate(
        new Date(response.resendEmailConfCodeDate.replace("Z", ""))
      );
      setUserData((prev) => ({
        ...prev!,
        resendEmailConfCodeDate: response.resendEmailConfCodeDate,
      }));
      setTimeOff(false);
    }
    if (!response.status && response.message) {
      Notify(response.message);
    }
  };

  const sendCodeToPhone = async () => {
    if (!token || !userData) return;
    setActionLoaders((prev) => ({ ...prev, resendLoader: true }));
    const response = await UsersApi.sendPhoneConfirmation(token);
    setActionLoaders((prev) => ({ ...prev, resendLoader: false }));
    if (
      response.status &&
      response.userData &&
      response.userData.resendConfCodeDate
    ) {
      setLastResendDate(
        new Date(response.userData.resendConfCodeDate.replace("Z", ""))
      );
      setUserData((prev) => ({
        ...prev!,
        resendEmailConfCodeDate: response.userData.resendConfCodeDate,
      }));
      setTimeOff(false);
    }
    if (!response.status && response.message) {
      Notify(response.message);
    }
  };
  useEffect(() => {
    if (lastResendDate) return;
    if (verificationType === "email") {
      sendCodeToEmail();
      return;
    }
    sendCodeToPhone();
  }, []);

  const sendCode = async () => {
    if (!token || !userData) return;
    const payload = {
      userId: userData._id,
      code: Number(confirmCode),
    };
    const response = await UsersApi.confirmEmailWithCode(token, payload);
    if (response.status) {
      SuccesNotify(strings.verifyEmailSucces);
      setUserData((prev) => ({ ...prev!, isEmailConfirmed: true }));
      setModal(false);
    }
    if (!response.status && response.message) {
      Notify(response.message);
    }
  };

  const sendTwillioCode = async () => {
    if (!token || !userData) return;
    const response = await UsersApi.confirmPhoneNumberWithCode(
      token,
      Number(confirmCode)
    );
    if (response.status) {
      SuccesNotify(strings.verifyPhoneSucces);
      setUserData((prev) => ({ ...prev!, isPhoneNumberConfirmed: true }));
      setModal(false);
    }
    if (!response.status && response.message) {
      Notify(response.message);
    }
  };

  return (
    <div className={s.container}>
      <h2>
        {verificationType === "email"
          ? strings.pleaseCheckEmail
          : strings.pleaseCheckYourPhone}
      </h2>
      <span>
        {strings.weSentEmailCode +
          " " +
          (verificationType === "email"
            ? userData?.email
            : userData?.phoneNumber)}
      </span>
      <VerificationInput
        length={codeLength}
        value={confirmCode}
        onChange={(value) => setConfirmCode(value)}
        validChars="0-9"
        classNames={{
          container: s.inputContainer,
          character: s.character,
          characterInactive: s.characterInactive,
          characterSelected: s.characterSelected,
          characterFilled: s.characterFilled,
        }}
      />
      <StandartButton
        action={verificationType === "email" ? sendCode : sendTwillioCode}
        buttonTitle={strings.verifyEmailBtn}
        width={"250px"}
      />
      {lastResendDate && !timeOff && (
        <div className={s.resendBlock}>
          <span>{strings.youCanResend}</span>
          <TimerComponent
            date={lastResendDate}
            timeLimit={timeLimit}
            timeOffFunc={(value) => setTimeOff(value)}
            renderTimerFunc={(value) => {
              return (
                <span className={s.remaingTime}>
                  {value.minutes.toString().padStart(2, "0")} :{" "}
                  {value.seconds.toString().padStart(2, "0")}
                </span>
              );
            }}
          />
        </div>
      )}
      {lastResendDate && timeOff && (
        <div className={s.resendBlock}>
          <span>{strings.dintReciveCode}</span>
          {actionLoaders.resendLoader ? (
            <Loader size={30} />
          ) : (
            <span
              role="button"
              onClick={
                verificationType === "email" ? sendCodeToEmail : sendCodeToPhone
              }
            >
              {strings.resendBtn}
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default ConfirmEmail;

import { CircularProgress, ThemeProvider } from "@mui/material";
import dayjs from "dayjs";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SessionsApi from "../../api/sessionApi";
import { ReactComponent as ChatIcon } from "../../assets/Header/chatIcon.svg";
import { ReactComponent as CloseIcon } from "../../assets/HomePage/close.svg";
import { ReactComponent as InfoIcon } from "../../assets/HomePage/info-icon.svg";
import { ContextProvider } from "../../contextProvider";
import strings from "../../localization";
import { SessionsData } from "../../pages/Initial/Initial";
import { setSelectedInitialCalendarDate } from "../../redux-toolkit/slices/InitialCalendarSlice";
import { SessionPackage } from "../../types/TypeSession";
import { SpecialistTag } from "../../types/TypeSpecialist";
import {
  checkTimeRemaining,
  formatDateTimeWithOutTimezone,
} from "../../utils/general";
import { theme } from "../../utils/theme";
import { createDate } from "../../utils/timeActions";
import { SuccesNotify } from "../../utils/toaster";
import CircleAvatar from "../CircleAvatar/CircleAvatar";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import ModalWindow from "../ModalWindow/ModalWindow";
import NavigateToProfile from "../NavigateToProfile/NavigateToProfile";
import StandartButton from "../StandartButton/StandartButton";
import s from "./NextSessionCard.module.css";

type SessionCardProps = {
  userId: string;
  date: string;
  time: string;
  sessionTheme: string;
  bookingId: string;
  creationDate?: Date;
  bookedSessionId: string | undefined;
  duration: number;
  isBooking: boolean;
  bookSessionsData: null | SessionsData[];
  specialistPay: number;
  setSessionsData?: Dispatch<SetStateAction<null | SessionsData[]>>;
  setBookSessionsData?: Dispatch<SetStateAction<null | SessionsData[]>>;
  isMySessionRequest: boolean;
  project?: { _id: string; title: string };
  sessionPackage?: SessionPackage;
  specialistId?: string;
  clientData: { name: string; surname: string; _id: string };
  specialistData: { name: string; surname: string; _id: string };
  subject?: string;
  tags?: SpecialistTag[];
};

const NextSessionCard = ({
  userId,
  date,
  time,
  sessionTheme,
  bookingId,
  bookedSessionId,
  duration,
  isBooking,
  setSessionsData,
  setBookSessionsData,
  bookSessionsData,
  creationDate,
  isMySessionRequest,
  project,
  sessionPackage,
  specialistId,
  clientData,
  specialistData,
  tags,
  subject,
  specialistPay,
}: SessionCardProps) => {
  const token = localStorage.getItem("token");
  const isSpecialistStored = localStorage.getItem("isSpecialist");
  const isSpecialist =
    isSpecialistStored !== null ? JSON.parse(isSpecialistStored) : false;
  const { userData } = useContext(ContextProvider);
  const navigate = useNavigate();
  const [isActiveSession, setIsActiveSession] = useState(false);
  const [acceptLoader, setAcceptLoader] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [confirmAcceptBookingModal, setConfirmAcceptBookingModal] =
    useState(false);
  const [confirmDeclineBookingModal, setConfirmDeclineBookingModal] =
    useState(false);
  const dispatch = useDispatch();

  const addDurationToTime = (time: string, duration: number): string => {
    const format = "HH:mm";
    const startTime = dayjs(time, format);
    const endTime = startTime.add(duration, "minute");
    return endTime.format(format);
  };

  useEffect(() => {
    if (!userData) return;
    setIsActiveSession(
      checkTimeRemaining(createDate(date, time), userData?.timezone!)
    );
  }, [userData, date, time]);

  const joinSession = () => {
    navigate("/session-meet", {
      state: {
        sessionId: bookedSessionId,
        specialistId: specialistId,
      },
    });
  };

  const navigateToChat = () => {
    navigate("/global-chat", {
      state: {
        userId: userId,
      },
    });
  };

  const cancelBookingHandler = async (bookingId: string) => {
    if (!token) return;
    setAcceptLoader(true);
    const response = await SessionsApi.cancelBooking(token, bookingId);
    setAcceptLoader(false);
    if (response.status && setBookSessionsData) {
      setBookSessionsData((prev) => {
        if (!prev) return null;
        return prev.filter((item) => item.bookingId !== bookingId);
      });
      SuccesNotify(strings.bookingHasCanceled);
    }
  };

  const acceptBooking = async (bookingId: string) => {
    if (userData && userData._id && token) {
      setAcceptLoader(true);
      const response = await SessionsApi.acceptBooking(
        userData._id,
        bookingId,
        token
      );

      setAcceptLoader(false);
      if (response.status && response.sessions) {
        dispatch(
          setSelectedInitialCalendarDate(
            dayjs(response.sessions[0].datesAndTime[0].date)
          )
        );
        SuccesNotify(strings.sessionAccepted);
        const indexToRemove =
          bookSessionsData &&
          bookSessionsData.findIndex(
            (session) => session.bookingId === bookingId
          );
        if (
          indexToRemove !== undefined &&
          indexToRemove !== null &&
          indexToRemove !== -1
        ) {
          const updatedBookSessionsData = [...(bookSessionsData || [])];
          const removedSession = updatedBookSessionsData.splice(
            indexToRemove,
            1
          )[0];

          //@ts-ignore
          setSessionsData((prevSessionsData) => {
            if (prevSessionsData) {
              const acceptedSession = {
                ...removedSession,
                acceptSession: true,
                isAccepted: true,
              };
              return [...prevSessionsData, acceptedSession];
            }
            return [removedSession];
          });
          //@ts-ignore
          setBookSessionsData(updatedBookSessionsData);
        }
      }
    }
  };

  const tagsSteps = [strings.direction, strings.topic, strings.result];

  return (
    <div className={isActiveSession ? s.activeContainer : s.container}>
      <div className={s.headBlock}>
        <NavigateToProfile userId={userId}>
          <div className={s.avatarBlock}>
            <CircleAvatar
              userId={userId}
              width="35px"
              height="35px"
              fontSize="18px"
            />
            <span>
              {userData?._id === clientData._id
                ? specialistData.name + " " + specialistData.surname
                : clientData.name + " " + clientData.surname}
            </span>
          </div>
        </NavigateToProfile>
        {isBooking ? (
          <span className={s.didtConfirmStatus}>{strings.didNotConfirm}</span>
        ) : (
          <span
            className={
              isActiveSession ? s.activeStatusBlock : s.plannedStatusBlock
            }
          >
            {isActiveSession ? strings.activeStatus : strings.plannedStatus}
          </span>
        )}
      </div>
      <div className={s.themeBlock}>
        {project ? (
          <span className={s.sessionTheme}>
            {strings.sessionText + " " + strings.fromThe + " "}
            <Link
              to={`/${
                isSpecialist ? "my-programs-specialist" : "my-programs"
              }?projectId=${project._id}`}
              className={s.projectLink}
            >
              {project.title}
            </Link>
          </span>
        ) : sessionPackage ? (
          <span className={s.sessionTheme}>
            {strings.sessionText + " " + strings.fromThePackage + " "}
            <Link
              to={`/package-info/${sessionPackage.packageInfoRef}`}
              className={s.projectLink}
            >
              {sessionPackage.title.find(
                (el) => el.language === userData?.selectedLanguage
              )?.text ??
                sessionPackage.title[0].text ??
                "-"}
            </Link>
          </span>
        ) : (
          <span className={s.sessionTheme}>{sessionTheme}</span>
        )}
        <span>
          {time +
            " - " +
            addDurationToTime(time, duration) +
            ", " +
            date +
            "." +
            new Date().getFullYear()}{" "}
        </span>
      </div>
      <div className={s.actionBlock}>
        <div role="button" onClick={() => setInfoModal(true)}>
          <InfoIcon className={s.infoIcon} />
        </div>
        {isBooking &&
        userData?.roles &&
        userData.roles.includes("SPECIALIST") &&
        !isMySessionRequest ? (
          acceptLoader ? (
            <ThemeProvider theme={theme}>
              <CircularProgress size={50} color="primary" />
            </ThemeProvider>
          ) : (
            <div className={s.buttonsBlock}>
              <div
                onClick={() => setConfirmDeclineBookingModal(true)}
                className={s.declineBtn}
              >
                {strings.notifiesDeclimeBtn}
              </div>
              <StandartButton
                buttonTitle={strings.notifiesAcceptBtn}
                action={() => setConfirmAcceptBookingModal(true)}
                height="35px"
                fontSize="14px"
              />
              {/*  <div className={s.acceptBookingBtn} role="button">
                <CheckIcon
                  className={s.checkIcon}
                  onClick={() => acceptBooking(bookingId)}
                />
              </div>
              <div
                className={s.rejectBookingBtn}
                role="button"
                onClick={() => cancelBookingHandler(bookingId)}
              >
                <CancelIcon className={s.cancelIcon} />
              </div>
              <div
                className={s.infoBookingBtn}
                role="button"
                onClick={() => setInfoModal(true)}
              >
                <InfoIcon className={s.infoIcon} />
              </div> */}
            </div>
          )
        ) : isBooking &&
          userData?.roles &&
          !userData.roles.includes("SPECIALIST") ? (
          <div></div>
        ) : (
          <div className={s.buttonsBlock}>
            <span
              className={s.messageIconBlock}
              role="button"
              onClick={navigateToChat}
            >
              <ChatIcon className={s.chatIcon} />
            </span>
            <StandartButton
              action={joinSession}
              buttonTitle={strings.joinSessioNCard}
              height="35px"
              fontSize="14px"
              disabled={isActiveSession ? false : true}
              tooltipTitle={isActiveSession ? "" : strings.itsNotTimeTooltip}
            />
          </div>
        )}
      </div>
      <ModalWindow
        isOpen={infoModal}
        setIsOpen={setInfoModal}
        width={"fit-content"}
        position="bottom"
        showCloseBtn={false}
        borderRadius="20px 20px 0 0"
      >
        <div className={s.infoModalContent}>
          <div className={s.headerInfoModal}>
            <span className={s.infoLable}>{date + " " + time}</span>
            <div
              onClick={() => setInfoModal(false)}
              style={{ cursor: "pointer" }}
            >
              <CloseIcon className={s.closeIcon} />
            </div>
          </div>
          <div className={s.infoElement}>
            <CircleAvatar userId={clientData._id} />
            <div className={s.clientNameBlock}>
              <span className={s.infoLable}>
                {clientData.name + " " + clientData.surname}
              </span>
              <span className={s.grayText}>
                {strings.bookedThisSession +
                  " " +
                  (creationDate &&
                    formatDateTimeWithOutTimezone(creationDate).formattedTime +
                      " " +
                      formatDateTimeWithOutTimezone(creationDate)
                        .formattedDate)}
              </span>
            </div>
          </div>
          {tags && tags.length ? (
            <div className={s.tagsBlock}>
              {tags.map((item, index) => (
                <div key={index} className={s.clientNameBlock}>
                  <span className={s.infoLable}>
                    {tagsSteps[item.level - 1]}:
                  </span>
                  <span className={s.tagValue}>
                    {
                      item.labels.find(
                        (el) => el.language === userData?.selectedLanguage
                      )?.text
                    }
                  </span>
                </div>
              ))}
            </div>
          ) : null}
          {subject && subject.length ? (
            <div className={s.clientNameBlock}>
              <span className={s.infoLable}>{strings.infoModalComment}</span>
              <span>{subject}</span>
            </div>
          ) : null}
          <div className={s.infoFooter}>
            {!isMySessionRequest ? (
              <div className={s.clientNameBlock}>
                <span className={s.grayText}>{strings.sessionIncome}</span>
                <span className={s.infoLable}>{specialistPay / 100 + "$"}</span>
              </div>
            ) : (
              <div></div>
            )}
            {isBooking && !isMySessionRequest && (
              <div className={s.infoButtonsBlock}>
                <div
                  onClick={() => cancelBookingHandler(bookingId)}
                  className={s.declineBtn}
                >
                  {strings.notifiesDeclimeBtn}
                </div>
                <StandartButton
                  buttonTitle={strings.notifiesAcceptBtn}
                  action={() => acceptBooking(bookingId)}
                />
              </div>
            )}
          </div>
        </div>
      </ModalWindow>
      <ConfirmationModal
        confirmFunc={() => acceptBooking(bookingId)}
        confirmTitle={strings.confirmAccceptBooking}
        modalVisible={confirmAcceptBookingModal}
        setModalVisible={setConfirmAcceptBookingModal}
      />
      <ConfirmationModal
        confirmFunc={() => cancelBookingHandler(bookingId)}
        confirmTitle={strings.confirmDeclineBooking}
        modalVisible={confirmDeclineBookingModal}
        setModalVisible={setConfirmDeclineBookingModal}
      />
    </div>
  );
};

export default NextSessionCard;

import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import { useNavigate } from "react-router-dom";
import Peer from "simple-peer";
import SessionsApi from "../../../api/sessionApi";
import { ReactComponent as ChevronUpIcon } from "../../../assets/HomePage/chevron-up.svg";
import { ReactComponent as GreenAlertCircle } from "../../../assets/VideoSession/alert-circle-green.svg";
import { ReactComponent as AlertCircle } from "../../../assets/VideoSession/alert-circle.svg";
import { ReactComponent as Handset } from "../../../assets/VideoSession/handset.svg";
import { ReactComponent as MicroSlashIcon } from "../../../assets/VideoSession/microphone-slash.svg";
import { ReactComponent as MicroIcon } from "../../../assets/VideoSession/microphone.svg";
import PermissionImage from "../../../assets/VideoSession/permissions.jpg";
import { ReactComponent as TriangelIcon } from "../../../assets/VideoSession/triangle.svg";
import { ReactComponent as TripleDotIcon } from "../../../assets/VideoSession/triple-dot.svg";
import { ReactComponent as CameraSlashIcon } from "../../../assets/VideoSession/video-camera-slash.svg";
import { ReactComponent as CameraIcon } from "../../../assets/VideoSession/video-camera.svg";
import { ReactComponent as Volume } from "../../../assets/VideoSession/volume.svg";
import PopperComponent from "../../../components/CustomPopper/CustomPopper";
import ModalWindow from "../../../components/ModalWindow/ModalWindow";
import useClickOutside from "../../../hooks/useClickOutside";
import strings from "../../../localization";
import { Sessions } from "../../../types/TypeSession";
import { TypeUserData } from "../../../types/TypeUsers";
import { DevicesList } from "../SessionVideoChat";
import s from "./BottomMenu.module.css";

type TypeBottomMenuProps = {
  myStream: MediaStream | undefined;
  peers: { peerID: string; peer: Peer.Instance }[];
  inputAudioDevices: DevicesList[] | null;
  setInputAudioDevices: Dispatch<SetStateAction<DevicesList[] | null>>;
  outputAudioDevices: DevicesList[] | null;
  setOutputAudioDevices: Dispatch<SetStateAction<DevicesList[] | null>>;
  inputVideoDevices: DevicesList[] | null;
  setInputVideoDevices: Dispatch<SetStateAction<DevicesList[] | null>>;
  setMyStream: any;
  sessionId: string;
  camera: boolean;
  setCamera: Dispatch<SetStateAction<boolean>>;
  microphone: boolean;
  setMicrophone: Dispatch<SetStateAction<boolean>>;
  recordEnable: boolean;
  approveRecord: (approval: boolean) => Promise<void>;
  toolsBtnsData: {
    icon: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string;
      }
    >;
    action: () => void;
    selected: boolean;
    title?: string;
  }[];
  userIsSpecialist: boolean | undefined;
  sessionInfo:
    | (Sessions & {
        clients: TypeUserData[];
        specialistUsers: TypeUserData[];
      })
    | undefined;
  approveActionStatus: "sent" | "decline" | undefined;
  isRecordStarted: boolean;
  notifyFromUserData:
    | {
        userId: string;
        name: string;
        surname: string;
      }
    | undefined;
  actionLoaders: {
    aprroveLoader: boolean;
  };
  setApproveRecordModal: Dispatch<SetStateAction<boolean>>;
};

const BottomMenu = ({
  myStream,
  //@ts-expect-error
  peers,
  inputAudioDevices,
  setInputAudioDevices,
  outputAudioDevices,
  setOutputAudioDevices,
  inputVideoDevices,
  setInputVideoDevices,
  //@ts-expect-error
  setMyStream,
  sessionId,
  camera,
  setCamera,
  microphone,
  setMicrophone,
  /*   recordEnable, */
  toolsBtnsData,
  approveRecord,
  userIsSpecialist,
  sessionInfo,
  approveActionStatus,
  isRecordStarted,
  notifyFromUserData,
  actionLoaders,
  setApproveRecordModal,
}: TypeBottomMenuProps) => {
  const navigation = useNavigate();
  const token = localStorage.getItem("token");

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [devicesChooseVisible, setDevicesChooseVisible] = useState({
    camera: false,
    audio: false,
  });
  const [devicesHeight, setDevicesHeight] = useState({
    video: false,
    inputAudio: false,
    outputAudio: false,
  });
  const [popperMenu, setPopperMenu] = useState(false);
  const [permissions, setPermissions] = useState<{
    micro?: boolean;
    camera?: boolean;
  }>({ micro: false, camera: false });
  const [permissionModal, setPermissionModal] = useState(false);
  const [permissionsRequested, setPermissionsRequested] = useState(false);

  const tripleDorMenuRef = useRef<HTMLDivElement | null>(null);
  useClickOutside(tripleDorMenuRef, () => setPopperMenu(false));

  const handleMicrophoneClick = () => {
    if (!permissions.micro) {
      setPermissionModal(true);
      return;
    }

    setMicrophone((prev) => !prev);

    if (myStream)
      myStream.getAudioTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
  };

  const getPermissions = async () => {
    try {
      await navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then((stream) =>
          stream.getTracks().forEach((track) => {
            track.stop();
          })
        );

      await navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then((stream) =>
          stream.getTracks().forEach((track) => {
            track.stop();
          })
        );
      setPermissionsRequested(true);
      return;
    } catch (error) {
      console.error("error getting permissions: ", error);

      return;
    }
  };
  useEffect(() => {
    if (!permissionsRequested) return;
    (async () => {
      const cameraPermission = await navigator.permissions.query({
        //@ts-expect-error
        name: "camera",
      });

      cameraPermission.addEventListener("change", (evt) => {
        if (!evt || !evt.currentTarget) return;
        const target = evt.currentTarget as PermissionStatus;
        setPermissions((prev) => ({
          ...prev,
          camera: target.state === "granted",
        }));
        updateDevices();
      });
      const audioPermission = await navigator.permissions.query({
        //@ts-expect-error
        name: "microphone",
      });
      audioPermission.addEventListener("change", (evt) => {
        if (!evt || !evt.currentTarget) return;
        const target = evt.currentTarget as PermissionStatus;
        setPermissions((prev) => ({
          ...prev,
          micro: target.state === "granted",
        }));
        updateDevices();
      });
      setPermissions({
        camera: cameraPermission.state === "granted",
        micro: audioPermission.state === "granted",
      });
    })();
  }, [permissionsRequested]);
  const updateDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioInputDevices = devices.filter(
        (device) => device.kind === "audioinput"
      );
      const audioOutputDevices = devices.filter(
        (device) => device.kind === "audiooutput"
      );
      const videoInputDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );

      setInputAudioDevices(
        audioInputDevices.map((item) => ({
          title: item.label,
          deviseId: item.deviceId,
          isSelect: item.deviceId === "default",
        }))
      );
      setOutputAudioDevices(
        audioOutputDevices.map((item) => ({
          title: item.label,
          deviseId: item.deviceId,
          isSelect: item.deviceId === "default",
        }))
      );
      setInputVideoDevices(
        videoInputDevices.map((item, index) => ({
          title: item.label,
          deviseId: item.deviceId,
          isSelect: index === 0,
        }))
      );
    } catch (error) {
      console.error("Error enumerating devices:", error);
    }
  };
  useEffect(() => {
    if (permissionsRequested) updateDevices();
  }, [permissionsRequested]);

  useEffect(() => {
    getPermissions();
  }, []);

  const handleCameraClick = () => {
    if (!permissions.camera) {
      setPermissionModal(true);
      return;
    }

    setCamera((prev) => !prev);

    if (myStream)
      myStream.getVideoTracks().forEach((track) => {
        track.enabled = !track.enabled;
      });
  };

  const sessionDisconnect = async () => {
    if (token) {
      const commentLeaveResponse = await SessionsApi.checkIfCanLeaveComment(
        token,
        sessionId
      );

      if (!commentLeaveResponse.status) {
        navigation("/home");
      } else {
        navigation("/home", {
          state: {
            sessionId: sessionId,
            ratingOpen: true,
          },
        });
      }
    }
  };

  const menuElements = [
    {
      icon: microphone ? MicroIcon : MicroSlashIcon,
      action: () => handleMicrophoneClick(),
      className: microphone ? s.iconBg : s.disableIconBg,
      categoryTitle: "audio",
      showErrorIcon: !permissions.micro,
    },
    {
      icon: camera ? CameraIcon : CameraSlashIcon,
      action: () => handleCameraClick(),
      className: camera ? s.iconBg : s.disableIconBg,
      categoryTitle: "camera",
      showErrorIcon: !permissions.camera,
    },
    {
      icon: Handset,
      action: () => sessionDisconnect(),
      className: s.handsetBg,
      categoryTitle: "disconnect",
    },
    {
      icon: TripleDotIcon,
      action: (event: {
        currentTarget: SetStateAction<HTMLElement | null>;
      }) => {
        setPopperMenu((prev) => !prev);
        setAnchorEl(event.currentTarget);
      },
      className: s.tripleDotBtn,
      categoryTitle: "tripledot",
      ref: tripleDorMenuRef,
    },
  ];
  const handleChooseDeviceVisible = (title: string) => {
    setDevicesChooseVisible(
      title === "camera"
        ? { camera: !devicesChooseVisible.camera, audio: false }
        : title === "audio"
        ? { camera: false, audio: !devicesChooseVisible.audio }
        : { camera: false, audio: false }
    );
  };
  const handleDeviceClick = (index: number, devicesArr: DevicesList[]) => {
    const updatedDevices = devicesArr.map((device, i) => ({
      ...device,
      isSelect: i === index,
    }));
    return updatedDevices;
  };

  return (
    <div className={s.container}>
      <div className={s.chooseDeviceBlock}>
        {(devicesChooseVisible.audio || devicesChooseVisible.camera) &&
          (devicesChooseVisible.camera ? (
            <div className={s.chooseCameraBlock}>
              <AnimateHeight
                height={!devicesHeight.video ? 0 : "auto"}
                className={s.cameraListBlock}
              >
                {inputVideoDevices?.map((item, index) => (
                  <div
                    onClick={() => {
                      const updateSelectDevice = handleDeviceClick(
                        index,
                        inputVideoDevices
                      );
                      setInputVideoDevices(
                        updateSelectDevice ? updateSelectDevice : null
                      );
                    }}
                    style={{
                      marginBottom:
                        index === inputVideoDevices.length - 1 ? "10px" : 0,
                    }}
                    key={index}
                    className={
                      item.isSelect ? s.selectedDeviceTitle : s.divecTitle
                    }
                  >
                    {item.title}
                  </div>
                ))}
              </AnimateHeight>

              <div
                className={
                  devicesHeight.video
                    ? s.chooseCameraLableOpen
                    : s.chooseCameraLable
                }
                onClick={() =>
                  setDevicesHeight({
                    video: !devicesHeight.video,
                    inputAudio: false,
                    outputAudio: false,
                  })
                }
              >
                <CameraIcon className={s.cameraIcon} />
                <span>
                  {inputVideoDevices &&
                    inputVideoDevices.find((device) => device.isSelect === true)
                      ?.title}
                </span>
                <TriangelIcon className={s.triangelIcon} />
              </div>
            </div>
          ) : (
            <div className={s.chooseAudioBlock}>
              <div className={s.chooseMicro}>
                <AnimateHeight
                  height={!devicesHeight.inputAudio ? 0 : "auto"}
                  className={s.cameraListBlock}
                >
                  {inputAudioDevices?.map((item, index) => (
                    <div
                      onClick={() => {
                        const updateSelectDevice = handleDeviceClick(
                          index,
                          inputAudioDevices
                        );
                        setInputAudioDevices(
                          updateSelectDevice ? updateSelectDevice : null
                        );
                      }}
                      style={{
                        marginBottom:
                          index === inputAudioDevices.length - 1 ? "10px" : 0,
                      }}
                      key={index}
                      className={
                        item.isSelect ? s.selectedDeviceTitle : s.divecTitle
                      }
                    >
                      {item.title}
                    </div>
                  ))}
                </AnimateHeight>
                <div
                  className={
                    devicesHeight.inputAudio
                      ? s.chooseCameraLableOpen
                      : s.chooseCameraLable
                  }
                  onClick={() =>
                    setDevicesHeight({
                      video: false,
                      inputAudio: !devicesHeight.inputAudio,
                      outputAudio: false,
                    })
                  }
                >
                  <MicroIcon className={s.microIcon} />

                  <span>
                    {inputAudioDevices &&
                      inputAudioDevices.find(
                        (device) => device.isSelect === true
                      )?.title}
                  </span>
                  <TriangelIcon className={s.triangelIcon} />
                </div>
              </div>
              <div className={s.chooseVolume}>
                <AnimateHeight
                  height={!devicesHeight.outputAudio ? 0 : "auto"}
                  className={s.cameraListBlock}
                >
                  {outputAudioDevices?.map((item, index) => (
                    <div
                      onClick={() => {
                        const updateSelectDevice = handleDeviceClick(
                          index,
                          outputAudioDevices
                        );
                        setOutputAudioDevices(
                          updateSelectDevice ? updateSelectDevice : null
                        );
                      }}
                      style={{
                        marginBottom:
                          index === outputAudioDevices.length - 1 ? "10px" : 0,
                      }}
                      key={index}
                      className={
                        item.isSelect ? s.selectedDeviceTitle : s.divecTitle
                      }
                    >
                      {item.title}
                    </div>
                  ))}
                </AnimateHeight>
                <div
                  className={
                    devicesHeight.outputAudio
                      ? s.chooseCameraLableOpen
                      : s.chooseCameraLable
                  }
                  onClick={() =>
                    setDevicesHeight({
                      video: false,
                      inputAudio: false,
                      outputAudio: !devicesHeight.outputAudio,
                    })
                  }
                >
                  <Volume className={s.volumeIcon} />
                  <span>
                    {outputAudioDevices &&
                      outputAudioDevices.find(
                        (device) => device.isSelect === true
                      )?.title}
                  </span>
                  <TriangelIcon className={s.triangelIcon} />
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className={s.mainContainer}>
        {menuElements.map((item, index) => (
          <div
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              handleChooseDeviceVisible(item.categoryTitle);
            }}
            className={
              index === menuElements.length - 1 ? "" : s.bottomMenuButtonsBlock
            }
            style={{
              paddingLeft:
                item.categoryTitle === "audio" ||
                item.categoryTitle === "camera"
                  ? "7px"
                  : "0px",
            }}
          >
            {(item.categoryTitle === "audio" ||
              item.categoryTitle === "camera") && (
              <ChevronUpIcon className={s.chevronIcon} />
            )}
            <div
              className={item.className}
              onClick={(e) => {
                e.stopPropagation();
                item.action(e);
              }}
              key={index}
              role="button"
            >
              <item.icon className={s.icon} />
            </div>
            {item.showErrorIcon && <span className={s.errorIcon}>!</span>}
          </div>
        ))}
      </div>
      <PopperComponent
        open={popperMenu}
        anchorEl={anchorEl}
        content={
          <div className={s.popperContentBlock} ref={tripleDorMenuRef}>
            <div>
              <div
                className={`${s.audioRecordBlock} ${
                  isRecordStarted ? s.audioRecordBlockActive : ""
                } ${
                  (userIsSpecialist &&
                    !sessionInfo?.askSpecForRecording &&
                    isRecordStarted) ||
                  approveActionStatus === "sent"
                    ? s.disableAudioRecordBlock
                    : ""
                }`}
                onClick={() => {
                  if (
                    (userIsSpecialist &&
                      !sessionInfo?.askSpecForRecording &&
                      isRecordStarted) ||
                    approveActionStatus === "sent"
                  )
                    return;
                  if (isRecordStarted) {
                    approveRecord(false);
                  }
                  if (!isRecordStarted) {
                    setApproveRecordModal(true);
                  }
                }}
              >
                <div className={s.row10gap}>
                  <MicroIcon className={s.recordingMicro} />

                  {isRecordStarted
                    ? strings.stopAudioRecord
                    : strings.startAudioRecordBtn}
                </div>
              </div>
              {!isRecordStarted &&
                !actionLoaders.aprroveLoader &&
                sessionInfo?.askSpecForRecording && (
                  <div>
                    {approveActionStatus === "sent" ? (
                      <div className={s.actionStatusBlock}>
                        <GreenAlertCircle width={20} height={20} />{" "}
                        <span>{strings.recordRequestSent}</span>
                      </div>
                    ) : approveActionStatus === "decline" ? (
                      <div className={s.actionStatusBlock}>
                        <AlertCircle width={20} height={20} />
                        <span>
                          {(notifyFromUserData
                            ? notifyFromUserData.name +
                              " " +
                              notifyFromUserData.surname +
                              " "
                            : strings.conversation + " ") +
                            strings.declineRecord}
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}
            </div>

            <div className={s.toolsButtonsBlock}>
              {toolsBtnsData
                .filter((el) => el.title)
                .map((item, index) => (
                  <div key={index} onClick={item.action} className={s.toolsBtn}>
                    <item.icon
                      className={item.selected ? s.activeIcon : s.toolsIcon}
                      width={25}
                      height={25}
                    />
                    <span>{item.title}</span>
                  </div>
                ))}
            </div>
          </div>
        }
      />
      <ModalWindow
        isOpen={permissionModal}
        setIsOpen={setPermissionModal}
        showCloseBtn={true}
        width="fit-content"
      >
        <div className={s.permisssionModalContent}>
          <img src={PermissionImage} alt="" />
          <div>
            <h3>{strings.noPermissionModalTitle}</h3>
            <ol className={s.turnDeviceStepsBlock}>
              <li>{strings.clickPageInfoIcon}</li>
              <li>{strings.turnCameraAndMicro}</li>
            </ol>
          </div>
        </div>
      </ModalWindow>
    </div>
  );
};

export default BottomMenu;
